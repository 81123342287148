import Vue from 'vue';
import fetch from 'unfetch';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import VueApollo from 'vue-apollo';

// Install the vue plugin
Vue.use(VueApollo);

export const graphqlClient = new ApolloClient({
  // Provide the URL to the API server.
  link: new HttpLink({ uri: process.env.VUE_APP_SERVER_URL, fetch }),
  // Using a cache for blazingly
  // fast subsequent queries.
  cache: new InMemoryCache(),
});

const apolloProvider = new VueApollo({
  defaultClient: graphqlClient,
});

export default apolloProvider;
