import { graphqlClient } from '@/utils/graphql';
import settingsQuery from '@/graphql/query/Settings.gql';

// eslint-disable-next-line import/prefer-default-export
export async function getUpdatedMigrationTime() {
  const settingsSubscribe = await graphqlClient.subscribe({
    query: settingsQuery.findFirstSettings,
    variables: {
      where: {
        is_singleton: {
          equals: true,
        },
      },
    },
  });

  return new Promise((resolve, reject) => {
    settingsSubscribe.subscribe({
      next(response) {
        const { data: { findFirstSettings } } = response;
        const migration = new Date(findFirstSettings.latest_migrated_timestamp);
        const currentDate = new Date();
        const updatedMigration = !(currentDate.getTime() > migration.getTime() + (5 * 60 * 1000));
        resolve({ updatedMigration });
      },
      error(error) {
        reject(error);
      },
    });
  });
}
