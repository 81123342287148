<template>
  <index />
</template>
<script>
import Index from '@/components/container/Index.vue';

export default {
  name: 'App',
  components: {
    Index,
  },
};
</script>
