<template>
  <v-dialog v-model="dialog" persistent content-class="d-saving-amazon"
    overlay-opacity="0.6" overlay-color="#000">
    <v-icon class="btn-close-dialog" @click="closeDialog">mdi-close</v-icon>
    <div class="dialog-saving-amazon secondary-color">
      <div class="d-flex justify-center align-center content-saving-amazon">
        <div v-if="option === 1" class="congratulation">
          <div class="d-flex justify-center">
            <img src="@/assets/dialog/handTree.svg" alt="handTree" />
            <div class="description ml-10">
              <div class="h1-title">
                {{$t('dialog.saving-amazon.title')}}
              </div>
              <div class="p1-descriptions mt-4">
                {{$t('dialog.saving-amazon.description1')}}
                <span class="text-info">
                  {{$t('dialog.saving-amazon.description2')}}
                </span>
                {{$t('dialog.saving-amazon.description3')}}
              </div>
            </div>
          </div>
          <div class="d-flex justify-space-between">
            <v-btn text class="btn btn1 btn-secondary mt-6" @click="closeDialog">
              <span class="b2-secondary text-detail">
                {{$t('dialog.saving-amazon.btn1')}}
              </span>
            </v-btn>
            <v-btn class="btn btn2 primary-color mt-6" @click="option = 2">
              <span class="b2-secondary white--text">
                {{$t('dialog.saving-amazon.btn2')}}
              </span>
            </v-btn>
          </div>
        </div>
        <div v-if="option === 2" class="form-saving-amazon">
          <div class="d-flex justify-center">
            <img src="@/assets/dialog/bitcoinTree.svg" alt="bitcoinTree" />
            <div class="form-content ml-10">
              <div class="input-control mb-4">
                <label class="p1-descriptions">
                  {{$t('dialog.saving-amazon.alias')}}
                </label> <br>
                <input
                  class="input-saving text-info primary-bg"
                  :class="validAlias ? 'alert' : ''"
                  type="string"
                  v-model="alias"
                  @input="getAlias"
                />
                <div class="alert-description">
                  {{ descriptionAlias }}
                </div>
              </div>
              <div class="input-control mb-4">
                <label class="p1-descriptions">
                  {{$t('dialog.saving-amazon.nameTree')}}
                </label> <br>
                <input
                  class="input-saving text-info primary-bg"
                  type="string"
                  v-model="nameTree"
                />
              </div>
              <div class="input-control mt-4">
                <div class="d-flex align-center">
                  <label class="p1-descriptions mb-1">
                    {{$t('dialog.saving-amazon.email')}}
                  </label>
                  <div class="tooltip-info ml-1 text-left">
                    <v-tooltip
                      bottom
                      content-class="secondary-color box-shadow-tooltip"
                      max-width="260">
                      <template v-slot:activator="{ on, attrs }">
                        <img
                          v-bind="attrs"
                          v-on="on"
                          src="@/assets/icons/info2.svg"
                        />
                      </template>
                      <span class="p5-feedback text-info">
                        {{$t('dialog.saving-amazon.tooltip[0]')}}
                        <ul>
                          <li class="mt-3">{{$t('dialog.saving-amazon.tooltip[1]')}}</li>
                          <li class="mt-3">{{$t('dialog.saving-amazon.tooltip[2]')}}</li>
                          <li class="mt-3">{{$t('dialog.saving-amazon.tooltip[3]')}}</li>
                          <li class="mt-3">{{$t('dialog.saving-amazon.tooltip[4]')}}</li>
                          <li class="mt-3 mb-1">{{$t('dialog.saving-amazon.tooltip[5]')}}</li>
                        </ul>
                      </span>
                    </v-tooltip>
                  </div>
                </div>
                <input
                  class="input-saving text-info primary-bg"
                  :class="!validEmail || existEmail ? 'alert' : ''"
                  type="string"
                  v-model="email"
                  @input="getEmail"
                />
                <div class="alert-description">
                  {{ descriptionEmail }}
                </div>
              </div>
              <div class="d-flex align-center justify-start p1-descriptions mt-5">
                <v-checkbox
                  v-model="checkTyC"
                  class="checkbox"
                  hide-details
                ></v-checkbox>
                <span>{{$t('dialog.saving-amazon.tyc1')}}&nbsp;</span>
                <a target="_blank" class="p1-descriptions text-detail"
                  :href="tycUrl">
                  {{$t('dialog.saving-amazon.tyc2')}}
                </a>
              </div>
            </div>
          </div>
          <div class="d-flex justify-center">
            <v-btn text class="btn btn2 mt-6"
              :disabled="!activeButton"
              :class="activeButton ? 'primary-color' : 'secondary-bg'"
              @click="saveSavingTheAmzonUser">
              <span class="white--text">
                {{ $t('dialog.saving-amazon.btn3') }}
              </span>
            </v-btn>
          </div>
        </div>
        <div v-if="option === 3" class="code">
          <div class="code-saving-amazon">
            <div class="d-flex flex-column text-left">
              <div class="text-center">
                <img src="@/assets/dialog/tropykusTree.svg" alt="tropykusTree" />
              </div>
              <span class="text-center my-2 text-info">
                {{ savedCodeAmazon }}
              </span>
              <div v-if="validEmail && email !== ''" class="p1-descriptions text-center mb-2 px-10">
                <span class="text-info">{{ $t('dialog.saving-amazon.code.description1') }} </span>
                <span>{{ $t('dialog.saving-amazon.code.description2') }} </span>
                <a class="text-info" :href="savingUrl" target="_blank">
                  {{ $t('dialog.saving-amazon.code.description3') }}
                </a>
                <span>{{ $t('dialog.saving-amazon.code.description8') }} </span>
              </div>
              <div v-else class="p1-descriptions text-center mb-2">
                <span class="text-info">{{ $t('dialog.saving-amazon.code.description1') }} </span>
                <span>{{ $t('dialog.saving-amazon.code.description2') }} </span>
                <a class="text-info" :href="savingUrl" target="_blank">
                  {{ $t('dialog.saving-amazon.code.description3') }}
                </a>
                <span>{{ $t('dialog.saving-amazon.code.description4') }} </span>
                <span class="text-info">{{ $t('dialog.saving-amazon.code.description5') }}</span>
              </div>
              <div v-if="!validEmail || email === ''" class="p1-descriptions">
                <ul>
                  <li>
                    {{ $t('dialog.saving-amazon.code.description6') }}
                  </li>
                  <li>
                    {{ $t('dialog.saving-amazon.code.description7') }}
                  </li>
                </ul>
              </div>
            </div>
            <v-btn text class="btn btn2 primary-color mt-4"
              @click="closeDialog">
              <span class="white--text">
                {{ $t('dialog.saving-amazon.btn4') }}
              </span>
            </v-btn>
          </div>
        </div>
        <div v-if="option === 4" class="code">
          <div class="code-saving-amazon">
            <div class="d-flex flex-column text-left">
              <div class="text-center">
                <img src="@/assets/dialog/tropykusTree.svg" alt="tropykusTree" />
              </div>
              <span class="text-center my-2 text-info">
                {{ $t('dialog.saving-amazon.code.description9') }}
              </span>
            </div>
            <v-btn text class="btn btn2 primary-color mt-4"
              @click="closeDialog">
              <span class="white--text">
                {{ $t('dialog.saving-amazon.btn4') }}
              </span>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'SavingTheAmazon',
  data() {
    return {
      tycUrl: 'https://firebasestorage.googleapis.com/v0/b/tropycofinance.appspot.com/o/saving-the-amazon-tyc.pdf?alt=media&token=63a129a9-0c3e-485d-9644-5e48f7297029',
      savingUrl: 'https://savingtheamazon.org/en/pages/tu-arbol',
      dbName: process.env.VUE_APP_DB_SAVING_THE_AMAZON,
      dialog: this.showModal,
      checkPioneer: false,
      option: 1,
      alias: '',
      nameTree: null,
      email: '',
      checkTyC: false,
      existAlias: false,
      existEmail: false,
      savedCodeAmazon: 0,
      rules: {
        alias: () => ((this.alias !== '')
          ? this.existAlias === true : true)
          || 'ingresa otro alias este ya esta en uso',
        email: () => ((this.email !== '')
          ? this.validEmail === true : true)
          || 'Ingrese un correo valido',
      },
    };
  },
  props: {
    showModal: {
      type: Boolean,
    },
    savingTheAmazonId: {
      type: Number,
      require: true,
    },
  },
  watch: {
    showModal() {
      this.dialog = this.showModal;
    },
    savingTheAmazonId() {
      if (this.savingTheAmazonId === 0 && this.savedCodeAmazon === 0) {
        this.option = 4;
      }
    },
  },
  computed: {
    ...mapState({
      walletAddress: (state) => state.Session.walletAddress,
    }),
    activeButton() {
      return this.alias !== ''
        && !this.existAlias && !this.existEmail && this.checkTyC;
    },
    validEmail() {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.email !== '') {
        return pattern.test(this.email);
      }
      return true;
    },
    validAlias() {
      return this.existAlias;
    },
    descriptionEmail() {
      if (!this.validEmail && this.email !== '') return 'correo no valido';
      return this.existEmail ? 'Email ya existe' : '';
    },
    descriptionAlias() {
      return this.existAlias ? 'Alias ya existe' : '';
    },
  },
  methods: {
    closeDialog() {
      this.option = 1;
      this.savedCodeAmazon = 0;
      this.$emit('closed');
    },
    getAlias() {
      setTimeout(() => {
        this.$db
          .collection(this.dbName).where('alias', '==', `${this.alias}`)
          .get()
          .then((data) => {
            if (data.docs.length > 0) {
              const { exists } = data.docs[0];
              this.existAlias = exists;
            } else {
              this.existAlias = false;
            }
          });
      }, 500);
    },
    getEmail() {
      setTimeout(() => {
        this.$db
          .collection(this.dbName).where('email', '==', `${this.email}`)
          .get()
          .then((data) => {
            if (data.docs.length > 0) {
              const { exists } = data.docs[0];
              this.existEmail = exists;
            } else {
              this.existEmail = false;
            }
          });
      }, 500);
    },
    async saveSavingTheAmzonUser() {
      this.savedCodeAmazon = this.savingTheAmazonId;
      if (this.savingTheAmazonId > 0) {
        this.$db.collection(this.dbName)
          .doc(`${this.savingTheAmazonId}`).update({
            address: this.walletAddress,
            tyc: this.checkTyC,
            alias: this.alias,
            email: this.email,
            name: this.nameTree,
          })
          .then(() => {
            this.option = 3;
          })
          .catch((error) => {
            console.error('Error updating document: ', error);
          });
      } else {
        this.option = 4;
      }
    },
  },
};
</script>
