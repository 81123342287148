import * as constants from '@/store/constants';
import { getMarkestInfo } from '@/services/markets';
import { getUserBalance } from '@/services/users';
import { getUpdatedMigrationTime } from '@/services/settings';
import {
  CRbtc,
  CRDoc,
  CToken,
  reduceDecimals,
} from '@/middleware';
import { addresses } from '@/middleware/contracts/constants';

const initState = {
  underlyingSymbol: null,
  rate: null,
  underlyingPrice: 0,
  underlyingBalance: null,
  liquidity: null,
  interestBalance: 0,
  supplyBalance: 0,
  borrowBalance: 0,
  price: 0,
};
const state = {
  accountInfo: {},
  getMarkets: [],
  market: null,
  isProgress: true,
  info: initState,
  select: {
    symbol: '',
    img: '',
  },
  dest_market: null,
  dest_select: {
    symbol: '',
    img: '',
  },
  dest_info: initState,
  markets: [],
  instance: null,
  totalDepositsInteres: 0,
  totalBorrowsInUSD: 0,
  totalDepositsInUSD: 0,
  loadingMarket: false,
  loadedWallet: false,
  totalDepositsByInteresUSD: 0,
  totalBorrowByInteresUSD: 0,
  totalUSDWallet: 0,
  totalRbtcInWalletInUSD: 0,
  filteredMarkets: [],
  swapMarkets: [],
};

const newMarketInstance = {
  CRBTC: (marketAddress) => new CRbtc(marketAddress),
  CRDOC: (marketAddress) => new CRDoc(marketAddress),
  CTOKEN: (marketAddress) => new CToken(marketAddress),
};

const actions = {
  [constants.MARKET_LOAD_MARKET_INSTANCE]: ({ commit }, market) => {
    if (!market) return;
    const { type, id } = market;
    const instance = newMarketInstance[type](id);
    commit(constants.MARKET_SET_PROPERTY, { instance });
  },

  [constants.MARKET_LOAD_TOKENS]: ({ commit }) => {
    const tokens = [];
    let counter = 0;
    state.markets.forEach(async (m) => {
      try {
        const market = newMarketInstance[m.type](m.id);
        tokens.push(market);
        counter += 1;
        if (counter === state.markets.length) {
          commit(constants.MARKET_SET_PROPERTY, { tokens });
        }
      } catch (error) {
        console.error(error);
      }
    });
  },

  [constants.MARKET_LOAD_MARKETS]: ({ commit, dispatch, rootState }) => {
    const { Session: { chainId } } = rootState;
    getMarkestInfo()
      .then((response) => {
        const { markets } = response;
        const newMarkets = markets.map((market) => (
          {
            collateralFactor: market.collateral_factor,
            underlyingSymbol: market.underlying_token_name,
            symbol: market.name,
            borrowRate: Number(market.borrow_rate),
            supplyRate: Number(market.supply_rate),
            type: market.market_type,
            id: market.contract_address.toLowerCase(),
            symbolImg: market.symbol_image,
            price: market.underlying_token_price,
            marketId: market.id,
            borrowBalance: 0,
            bruteDeposits: 0,
            bruteBorrows: 0,
            underlyingBalance: 0,
            supplyBalance: 0,
            maxAllowedToWithdraw: 0,
            maxAllowedToDeposit: 0,
            cash: 0,
            interestDeposit: 0,
            interestBorrow: 0,
            borrow_paused_status: market.borrow_paused_status,
            borrow_paused_message: market.borrow_paused_message,
            mint_paused_status: market.mint_paused_status,
            mint_paused_message: market.mint_paused_message,
            is_listed: market.is_listed,
          }
        ));
        const filteredMarkets = newMarkets
          .filter(((mkt) => mkt.is_listed));
        const swapMarkets = newMarkets
          .filter(((mkt) => mkt.is_listed || mkt.id === addresses[chainId].kRIF));
        commit(constants.MARKET_SET_PROPERTY, { markets: newMarkets });
        commit(constants.MARKET_SET_PROPERTY, { filteredMarkets });
        commit(constants.MARKET_SET_PROPERTY, { swapMarkets });
        dispatch(constants.MARKET_LOAD_TOKENS);
      });
  },

  // eslint-disable-next-line no-shadow
  [constants.MARKET_LOAD_MARKETS_USER_INFO]: async ({ commit, dispatch, rootState }) => {
    const {
      Session: {
        userId, account, walletAddress, chainId,
      },
      Market,
    } = rootState;
    commit(constants.MARKET_SET_PROPERTY, { loadingMarket: true });
    let totalDepositsInteres = 0;
    let totalBorrowsInUSD = 0;
    let totalDepositsInUSD = 0;
    let totalDepositsByInteresUSD = 0;
    let totalBorrowByInteresUSD = 0;
    let totalUSDWallet = 0;
    let totalRbtcInWalletInUSD = 0;
    const { marketsBalance } = await getUserBalance(userId);

    const xusd = marketsBalance
      .filter((mkt) => mkt.markets.contract_address.toLowerCase() === addresses[chainId].kXUSD)
      .pop();

    const marketsBalanceFiltered = marketsBalance
      .filter((mkt) => mkt.markets.is_listed);

    if (Number(xusd?.deposits) > 0 || Number(xusd?.borrows) > 0) {
      marketsBalanceFiltered.push(xusd);
      if (!Market.filteredMarkets.find((mkt) => mkt.id === addresses[chainId].kXUSD)) {
        Market.filteredMarkets
          .push(Market.markets.find((mkt) => mkt.id === addresses[chainId].kXUSD));
      }
    }

    const { updatedMigration } = await getUpdatedMigrationTime();

    Promise.all(state.markets.map(async (market) => {
      const instance = newMarketInstance[market.type](market.id);
      const symbol = market.underlyingSymbol;
      const findMarket = marketsBalanceFiltered
        .filter((mk) => mk.markets.contract_address.toLowerCase() === market.id).pop();

      let marketInfo = await Promise.all([
        instance.getCash(),
        instance.balanceOfUnderlyingInWallet(account),
      ]);

      const [cash, underlyingBalance] = marketInfo;

      if (market.id === addresses[chainId].kRBTC) {
        totalRbtcInWalletInUSD += underlyingBalance * market.price;
      }

      Object.assign(market, {
        cash: reduceDecimals(cash, symbol),
        underlyingBalance: reduceDecimals(underlyingBalance, symbol),
      });
      if (market.is_listed) {
        totalUSDWallet += market.underlyingBalance * market.price;
      }

      if (findMarket) {
        marketInfo = await Promise.all([
          instance.maxAllowedToDeposit(),
          instance.maxAllowedToWithdraw(),
        ]);

        const [maxAllowedToDeposit, maxAllowedToWithdraw] = marketInfo;
        Object.assign(market, {
          maxAllowedToWithdraw: reduceDecimals(maxAllowedToWithdraw.underlying, symbol),
          maxAllowedToDeposit: reduceDecimals(maxAllowedToDeposit.underlying.value, symbol),
        });

        // getting data from bakend
        if (updatedMigration && market.symbol !== constants.CSAT_SYMBOL) {
          marketInfo = await Promise.all([
            findMarket.deposits,
            findMarket.borrows,
          ]);
        } else {
          marketInfo = await Promise.all([
            instance.balanceOfUnderlying(walletAddress, account),
            instance.borrowBalanceCurrent(walletAddress),
          ]);
        }
        const [supplyBalance, borrowBalance] = marketInfo;

        const interestDeposit = Number(supplyBalance) - Number(findMarket.brute_deposits);
        const interestBorrow = Number(borrowBalance) - Number(findMarket.brute_borrows);

        Object.assign(market, {
          supplyBalance: reduceDecimals(supplyBalance, symbol),
          borrowBalance: reduceDecimals(borrowBalance, symbol),
          interestDeposit,
          interestBorrow,
          bruteBorrows: findMarket.brute_borrows,
          bruteDeposits: findMarket.brute_deposits
        });
        totalDepositsInteres += market.interestDeposit * market.price;
        totalBorrowsInUSD += market.borrowBalance * market.price;
        totalDepositsInUSD += Number(supplyBalance) * market.price;
        totalDepositsByInteresUSD += supplyBalance * market.price;
        totalBorrowByInteresUSD += borrowBalance * market.price;

        return market;
      }

      return market;
    }))
      .then((markets) => {
        commit(constants.MARKET_SET_PROPERTY, { loadedWallet: true });
        commit(constants.MARKET_SET_PROPERTY, { markets });
        commit(constants.MARKET_SET_PROPERTY, { totalDepositsInteres });
        commit(constants.MARKET_SET_PROPERTY, { totalBorrowsInUSD });
        commit(constants.MARKET_SET_PROPERTY, { totalDepositsInUSD });
        commit(constants.MARKET_SET_PROPERTY, { totalDepositsByInteresUSD });
        commit(constants.MARKET_SET_PROPERTY, { totalBorrowByInteresUSD });
        commit(constants.MARKET_SET_PROPERTY, { totalUSDWallet });
        commit(constants.MARKET_SET_PROPERTY, { loadingMarket: false });
        commit(constants.MARKET_SET_PROPERTY, { totalRbtcInWalletInUSD });
        dispatch(constants.MARKET_SET_ENTER_MARKET);
      });
  },

  // eslint-disable-next-line no-shadow
  [constants.MARKET_UPDATE_MARKET_USER_INFO]: async ({ commit, dispatch, rootState },
    currentMarket) => {
    const { account, walletAddress, chainId } = rootState.Session;
    const { markets } = state;
    const instance = newMarketInstance[currentMarket.type](currentMarket.id);
    const symbol = currentMarket.underlyingSymbol;

    // Update once market
    // ===========================================================================================
    const marketInfo = await Promise.all([
      instance.balanceOfUnderlying(walletAddress, account),
      instance.borrowBalanceCurrent(walletAddress),
      instance.balanceOfUnderlyingInWallet(account),
      instance.maxAllowedToDeposit(),
      instance.getCash(),
      instance.underlyingCurrentPrice(chainId),
      instance.getSupplierSnapshotUnderlyingAmountStored(walletAddress),
      instance.getBorrowerSnapshotUnderlyingAmountStored(walletAddress),
      instance.maxAllowedToWithdraw(),
    ]);

    const [
      supplyBalance, borrowBalance, underlyingBalance, maxAllowedToDeposit,
      cash, price, supplySnapshot, borrowSnapshot, maxAllowedToWithdraw,
    ] = marketInfo;
    Object.assign(currentMarket, {
      supplyBalance: reduceDecimals(supplyBalance, symbol),
      underlyingBalance: reduceDecimals(underlyingBalance, symbol),
      borrowBalance: reduceDecimals(borrowBalance, symbol),
      cash: reduceDecimals(cash, symbol),
      price: reduceDecimals(price, symbol),
      supplySnapshot,
      borrowSnapshot,
      maxAllowedToWithdraw: reduceDecimals(maxAllowedToWithdraw.underlying, symbol),
      maxAllowedToDeposit: reduceDecimals(maxAllowedToDeposit.underlying.value, symbol),
    });
    const updateMarkets = markets.map((mk) => {
      if (mk.id === currentMarket.id) {
        return currentMarket;
      }
      return mk;
    });
    commit(constants.MARKET_SET_PROPERTY, { markets: updateMarkets });
    dispatch(constants.MARKET_SET_ENTER_MARKET);
  },

  // eslint-disable-next-line no-shadow
  [constants.MARKET_UPDATE_WITHDRAW_INFO]: ({ commit, state }) => {
    Promise.all(state.markets.map(async (market) => {
      const instance = newMarketInstance[market.type](market.id);
      // Update with all the elements that are needed
      // ===========================================================================================
      const marketInfo = await Promise.all([
        instance.maxAllowedToWithdraw(),
      ]);

      const [maxAllowedToWithdraw] = marketInfo;
      Object.assign(market, {
        maxAllowedToWithdraw: maxAllowedToWithdraw.underlying,
      });
      // ===========================================================================================
      return market;
    }))
      .then((markets) => {
        commit(constants.MARKET_SET_PROPERTY, { markets });
      });
  },

  // eslint-disable-next-line no-shadow
  [constants.MARKET_UPDATE_RBTC_BALANCE_IN_WALLET]: ({ commit, state, rootState }) => {
    const { Session: { account, chainId } } = rootState;
    let totalUSDWallet = 0;
    let totalRbtcInWalletInUSD = 0;
    Promise.all(state.markets.map(async (market) => {
      const instance = newMarketInstance[market.type](market.id);
      const symbol = market.underlyingSymbol;

      // Update balance in wallet
      // =============================================================
      const marketInfo = await Promise.all([
        instance.balanceOfUnderlyingInWallet(account),
      ]);
      const [underlyingBalance] = marketInfo;
      Object.assign(market, {
        underlyingBalance: reduceDecimals(underlyingBalance, symbol),
      });
      if (market.is_listed) {
        totalUSDWallet
        += market.underlyingBalance * market.price;
      }
      if (market.id === addresses[chainId].kRBTC) {
        totalRbtcInWalletInUSD += underlyingBalance * market.price;
      }
      return market;
    }))
      .then((markets) => {
        commit(constants.MARKET_SET_PROPERTY, { loadedWallet: true });
        commit(constants.MARKET_SET_PROPERTY, { markets });
        commit(constants.MARKET_SET_PROPERTY, { totalUSDWallet });
        commit(constants.MARKET_SET_PROPERTY, { totalRbtcInWalletInUSD });
      });
  },

  // eslint-disable-next-line no-shadow
  [constants.MARKET_SET_ENTER_MARKET]: ({ dispatch, rootState, commit }) => {
    const { Comptroller: { comptroller }, Session: { walletAddress } } = rootState;

    comptroller.getAssetsIn(walletAddress).then((assetsIn) => {
      if (assetsIn.length > 0) {
        dispatch(constants.COMPTROLLER_GET_LIQUIDITY);
        dispatch(constants.COMPTROLLER_BORROW_MAX_CAPACITY);
        dispatch(constants.COMPTROLLER_GET_TOTAL_BORROWS);
        commit(constants.USER_SET_PROPERTY, { enterMarkets: true });
      } else {
        dispatch(constants.COMPTROLLER_ENTER_MARKET_SIMULATOR);
      }
    });
  },

};

const mutations = {
  // eslint-disable-next-line no-shadow
  [constants.MARKET_SET_PROPERTY]: (state, data) => {
    const [[property, value]] = Object.entries(data);
    state[property] = value;
  },

  // eslint-disable-next-line no-shadow
  [constants.MARKET_RESET_MARKET]: (state) => {
    state.info = initState;
    state.loadedWallet = false;
    state.totalDepositsInUSD = 0;
    state.totalBorrowsInUSD = 0;
    state.totalUSDWallet = null;
    state.markets.forEach((market) => {
      Object.assign(market, {
        supplyBalance: 0,
        borrowBalance: 0,
        maxAllowedToWithdraw: 0,
        maxAllowedToDeposit: 0,
        interestDeposit: 0,
        interestBorrow: 0,
        underlyingBalance: '',
      });
    });
  },
  // eslint-disable-next-line no-shadow
  [constants.MARKET_GET_DEST_MARKET]: (state, payload) => {
    state.dest_market = payload;
  },

  // eslint-disable-next-line no-shadow
  [constants.MARKET_UPDATE_DEST_MARKET]: (state, payload) => {
    state.dest_info = { ...state.dest_info, ...payload };
  },

  // eslint-disable-next-line no-shadow
  [constants.MARKET_UPDATE_DEST_SELECT]: (state, payload) => {
    state.dest_select = { ...state.dest_select, ...payload };
  },

};

const getters = {
  // eslint-disable-next-line no-shadow
  [constants.MARKET_LOADED]: (state) => !!state.markets.length,
  // eslint-disable-next-line no-shadow
  [constants.MARKET_ADDRESSES]: (state) => state.markets.map((m) => m.id),
};

export default {
  state,
  actions,
  mutations,
  getters,
};
