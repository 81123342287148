/* eslint-disable no-underscore-dangle */
import { BigNumber, ethers, FixedNumber } from 'ethers';
import CRBTCAbi from '@/abis/CRBTC.json';
import CRDOCAbi from '@/abis/CRDOC.json';
import PriceOracleProxyAbi from '@/abis/PriceOracleProxy.json';
import StandardTokenAbi from '@/abis/StandardToken.json';
import TropykusLensAbi from '@/abis/TropykusLens.json';
import InterestRateModelAbi from '@/abis/InterestRateModel.json';
import store from '@/store';
import Vue from 'vue';
import mathematics from '@/mathematics';
import PNumber from '@/pnumber';
import * as constants from '@/store/constants';
import { DEFAULT_ALLOWANCE } from '@/utils/commons.consts';
import { ADAPTER_STATUS } from '@web3auth/base';
import { addresses } from './constants';
import { signer } from './utils';
import Comptroller from './comptroller';

const factor = 1e18;
const gasPrice = 60000000;
const gasLimit = 900000;

export default class Market {
  constructor(address = '', MarketAbi, chainId = process.env.VUE_APP_DEFAULT_CHAIN_ID) {
    this.marketAddress = address.toLowerCase();
    this.chainId = chainId;
    this.lens = new ethers
      .Contract(addresses[this.chainId].tropykusLens, TropykusLensAbi, Vue.web3);
    this.instance = new ethers.Contract(this.marketAddress, MarketAbi, Vue.web3);
    this.wsInstance = new ethers.Contract(this.marketAddress, MarketAbi, Vue.web3Ws);
  }

  static async isCRbtc(address) {
    const instance = new ethers.Contract(address.toLowerCase(), CRBTCAbi, Vue.web3);
    try {
      const result = await instance.callStatic.symbol();
      return result === constants.CRBTC_SYMBOL;
    } catch (e) {
      return false;
    }
  }

  static async isCSat(address) {
    const instance = new ethers.Contract(address.toLowerCase(), CRBTCAbi, Vue.web3);
    try {
      const result = await instance.callStatic.symbol();
      return result === constants.CSAT_SYMBOL;
    } catch (e) {
      return false;
    }
  }

  static async isCRDoc(address) {
    const instance = new ethers.Contract(address.toLowerCase(), CRDOCAbi, Vue.web3);
    try {
      const result = await instance.callStatic.symbol();
      return result === constants.CRDOC_SYMBOL;
    } catch (e) {
      return false;
    }
  }

  get name() {
    return this.instance.callStatic.name();
  }

  get symbol() {
    return this.instance.callStatic.symbol();
  }

  decimals() {
    return this.instance.callStatic.decimals();
  }

  async getDecimalsTokens() {
    const underlyingAddress = await this.underlying();
    const underlyingAsset = new ethers.Contract(
      underlyingAddress,
      StandardTokenAbi,
      Vue.web3,
    );
    const decimal = await underlyingAsset.callStatic.decimals();
    return decimal;
  }

  async totalSupplyInUnderlying() {
    const totalSupply = PNumber.from(await this.instance.callStatic.totalSupply());
    const exchangeRate = await this.exchangeRateStored(null);
    return totalSupply.mul(exchangeRate).getDecimals();
  }

  async totalBorrowsInUnderlying() {
    return PNumber.from(await this.instance.callStatic.totalBorrows());
  }

  async totalSupplyUSD(chainId) {
    const totalSupplyInUnderlying = PNumber.from(await this.totalSupplyInUnderlying(), 0);
    const price = PNumber.from(await this.underlyingCurrentPrice(chainId), 0);
    return totalSupplyInUnderlying.mul(price);
  }

  async totalBorrowsUSD(chainId) {
    const totalBorrowsInUnderlying = PNumber.from(await this.totalBorrowsInUnderlying(), 0);
    const price = PNumber.from(await this.underlyingCurrentPrice(chainId), 0);
    return totalBorrowsInUnderlying.mul(price);
  }

  async reservesInUSD(chainId) {
    const reserves = await this.getReserves();
    const price = await this.underlyingCurrentPrice(chainId);
    return mathematics.mul(price, reserves);
  }

  async underlying() {
    if (this.marketAddress === addresses[this.chainId].krDOC) {
      return addresses[this.chainId].rDOC;
    }
    const { underlyingAssetAddress } = await this
      .lens.callStatic.cTokenMetadata(this.marketAddress);
    return underlyingAssetAddress;
  }

  async underlyingAssetName() {
    const underlyingAsset = new ethers.Contract(
      await this.underlying(),
      StandardTokenAbi,
      Vue.web3,
    );
    return underlyingAsset.callStatic.name();
  }

  async underlyingAssetSymbol() {
    const underlyingAsset = new ethers.Contract(
      await this.underlying(),
      StandardTokenAbi,
      Vue.web3,
    );
    return underlyingAsset.callStatic.symbol();
  }

  async underlyingAssetDecimals() {
    const underlyingAsset = new ethers.Contract(
      await this.underlying(),
      StandardTokenAbi,
      Vue.web3,
    );
    return underlyingAsset.callStatic.decimals();
  }

  async supplyRateAPY() {
    const modelAddress = await this.instance.interestRateModel();
    const model = new ethers.Contract(modelAddress, InterestRateModelAbi, Vue.web3);
    const blocksPerYear = await model.callStatic.blocksPerYear();
    const supplyRatePerBlock = await this.instance.callStatic.supplyRatePerBlock();
    return Number(supplyRatePerBlock.mul(blocksPerYear) / 1e18) * 100;
  }

  async borrowRateAPY() {
    const modelAddress = await this.instance.interestRateModel();
    const model = new ethers.Contract(modelAddress, InterestRateModelAbi, Vue.web3);
    const blocksPerYear = await model.callStatic.blocksPerYear();
    const borrowRatePerBlock = await this.instance.callStatic.borrowRatePerBlock();
    return Number(borrowRatePerBlock.mul(blocksPerYear) / 1e18) * 100;
  }

  async balanceOf(address) {
    return PNumber.from(await this.instance.callStatic.balanceOf(address)).getDecimals();
  }

  async balanceOfUnderlying(address, account) {
    let result = await this.instance.connect(account).callStatic
      .balanceOfUnderlying(address);

    if (addresses[this.chainId].kBRZ === this.marketAddress) {
      const value = Number(result) / 1e4;
      return value;
    }

    result = FixedNumber.from(result.toString(), 'fixed80x18');
    result = result.divUnsafe(FixedNumber.fromString(1e18.toString(), 'fixed80x18'));
    return Number(result._value);
  }

  async currentBalanceOfCTokenInUnderlying(address) {
    const cTokenBalance = PNumber.from(await this.instance.callStatic.balanceOf(address));
    const exchangeRate = PNumber.from(await this.exchangeRateCurrent());
    return cTokenBalance.mul(exchangeRate).getDecimals();
  }

  async borrowBalanceStored(address) {
    return PNumber.from(await this.instance.callStatic.borrowBalanceStored(address)).getDecimals();
  }

  async borrowBalanceCurrent(address) {
    const value = await this.instance.callStatic.borrowBalanceCurrent(address);
    if (addresses[this.chainId].kBRZ === this.marketAddress) {
      return value / 1e4;
    }
    return PNumber.from(value).getDecimals();
  }

  async borrowBalanceInUSD(chainId, address) {
    const price = await this.underlyingCurrentPrice(chainId);
    return mathematics.mul(await this.borrowBalanceStored(address), price);
  }

  async exchangeRateCurrent(account = '', isKSat = false) {
    if (isKSat && account) {
      return PNumber.from(await this.instance.connect(account)
        .callStatic.exchangeRateCurrent()).getDecimals();
    }
    return PNumber.from(await this.instance.callStatic.exchangeRateCurrent()).getDecimals();
  }

  async exchangeRateStored(account = '', isKSat = false) {
    if (isKSat && account) {
      return PNumber.from(await this.instance.connect(account)
        .callStatic.exchangeRateStored()).getDecimals();
    }
    return PNumber.from(await this.instance.callStatic.exchangeRateStored()).getDecimals();
  }

  async getTotalSupply() {
    return mathematics.div(await this.instance.callStatic.totalSupply(), factor);
  }

  async getReserves() {
    return mathematics.div(await this.instance.callStatic.totalReserves(), factor);
  }

  async getSubsidyFound(isRbtc = false) {
    let { subsidyFund } = this.instance.callStatic;
    subsidyFund = subsidyFund ? subsidyFund() : 0;
    subsidyFund = mathematics.div(subsidyFund, factor);
    return isRbtc ? subsidyFund : 0;
  }

  async getSupplierSnapshotStored(accountAddress) {
    return this.instance
      .callStatic
      .getSupplierSnapshotStored(accountAddress);
  }

  async getSupplierSnapshotUnderlyingAmountStored(accountAddress) {
    const supplySnapshot = await this.getSupplierSnapshotStored(accountAddress);
    const result = FixedNumber.from(supplySnapshot.underlyingAmount.toString(), 'fixed80x18')
      .divUnsafe(FixedNumber.from(factor.toString(), 'fixed80x18'));
    return result._value;
  }

  async getBorrowerSnapshotStored(accountAddress) {
    return this.instance
      .callStatic
      .borrowBalanceStored(accountAddress);
  }

  async getBorrowerSnapshotUnderlyingAmountStored(accountAddress) {
    const borrowerSnapshot = await this.getBorrowerSnapshotStored(accountAddress);
    const result = FixedNumber.from(borrowerSnapshot.toString(), 'fixed80x18')
      .divUnsafe(FixedNumber.from(factor.toString(), 'fixed80x18'));
    return result._value;
  }

  async getDebtInterest(address) {
    const borrowBalanceStored = PNumber.from(await this.borrowBalanceStored(address), 0);
    const borrowAPY = PNumber.from((await this.borrowRateAPY()).toString(), 0);
    return borrowBalanceStored.mul(borrowAPY.div('100.0')).getDecimals();
  }

  async getEarnings(accountAddress) {
    const { SESSION_GET_ACCOUNT } = store.getters;
    const account = SESSION_GET_ACCOUNT || null;

    const balanceOfUnderlying = (
      await this.instance.connect(account).callStatic.balanceOfUnderlying(accountAddress)
    ) / factor;
    const supplySnapshot = (
      await this.getSupplierSnapshotStored(accountAddress)
    ).underlyingAmount / factor;

    return balanceOfUnderlying > supplySnapshot ? balanceOfUnderlying - supplySnapshot : 0;
  }

  async getCash() {
    const value = await this.instance.callStatic.getCash();
    if (addresses[this.chainId].kBRZ === this.marketAddress) {
      return value / 1e4;
    }
    return PNumber.from(await this.instance.callStatic.getCash()).getDecimals();
  }

  async balanceOfUnderlyingInWallet(account) {
    const address = await account.getAddress();
    const underlyingAssetSymbol = await this.underlying();
    const underlyingAsset = new ethers.Contract(
      underlyingAssetSymbol,
      StandardTokenAbi,
      Vue.web3,
    );
    let balance = await underlyingAsset.callStatic.balanceOf(address);
    if (addresses[this.chainId].kBRZ === this.marketAddress) {
      return balance / 1e4;
    }
    balance = FixedNumber.from(balance.toString(), 'fixed80x18')
      .divUnsafe(FixedNumber.from(factor.toString(), 'fixed80x18'));
    balance = Market.parseFixedDecimals(balance._value);
    return balance;
  }

  async underlyingCurrentPrice(chainId) {
    const priceOracleProxyInstance = new ethers.Contract(
      addresses[chainId].priceOracleProxy,
      PriceOracleProxyAbi,
      Vue.web3,
    );
    const uPrice = await priceOracleProxyInstance.callStatic.getUnderlyingPrice(this.marketAddress);
    if (addresses[this.chainId].kBRZ === this.marketAddress) {
      return uPrice / 1e32;
    }
    return PNumber.from(uPrice).getDecimals();
  }

  static parseFixedDecimals(amount, decimals = 18) {
    const aux = amount.split('.');
    const decimalPart = aux[1] ? '.'.concat(aux[1].substring(0, decimals)) : '';
    return `${aux[0]}${decimalPart}`;
  }

  async allowance(account, requiredAllowance) {
    const underlyingAddress = await this.underlying();
    const underlyingAsset = new ethers.Contract(
      underlyingAddress,
      StandardTokenAbi,
      Vue.web3,
    );
    const walletAddress = await account.getAddress();
    const currentAllowance = await underlyingAsset
      .connect(account)
      .allowance(walletAddress, this.marketAddress);
    const decimals = await underlyingAsset.callStatic.decimals();
    const parsedRequiredAllowance = ethers.utils.parseUnits(requiredAllowance.toString(), decimals);
    return currentAllowance.gte(parsedRequiredAllowance);
  }

  // eslint-disable-next-line no-unused-vars
  async supply(account, amountIntended, connection = '', isCRbtc = false) {
    console.log('================== SUPPLY ==================');
    console.log('amountIntended: ', amountIntended);
    const { dispatch, commit, state } = store;
    const accountSigner = signer(account);
    let value = ethers.utils.parseEther(Market.parseFixedDecimals(amountIntended.toString()));
    console.log('value: ', Number(value) / 1e18);
    console.log('isCRbtc: ', isCRbtc);
    if (isCRbtc) {
      return this.instance.connect(accountSigner).mint({ value, gasLimit, gasPrice });
    }
    const underlyingAddress = await this.underlying();
    const underlyingAsset = new ethers.Contract(
      underlyingAddress,
      StandardTokenAbi,
      Vue.web3,
    );
    const decimals = await underlyingAsset.callStatic.decimals();
    value = ethers.utils.parseUnits(
      Market.parseFixedDecimals(amountIntended.toString(), decimals), decimals,
    );
    const approvedValue = FixedNumber.from(amountIntended.toString(), 'fixed80x18');
    const requiredAllowance = ethers.utils.parseUnits(approvedValue.toString(), decimals);
    const approve = await underlyingAsset.connect(accountSigner)
      .approve(this.marketAddress, requiredAllowance, { gasLimit, gasPrice });
    commit(constants.USER_SET_PROPERTY, { progressLinear: 50 });
    if (
      (window.ethereum && !window.ethereum.isMetaMask)
    || state.Session.web3AuthInstance?.status === ADAPTER_STATUS.CONNECTED
    ) {
      const transaction = await Vue.web3.waitForTransaction(approve.hash);
      dispatch(constants.USER_ADD_GAS_USED, transaction);
    }
    commit(constants.USER_SET_PROPERTY, { progressLinear: 50 });
    return this.instance.connect(accountSigner)
      .mint(value, { gasLimit, gasPrice });
  }

  async borrow(account, amountIntended, walletAddress, isCRbtc = false) {
    console.log('amountIntended: ', amountIntended);
    const comptroller = new Comptroller(this.chainId);
    const accountSigner = signer(account);

    const priceOracleProxyInstance = new ethers.Contract(
      addresses[this.chainId].priceOracleProxy,
      PriceOracleProxyAbi,
      Vue.web3,
    );
    if (amountIntended === -1) {
      const liquidityData = await comptroller.instance.connect(accountSigner)
        .callStatic.getAccountLiquidity(walletAddress);
      const price = await priceOracleProxyInstance
        .callStatic.getUnderlyingPrice(this.marketAddress);

      const result = FixedNumber.from(liquidityData[1].toString(), 'fixed80x18')
        .subUnsafe(FixedNumber.from(ethers.utils.parseEther('1'), 'fixed80x18'))
        .divUnsafe(FixedNumber.from(price.toString(), 'fixed80x18'))
        .mulUnsafe(FixedNumber.from('0.85', 'fixed80x18'));

      return this.instance.connect(accountSigner)
        .borrow(
          ethers.utils.parseEther(result._value),
          { gasLimit, gasPrice },
        );
    }
    let value = ethers.utils.parseEther(Market.parseFixedDecimals(amountIntended.toString()));
    console.log('value: ', value);
    if (isCRbtc) {
      return this.instance.connect(accountSigner)
        .borrow(value, { gasLimit, gasPrice });
    }
    const underlyingAddress = await this.underlying();
    const underlyingAsset = new ethers.Contract(
      underlyingAddress,
      StandardTokenAbi,
      Vue.web3,
    );
    const decimals = await underlyingAsset.callStatic.decimals();
    value = ethers.utils.parseUnits(
      Market.parseFixedDecimals(amountIntended.toString(), decimals), decimals,
    );
    return this.instance.connect(accountSigner)
      .borrow(value, { gasLimit, gasPrice });
  }

  async redeem(account, amountIntended, isCRbtc) {
    const accountSigner = signer(account);
    if (Number(amountIntended) === -1) {
      const { tokens } = await this.maxAllowedToWithdraw();
      return this.instance.connect(accountSigner)
        .redeem(tokens.fixedNumber, { gasLimit, gasPrice });
    }
    let value = ethers.utils.parseEther(Market.parseFixedDecimals(amountIntended.toString()));
    if (isCRbtc) {
      return this.instance.connect(accountSigner)
        .redeemUnderlying(value, { gasLimit, gasPrice });
    }
    const underlyingAddress = await this.underlying();
    const underlyingAsset = new ethers.Contract(
      underlyingAddress,
      StandardTokenAbi,
      Vue.web3,
    );
    const decimals = await underlyingAsset.callStatic.decimals();
    value = ethers.utils.parseUnits(
      Market.parseFixedDecimals(amountIntended.toString(), decimals), decimals,
    );
    return this.instance.connect(accountSigner)
      .redeemUnderlying(value, { gasLimit, gasPrice });
  }

  // eslint-disable-next-line no-unused-vars
  async repay(account, amountIntended, walletAddress, connection = '', isCRbtc = false) {
    const { dispatch, commit, state } = store;
    const accountSigner = signer(account);
    const isMax = amountIntended === -1;
    let value = isMax ? ethers.constants.MaxUint256
      : ethers.utils.parseEther(Market.parseFixedDecimals(amountIntended.toString()));
    const borrowBalanceCurrent = await this.instance.callStatic.borrowBalanceCurrent(walletAddress);
    if (isCRbtc) {
      if (isMax) {
        value = borrowBalanceCurrent.add(BigNumber.from(0.0001e18));
        return this.instance.connect(accountSigner)
          .repayBorrowAll({ value, gasLimit, gasPrice });
      }
      return this.instance.connect(accountSigner)
        .repayBorrow({ value, gasLimit, gasPrice });
    }
    const underlyingAddress = await this.underlying();
    const underlyingAsset = new ethers.Contract(
      underlyingAddress,
      StandardTokenAbi,
      Vue.web3,
    );
    const decimals = await underlyingAsset.callStatic.decimals();
    const borrowBalancePlusMargin = borrowBalanceCurrent / (10 ** decimals) + 0.5;
    value = isMax ? ethers.constants.MaxUint256 : ethers.utils.parseUnits(
      Market.parseFixedDecimals(amountIntended.toString(), decimals), decimals,
    );
    // The approved value has a small margin to cover the interest incurred
    // from the start of the operation until it ends.
    const approvedValue = FixedNumber.from(isMax ? borrowBalancePlusMargin.toString() : (amountIntended + 1).toString(), 'fixed80x18');

    const requiredAllowance = ethers.utils.parseUnits(approvedValue.toString(), decimals);

    const approve = await underlyingAsset.connect(accountSigner)
      .approve(this.marketAddress, requiredAllowance, { gasLimit, gasPrice });
    commit(constants.USER_SET_PROPERTY, { progressLinear: 50 });

    if (
      (window.ethereum && !window.ethereum.isMetaMask)
        || state.Session.web3AuthInstance?.status === ADAPTER_STATUS.CONNECTED
    ) {
      const transaction = await Vue.web3.waitForTransaction(approve.hash);
      dispatch(constants.USER_ADD_GAS_USED, transaction);
    }
    return this.instance.connect(accountSigner)
      .repayBorrow(value, { gasLimit, gasPrice });
  }

  async suppliedLast24Hours(chainId) {
    const supplyEvents = await this.wsInstance.queryFilter('Mint', -2880);
    const price = await this.underlyingCurrentPrice(chainId);
    let total = 0;
    const accounts = [];
    supplyEvents.forEach((supply) => {
      if (accounts.indexOf(supply.args.minter) === -1) {
        accounts.push(supply.args.minter);
        const aux = mathematics.mul(mathematics.div(supply.args.mintAmount, factor), price);
        total = mathematics.add(total, aux);
      }
    });
    return { total, accounts };
  }

  async borrowedLast24Hours() {
    const borrowEvents = await this.wsInstance.queryFilter('Borrow', -2880);
    let total = 0;
    const accounts = [];
    borrowEvents.forEach((borrow) => {
      if (accounts.indexOf(borrow.args.borrower) === -1) {
        accounts.push(borrow.args.borrower);
        const aux = mathematics.div(borrow.args.borrowAmount, factor);
        total = mathematics.add(total, aux);
      }
    });
    return { total, accounts };
  }

  async tropykusInterestAccrued(account) {
    const [,
      interestFactorMantissa,
      interestEarnedBN,
      exchangeRateMantissa,
      realAmountMantissa,
    ] = await this.instance.callStatic.tropykusInterestAccrued(account);
    const interestFactor = mathematics.div(Number(interestFactorMantissa), factor);
    const interestEarned = Number(interestEarnedBN);
    const exchangeRate = mathematics.div(exchangeRateMantissa, factor);
    const realAmount = mathematics.div(realAmountMantissa, factor);
    return {
      interestFactor,
      interestEarned,
      exchangeRate,
      realAmount,
    };
  }

  async liquidityUnderlying(account, walletAddress) {
    const comptroller = new Comptroller(this.chainId);
    const accountSigner = signer(account);

    const priceOracleProxyInstance = new ethers.Contract(
      addresses[this.chainId].priceOracleProxy,
      PriceOracleProxyAbi,
      Vue.web3,
    );

    const liquidityData = await comptroller.instance.connect(accountSigner)
      .callStatic.getAccountLiquidity(walletAddress);
    const price = await priceOracleProxyInstance.callStatic
      .getUnderlyingPrice(this.marketAddress);

    const result = FixedNumber.from(liquidityData[1].toString(), 'fixed80x18')
      .subUnsafe(FixedNumber.from(ethers.utils.parseEther('1'), 'fixed80x18'))
      .divUnsafe(FixedNumber.from(price.toString(), 'fixed80x18'));

    return Number(result._value) <= 1 ? 0 : +result._value;
  }

  async maxAllowedToWithdraw() {
    const { SESSION_GET_TROPYKUS } = store.getters;
    const tropykus = SESSION_GET_TROPYKUS;

    const market = tropykus.mkts.filter((mkt) => mkt.address === this.marketAddress).pop();
    const maxAllowedToWithdraw = await market.maxAllowedToWithdraw(tropykus.account, tropykus.mkts);
    if (maxAllowedToWithdraw.underlying < 0) {
      maxAllowedToWithdraw.underlying = 0;
    }
    if (addresses[this.chainId].kBRZ === this.marketAddress) {
      const price = await this.underlyingCurrentPrice(this.chainId);
      maxAllowedToWithdraw.underlying = maxAllowedToWithdraw.usd / price;
    }
    return maxAllowedToWithdraw;
  }

  async maxAllowedToDeposit() {
    const { SESSION_GET_TROPYKUS } = store.getters;
    const tropykus = SESSION_GET_TROPYKUS;
    const isCSat = await Market.isCSat(this.marketAddress);
    const market = tropykus.mkts.filter((mkt) => mkt.address === this.marketAddress).pop();

    if (isCSat) await market.setInternalCompanion(addresses[this.chainId].cRBTCCompanion);

    const maxAllowedToDeposit = await market.maxAllowedToDeposit(tropykus.account);

    return maxAllowedToDeposit;
  }
}
