import RLogin from '@rsksmart/rlogin';
import { WalletConnect2Provider } from '@rsksmart/rlogin-walletconnect2-provider';
import { trezorProviderOptions } from '@rsksmart/rlogin-trezor-provider';
import { ledgerProviderOptions } from '@rsksmart/rlogin-ledger-provider';
import {
  rpcUrls, supportedChains, manifestAppUrl, chainId, WC_PROJECT_ID
} from '@/config/network.config';

const optionalChainsId = Object.keys(rpcUrls).map(Number).filter((c) => c !== chainId);

export default new RLogin({
  supportedLanguages: 'es',
  cacheProvider: false,
  providerOptions: {
    walletconnect: {
      package: WalletConnect2Provider,
      options: {
        projectId: WC_PROJECT_ID,
        chains: supportedChains,
        optionalChainsId,
        showQrModal: true,
        rpcMap: rpcUrls,
      }
    },
    'custom-ledger': {
      ...ledgerProviderOptions,
      options: {
        rpcUrl: rpcUrls[chainId],
        chainId,
      },
    },
    'custom-trezor': {
      ...trezorProviderOptions,
      options: {
        rpcUrl: rpcUrls[chainId],
        chainId,
        manifestEmail: 'devs@tropykus.com',
        manifestAppUrl,
      },
    },
  },
  rpcUrls,
  supportedChains,
});
