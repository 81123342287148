import { graphqlClient } from '@/utils/graphql';
import marketsQuery from '@/graphql/query/Markets.gql';

export async function getMarkestInfo() {
  const marketsSubscribe = await graphqlClient.subscribe({
    query: marketsQuery.findManyMarkets,
    variables: {
      orderBy: [
        {
          id: 'asc',
        },
      ],
    },
  });

  return new Promise((resolve, reject) => {
    marketsSubscribe.subscribe({
      next(response) {
        const { data: { findManyMarkets } } = response;
        const markets = findManyMarkets;
        resolve({ markets });
      },
      error(error) {
        reject(error);
      },
    });
  });
}

export async function findOneMarket(findMarket = 'CRBTC') {
  const marketsSubscribe = await graphqlClient.subscribe({
    query: marketsQuery.findFirstMarkets,
    variables: {
      where: {
        name: {
          equals: findMarket,
        },
      },
    },
  });

  return new Promise((resolve, reject) => {
    marketsSubscribe.subscribe({
      next(response) {
        const { data: { findFirstMarkets } } = response;
        const market = findFirstMarkets;
        resolve({ market });
      },
      error(error) {
        reject(error);
      },
    });
  });
}
