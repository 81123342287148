<template>
  <svg
    width="10"
    height="11"
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="custom-icon"
  >
    <path
      d="M7.36842 0H1.05263C0.471053 0 0 0.4475 0 1V8H1.05263V1H7.36842V0ZM8.94737 2H3.15789C2.57632 2 2.10526 2.4475 2.10526 3V10C2.10526 10.5525 2.57632 11 3.15789 11H8.94737C9.52895 11 10 10.5525 10 10V3C10 2.4475 9.52895 2 8.94737 2ZM8.94737 10H3.15789V3H8.94737V10Z"
      fill="trasnparent"
    />
  </svg>
</template>

<script>
/* eslint max-len: 0 */

export default {
  name: 'Clipboard',
};
</script>
