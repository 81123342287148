<template>
  <svg
    width="64"
    height="64"
    viewBox="0 0 17 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="custom-icon"
  >
    <path
      d="M5.76059 6.88682C5.52697 7.00553 5.30953 7.14764 5.09748 7.2931C4.73627 7.54054 4.32655
      7.59906 3.89526 7.59404C3.45858 7.58736 3.04526 7.47534 2.63553 7.35161C1.90593 7.13092
      1.17634 6.9119 0.446737 6.68953C0.37845 6.66947 0.308365 6.64774 0.25086 6.61095C-0.047449
      6.42704 -0.0833898 6.13613 0.162805 5.8937C0.322741 5.73487 0.52401 5.62953 0.736061
      5.54594C1.1979 5.36203 1.66333 5.18815 2.12697 5.00758C2.61037 4.82032 3.09198 4.6314
      3.57539 4.44247C4.02285 4.30036 4.45414 4.11812 4.89262 3.94925C7.34378 3.00963 9.79674
      2.06333 12.2497 1.11702C12.7313 0.931434 13.2075 0.729132 13.6945 0.551908C14.0809 0.411466
      14.4619 0.25765 14.85 0.11888C15.0657 0.0419717 15.2903 -0.00317019 15.5221
      0.000173649C15.9588 0.00686134 16.2158 0.235915 16.2463 0.642192C16.2607 0.824431
      16.2301 1.00333 16.2032 1.18055C16.1187 1.77575 16.0235 2.36929 15.9264 2.96449C15.806
      3.71017 15.6838 4.45585 15.5581 5.19985C15.4233 5.99736 15.2813 6.79319 15.1501
      7.59237C15.0836 8.00032 14.9884 8.4066 14.956 8.81956C14.8734 9.11716 14.8428 9.42313
      14.7889 9.72574C14.6811 10.331 14.5841 10.9379 14.4762 11.5448C14.4385 11.7571 14.3576
      11.9578 14.2229 12.135C14.0072 12.4192 13.7107 12.5346 13.3441 12.4911C12.7834 12.4226
      12.2892 12.1902 11.8256 11.9059C10.848 11.3074 9.87581 10.7005 8.90541 10.0919C8.45255
      9.80767 8.01048 9.51007 7.58458 9.1924C7.53966 9.15896 7.49473 9.12552 7.4516
      9.09041C6.98976 8.72593 6.9664 8.32635 7.37972 7.9184C7.40488 7.89332 7.42824 7.86991
      7.4516 7.84483C7.47496 7.82978 7.50192 7.81808 7.52169 7.79969C8.97729 6.54742 10.4203
      5.28512 11.8238 3.98269C11.9568 3.85897 12.0718 3.72187 12.1599 3.56806C12.203 3.49115
      12.2407 3.41089 12.2641 3.3273C12.3 3.19354 12.2353 3.12332 12.088 3.11998C11.9927 3.11831
      11.9047 3.14673 11.8166 3.1785C11.4662 3.30724 11.1517 3.49449 10.8426 3.68843C9.20911
      4.70998 7.57919 5.73152 5.94748 6.75474C5.88279 6.79487 5.80911 6.82663 5.76059 6.88682Z"
      fill="trasnparent"
    />
  </svg>
</template>

<script>
export default {
  name: 'TelegramIcon',
};
</script>
