import { ROUTES } from '@/store/constants';

export default [
  {
    path: '*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "Building" */ '@/views/NotFound.vue'),
  },
  {
    path: '',
    redirect: { name: ROUTES.HOME.SECTION, params: { id: 'savings' } },
  },
  {
    path: '/auth',
    name: 'Web3Auth-Authentication',
    component: () => import(/* webpackChunkName: "Building" */ '@/views/Auth/Web3AuthLogin.view.vue'),
  },

  {
    path: '/home',
    component: () => import(/* webpackChunkName: "Home" */'@/views/Home/Home.vue'),
    children: [
      {
        path: '',
        name: ROUTES.HOME.DASHBOARD,
        component: () => import(/* webpackChunkName: "Home" */ '@/views/Home/Dashboard.vue'),
      },
      {
        path: 'section/:id',
        meta: { requiredWallet: true },
        name: ROUTES.HOME.SECTION,
        component: () => import(/* webpackChunkName: "Home" */'@/views/Home/Section.vue'),
      },
      {
        path: 'qr',
        meta: { requiredWallet: true },
        name: ROUTES.HOME.QR,
        component: () => import(/* webpackChunkName: "QR" */'@/views/Home/Qr.vue'),
      },
      {
        path: 'send',
        meta: { requiredWallet: true },
        name: ROUTES.HOME.SEND,
        component: () => import(/* webpackChunkName: "QR" */'@/views/Home/Send.vue'),
      },
      {
        path: 'sendWallet',
        meta: { requiredWallet: true },
        name: ROUTES.HOME.SENDWALLET,
        component: () => import(/* webpackChunkName: "QR" */'@/views/Home/SendWallet.vue'),
      },
    ],
  },
  {
    path: '/deposits',
    component: () => import(/* webpackChunkName: "Deposits" */ '@/views/Deposits/Deposits.vue'),
    children: [
      {
        path: '/',
        name: ROUTES.DEPOSIT.DASHBOARD,
        component: () => import(/* webpackChunkName: "Deposits" */ '@/views/Deposits/Dashboard.vue'),
      },
      {
        path: ':id',
        meta: { requiredWallet: true },
        component: () => import(/* webpackChunkName: "Deposits" */ '@/views/Deposits/Market.vue'),
        children: [
          {
            path: '/',
            name: ROUTES.DEPOSIT.DEPOSIT,
            component: () => import(/* webpackChunkName: "Deposits" */ '@/views/Deposits/MarketDeposit.vue'),
          },
          {
            path: 'withdraw',
            name: ROUTES.DEPOSIT.WITHDRAW,
            component: () => import(/* webpackChunkName: "Deposits" */ '@/views/Deposits/MarketWithdraw.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/borrows',
    component: () => import(/* webpackChunkName: "Borrows" */ '@/views/Borrows/Borrows.vue'),
    children: [
      {
        path: ':id',
        meta: { requiredWallet: true },
        component: () => import(/* webpackChunkName: "Borrows" */ '@/views/Borrows/Market.vue'),
        children: [
          {
            path: '/',
            name: ROUTES.BORROW.BORROW,
            component: () => import(/* webpackChunkName: "Borrows" */ '@/views/Borrows/MarketBorrow.vue'),
          },
          {
            path: 'repay',
            name: ROUTES.BORROW.REPAY,
            component: () => import(/* webpackChunkName: "Borrows" */ '@/views/Borrows/MarketRepay.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/balance',
    name: 'Balance',
    meta: { requiredWallet: true },
    component: () => import(/* webpackChunkName: "Balance" */ '@/views/Balance.vue'),
  },
  {
    path: '/buy-crypto',
    meta: { requiredWallet: false },
    component: () => import(/* webpackChunkName: "BuyCrypto" */ '@/views/BuyCrypto/Directory.vue'),
    children: [
      {
        path: '',
        name: ROUTES.BUY_CRYPTO.DIRECTORY,
        component: () => import(/* webpackChunkName: "BuyCrypto" */ '@/views/BuyCrypto/Directory.vue'),
      },
    ],
  },
  {
    path: '/markets',
    name: 'Markets',
    component: () => import(/* webpackChunkName: "Markets" */ '@/views/Markets.vue'),
  },
  {
    path: '/trade-swaps',
    name: 'Exchanges',
    component: () => import(/* webpackChunkName: "TradeSwaps" */ '@/views/TradeSwaps.vue'),
  },
  {
    path: '/blocked',
    name: 'Blocked',
    component: () => import(/* webpackChunkName: "AccessBlocked" */ '@/views/AccessBlocked.vue'),
  },
];
