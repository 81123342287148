<template>
  <v-card elevation="0" color="#E1F0E8" class="notifications"
    :class="active ? 'show' : 'hidden'">
    <div class="d-flex" v-if="!info.error">
      <v-icon class="btn-close" @click="setNotification">mdi-close</v-icon>
      <img v-if="!info.loading" src="@/assets/dialog/success.svg" alt=""/>
      <div v-if="info.loading" class="ml-3">
        <div class="text-value">Transacción en proceso</div>
        <div class="progress-bar-box mt-9">
          <v-progress-linear
            :buffer-value="progressLinear"
            color="#042F24"
            :value="progressLinear"
            stream
            rounded
          ></v-progress-linear>
        </div>
        <div class="d-flex mt-5 justify-end">
          <div class="p1-descriptions text-detail">
            {{ $t('notify.description5') }}
          </div>
          <a target="_blank" :href="`${explorer}/${info.hash}`">
            <img class="link ml-5" src="@/assets/icons/link.svg" />
          </a>
        </div>
      </div>
      <div v-else class="b1-main ml-3 d-flex flex-column">
        <div class="mb-1" v-if="info.action !== 'Exchange'">
          {{ textAction(info.action) }} <br />
          <div class="mt-1">{{info.amount | formatDecimals(info.symbol)}} {{info.symbol}}</div>
        </div>
        <div class="mb-1" v-else>
          <div class="mb-1">
          {{ textAction(info.action) }} <br />
          </div>
          <span class="mt-1">{{info.amount | formatDecimals(info.symbol)}} {{info.symbol}}</span>
            {{$t('notify.to')}}
          <span class="mt-1">
            {{info.swap | formatDecimals(info.symbolSwap)}} {{info.symbolSwap}}
          </span>
        </div>
        <div class="p1-descriptions text-gasprice mt-1">
          {{$t('notify.description9')}} <br>
          <span class="text-bold">{{info.gasPrice | formatPrice}} USD</span>
        </div>
        <div class="d-flex mt-2">
          <div class="p1-descriptions text-detail">
            {{$t('notify.description5')}}
          </div>
          <a target="_blank" :href="`${explorer}/${info.hash}`">
            <img class="link ml-5" src="@/assets/icons/link.svg" />
          </a>
        </div>
      </div>
    </div>
    <div v-else>
      <v-icon class="btn-close" @click="setNotification">mdi-close</v-icon>
      <div class="d-flex">
        <img src="@/assets/dialog/cancel.svg" alt=""/>
        <div class="b1-main ml-2">
          {{ info.action === 'Swap'
              ? $t('notify.description8')
              : $t('notify.description7')
          }}
        </div>
      </div>
      <div class="d-flex mt-5 justify-end">
        <div class="p1-descriptions text-detail">
          {{ $t('notify.description5') }}
        </div>
        <a target="_blank" :href="`${explorer}/${info.hash}`">
          <img class="link ml-5" src="@/assets/icons/link.svg" />
        </a>
      </div>
    </div>
  </v-card>
</template>
<script>
import { mapMutations, mapState } from 'vuex';
import * as constants from '@/store/constants';

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  watch: {
    data() {
      this.info = this.data;
      setTimeout(() => {
        this.active = true;
      }, 1000);
    },
  },
  data() {
    return {
      info: {},
      active: false,
    };
  },
  computed: {
    ...mapState({
      chainId: (state) => state.Session.chainId,
      progressLinear: (state) => state.Users.progressLinear,
    }),
    explorer() {
      switch (this.chainId) {
        case 30:
          return 'https://explorer.rsk.co/tx';
        case 31:
          return 'https://explorer.testnet.rsk.co/tx';
        default:
          return '';
      }
    },
  },
  methods: {
    ...mapMutations({
      closeNotification: constants.USER_SET_PROPERTY,
    }),
    setNotification() {
      this.active = false;
      setTimeout(() => {
        this.closeNotification({ notifications: null });
      }, 1000);
    },
    textAction(action) {
      if (action === 'Mint') return this.$t('notify.description1');
      if (action === 'Redeem') return this.$t('notify.description2');
      if (action === 'Borrow') return this.$t('notify.description3');
      if (action === 'Exchange') return this.$t('notify.description6');
      return this.$t('notify.description4');
    },
  },
  created() {
    this.info = this.data;
    setTimeout(() => {
      this.active = true;
    }, 1000);
  },
};
</script>
