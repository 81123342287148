<template>
  <v-dialog v-model="dialog" width="414" overlay-opacity="0.6" overlay-color="#000">
    <v-card :height="modalHeight" class="modal-connect-wallet pt-2" v-click-outside="closeDialog">
      <v-icon class="btn-close-dialog" @click="closeDialog">mdi-close</v-icon>
      <v-row class="mb-4 ma-0 d-flex justify-center mt-4">
        <h1 class="h3-sections-heading text-detail">
          <!-- TODO: Use i18n -->
          Escanea tu Dirección
        </h1>
      </v-row>

      <div v-if="qr" class="tpks-qr-preview-wrapper">
        <img class="tpks-qr-img" :src="qr" />

        <v-chip color="#CFE7DA">{{ walletAddress }}</v-chip>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import QRious from 'qrious';
import { mapState } from 'vuex';

export default {
  name: 'ConnectWallet',
  data() {
    return {
      dialog: this.showModal,
      isMobile: false,
      isMetamask: false,
      isBrave: false,
      qr: null,
    };
  },
  props: {
    showModal: {
      required: true,
      type: Boolean,
    },
  },
  methods: {
    closeDialog() {
      this.$emit('closed', false);
    },
  },
  computed: {
    ...mapState({
      walletAddress: (state) => state.Session.walletAddress,
    }),
    modalHeight() {
      if (!this.isMobile) return '100%';
      return 230;
    },
  },
  async mounted() {
    if ('ontouchstart' in document.documentElement) this.isMobile = true;
    else this.isMobile = false;

    const qrInstance = new QRious({
      value: this.walletAddress,
      size: 240,
      backgroundAlpha: 0,
    });
    this.qr = qrInstance.toDataURL();
  },
};
</script>

<style scoped lang="scss">
  .tpks-qr-preview-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem 1rem 2rem 1rem;
    align-items: center;
  }
  .tpks-qr-img {
    width: 100%;
    max-width: 15rem;
  }
</style>
