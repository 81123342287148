<template>
  <container v-if="allowedRegion && onlineApp" />
  <no-service v-else-if="!onlineApp" />
  <access-blocked v-else />
</template>
<script>
import Vue from 'vue';
import Container from '@/components/container/Container.vue';
import AccessBlocked from '@/views/AccessBlocked.vue';
import NoService from '@/views/NoService.vue';

export default {
  components: {
    Container,
    AccessBlocked,
    NoService,
  },
  data() {
    return {
      allowedRegion: true,
      onlineApp: true,
    };
  },
  computed: {
    renderApp() {
      return this.allowedRegion !== 'USD';
    },
  },
  methods: {
    async getRegion() {
      const response = await fetch('https://21r4busya4.execute-api.us-east-1.amazonaws.com/default/ip-checking-service');
      const data = await response.json();
      this.allowedRegion = data !== 'US';
    },
    getOnlineAppData() {
      const stopApp = process.env.VUE_APP_STOP_APP;
      Vue.db
        .collection('apps-online')
        .doc(stopApp)
        .onSnapshot((doc) => {
          const { online } = doc.data();
          this.onlineApp = online;
        });
    },
  },
  created() {
    this.getRegion();
    this.getOnlineAppData();
  },
};
</script>
