<template>
  <div class="referral-user">
    <v-dialog v-model="dialog" persistent content-class="d-saving-amazon"
    overlay-opacity="0.6" overlay-color="#000">
    <v-icon class="btn-close-dialog" @click="closeDialog">mdi-close</v-icon>
    <div class="dialog-saving-amazon secondary-color">
      <div class="d-flex justify-center align-center content-saving-amazon">
        <div v-if="option === 1" class="form-saving-amazon">
          <div class="referral-content">
            <img src="@/assets/dialog/bitcoinTree.svg" alt="bitcoinTree" />
            <div class="form-content ml-10">
              <div class="title mb-5 text-bg">
                Si alguien te recomendó Tropykus escribe el código de referidos
                y te enviaremos una sorpresa a tu mail
              </div>
              <div class="input-control mb-4">
                <label class="p1-descriptions">
                  Codigo
                </label> <br>
                <input
                  class="input-saving text-info primary-bg"
                  :class="validCode ? '' : 'alert'"
                  type="string"
                  v-model="code"
                  @input="getCode"
                />
                <div class="alert-description">
                  {{ descriptionCode }}
                </div>
              </div>
              <div class="input-control mb-4">
                <label class="p1-descriptions">
                  Correo
                </label> <br>
                <input
                  class="input-saving text-info primary-bg"
                  :class="!validEmail || existEmail ? 'alert' : ''"
                  v-model="email"
                  @input="getEmail"
                />
                <div class="alert-description">
                  {{ descriptionEmail }}
                </div>
              </div>
              <div class="d-flex align-center justify-start p1-descriptions mt-5">
                <v-checkbox
                  v-model="checkTyC"
                  class="checkbox"
                  hide-details
                ></v-checkbox>
                <span>{{$t('dialog.saving-amazon.tyc1')}}&nbsp;</span>
                <a target="_blank" class="p1-descriptions text-detail"
                  href="https://somos.tropykus.com/referidos_tc">
                  {{$t('dialog.saving-amazon.tyc2')}}
                </a>
              </div>
            </div>
          </div>
          <div class="d-flex justify-space-around mt-7 mb-3">
            <v-btn text class="btn btn1 btn-secondary mr-3" @click="closeDialog">
              <span class="b2-secondary text-detail">
                {{$t('dialog.saving-amazon.btn1')}}
              </span>
            </v-btn>
            <v-btn text class="btn"
              :disabled="!activeButton"
              :class="activeButton ? 'primary-color' : 'secondary-bg'"
              @click="saveData"
              >
              <span class="white--text">
                Continuar
              </span>
            </v-btn>
          </div>
        </div>
        <div v-if="option === 2" class="code">
          <div class="code-saving-amazon">
            <div class="d-flex flex-column text-left">
              <div class="text-center">
                <img src="@/assets/dialog/tropykusTree.svg" alt="tropykusTree" />
              </div>
              <span class="text-center my-2 text-detail referral-description">
                ¡Bienvenido al Tropykus! Recuerda revisar tu correo electrónico incluyendo
                la bandeja de promociones para recibir una sorpresa.
              </span>
            </div>
            <v-btn text class="btn btn2 primary-color mt-4"
              @click="closeDialog">
              <span class="white--text">
                {{ $t('dialog.saving-amazon.btn4') }}
              </span>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex';
import * as constants from '@/store/constants';

export default {
  name: 'ReferralContent',
  data() {
    return {
      dialog: false,
      option: 1,
      code: null,
      email: '',
      checkTyC: false,
      existCode: false,
      existEmail: false,
      validUser: false,
      rules: {
        code: () => ((this.code !== '')
          ? this.existCode === true : true)
          || 'ingresa otro alias este ya esta en uso',
        email: () => ((this.email !== '')
          ? this.validEmail === true : true)
          || 'Ingrese un correo valido',
      },
    };
  },
  watch: {
    actionBorrow() {
      if (this.validationUser) this.dialog = true;
    },
    dialogLoading() {
      if (this.validationUser) this.dialog = true;
    },
    walletAddress() {
      if (!this.walletAddress) return;
      this.getExistWalletAddress();
      this.getValidatedUser();
    },
  },
  computed: {
    ...mapState({
      walletAddress: (state) => state.Session.walletAddress,
      actionBorrow: (state) => state.Users.actionBorrow,
      dialogLoading: (state) => state.Users.dialogLoading,
      chainId: (state) => state.Session.chainId,
    }),
    validationUser() {
      return this.actionBorrow && !this.dialogLoading && !this.existUser && this.validUser;
    },
    activeButton() {
      return this.code !== ''
        && this.existCode && !this.existEmail && this.checkTyC;
    },
    validEmail() {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.email !== '') {
        return pattern.test(this.email);
      }
      return true;
    },
    validCode() {
      return this.code ? this.existCode : true;
    },
    descriptionEmail() {
      if (!this.validEmail && this.email !== '') return 'correo no valido';
      return this.existEmail ? 'Email ya existe' : '';
    },
    descriptionCode() {
      if (!this.code) return null;
      return !this.existCode ? 'Ingrese un codigo valido' : '';
    },
    typeDbName() {
      return this.chainId === 31 ? 'testnet' : 'mainnet';
    },
  },
  methods: {
    ...mapMutations({
      usersSetProperty: constants.USER_SET_PROPERTY,
    }),
    closeDialog() {
      this.dialog = false;
      this.usersSetProperty({ actionBorrow: false });
      this.existUser = true;
    },
    getCode() {
      this.$db
        .collection(`referidos-${this.typeDbName}`)
        .where('code', '==', `${this.code}`)
        .get()
        .then((data) => {
          if (data.docs.length > 0) {
            const { exists } = data.docs[0];
            this.existCode = exists;
          } else {
            this.existCode = false;
          }
        });
    },
    getEmail() {
      this.$db
        .collection(`referidos-${this.typeDbName}`)
        .where('email', '==', `${this.email}`)
        .get()
        .then((data) => {
          if (data.docs.length > 0) {
            const { exists } = data.docs[0];
            this.existEmail = exists;
          } else {
            this.existEmail = false;
          }
        });
    },
    getExistWalletAddress() {
      this.$db
        .collection(`referidos-${this.typeDbName}`)
        .where('walletAddress', '==', `${this.walletAddress}`)
        .get()
        .then((data) => {
          if (data.docs.length > 0) {
            const { exists } = data.docs[0];
            this.existUser = exists;
          } else {
            this.existUser = false;
          }
        });
    },
    async saveData() {
      this.$db.collection(`referidos-${this.typeDbName}`).add({
        tyc: this.checkTyC,
        email: this.email,
        code: this.code,
        type: 'referido',
        walletAddress: this.walletAddress,
      })
        .then(() => {
          this.option = 2;
        })
        .catch((error) => {
          console.error('Error: ', error);
        });
    },
    getValidatedUser() {
      const definedDate = new Date('Fri Jun 24 2022 00:00:00 GMT-0600 (central)');
      this.$db
        .collection(`users-logs-${this.typeDbName}`)
        .doc(`${this.walletAddress}`)
        .onSnapshot((doc) => {
          const data = doc.data();
          if (!data?.firstTxIn) return;
          const userFirstTxDate = new Date(data.firstTxIn.seconds * 1000);
          if (userFirstTxDate >= definedDate) {
            this.validUser = true;
          } else {
            this.validUser = false;
          }
        });
    },
  },
};
</script>
