<template>
   <div  class="show-addresses">
      <div class="address-card">
            <span class="address-card-tex">RSK</span>
             <div class="image-container">
               <img src="@/assets/rsk.svg" alt="" class="" width="25">
              </div>
             <span>
             {{ rskAddress.substring(0, 8) }}...{{ rskAddress.substring(rskAddress.length - 6) }}
             </span>
             <copy-clipboard
                   :valueToCopy="rskAddress"
                   :confirmationText="$t('dialog.fastBtc.fastBtc2.clipboard')"
                   class="copy"
             />
           </div>
           <div class="address-card">
             <span >BTC</span>
             <div class="image-container">
               <img src="@/assets/btc.png" alt="" class="" width="25">
              </div>
             <span>
             {{ rskAddress.substring(0, 8) }}...{{ rskAddress.substring(rskAddress.length - 6) }}
             </span>
             <copy-clipboard
                   :valueToCopy="rskAddress"
                   :confirmationText="$t('dialog.fastBtc.fastBtc2.clipboard')"
                   class="copy"
             />
           </div>
           <span class="address-card-text"> {{ $t('home.donation.description3')}}</span>
   </div>
</template>

<script>
import CopyClipboard from '@/components/general/CopyClipboard.vue';

export default {
  components: {
    CopyClipboard,
  },

  data() {
    return {
      rskAddress: '0xbd230dedad3fbaf8b52400ac41a87d2e6bde86d6',
      btcAddress: 'bc1qheu2h7jum426rfgmgc9zq4fmy3u37w3a4yk5vleapmekdz94dcvskg8twm',
    };
  },
};
</script>
