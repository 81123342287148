<template>
  <v-img
    contain
    :src="require(`@/assets/icons/assets/ayuda.svg`)"
    width="56"
    height="56"
    border="50%"
  > </v-img>
</template>

<script>
export default {
  name: 'FeedbackBubbleIcon',
};
</script>
