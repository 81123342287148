<template>
  <svg
    width="28"
    height="29"
    viewBox="0 0 28 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="custom-icon"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.9817 0C6.2425 0 0 6.46545 0 14.4811C0 22.4967 6.2425 29 13.9817 29C21.721 29 28 22.4967 28 14.4811C28 6.46545 21.721 0 13.9817 0ZM17.5593 20.8709C16.9022 21.4381 16.0991 21.854 15.2229 22.043V23.0639C15.2229 24.5007 12.7771 24.5007 12.7771 23.0639V22.043C10.5867 21.5515 9.01695 19.8123 8.98044 17.7705C8.94394 16.3716 11.3533 16.3338 11.3898 17.7327C11.3898 18.6023 11.9374 19.3585 12.7771 19.7366C12.7771 19.7366 13.8722 20.3416 15.2229 19.7366C15.2678 19.7166 15.3106 19.6939 15.3522 19.669C15.527 19.5756 15.6972 19.4727 15.8435 19.3207C16.7927 18.4889 16.9022 17.0143 15.8801 16.1447C15.7322 16.0222 15.5369 15.9001 15.3518 15.798C15.3102 15.7715 15.2675 15.7481 15.2229 15.7288C14.3282 15.3401 13.8076 15.5855 12.7771 15.3984V15.3885C12.554 15.3515 12.3379 15.3008 12.1287 15.238C12.0429 15.2116 11.9538 15.1817 11.8615 15.1488C11.3245 14.9533 10.8437 14.6739 10.4407 14.3299C8.43286 12.6662 8.54237 9.83051 10.4407 8.12907C11.0613 7.56193 11.8644 7.14602 12.7771 6.95698V5.93611C12.7771 4.49935 15.2229 4.49935 15.2229 5.93611V6.95698C17.1943 7.37288 18.983 8.9987 19.0196 11.1917C19.0561 12.6284 16.6467 12.6662 16.6102 11.2295C16.5737 10.322 15.0404 8.16688 12.7771 9.22555C12.5438 9.33482 12.3393 9.45203 12.182 9.62066C12.1736 9.62747 12.1649 9.63465 12.1565 9.64146C11.2438 10.4733 11.0613 11.91 12.0469 12.7419C12.1612 12.8205 12.2853 12.8991 12.4138 12.9729C12.5255 13.0613 12.6471 13.1252 12.7771 13.1578C13.59 13.3619 15.2229 13.4602 15.2229 13.4602C15.6293 13.548 16.0199 13.6848 16.3875 13.8587C16.4302 13.8852 16.4685 13.9037 16.5021 13.9143C16.8836 14.1053 17.2388 14.3363 17.5593 14.5945C19.5306 16.296 19.4941 19.1317 17.5593 20.8709Z"
      fill="#41907C"
    />
  </svg>
</template>

<script>
/* eslint max-len: 0 */

export default {
  name: 'NoCommission',
};
</script>
