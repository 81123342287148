<template>
  <svg
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="custom-icon"
  >
    <circle cx="14.4075" cy="14.8235" r="13.5677" fill="#41907C" stroke="#41907C" />
    <path
      d="M13.6219 11.7456C13.2859 11.7456 12.9685 11.7876 12.6699 11.8716C12.3712 11.9556 12.0399 12.0909 11.6759 12.2776L11.0879 10.6956C11.4519 10.4622 11.8812 10.2849 12.3759 10.1636C12.8705 10.0329 13.3559 9.96755 13.8319 9.96755C14.4292 9.96755 14.9192 10.0469 15.3019 10.2056C15.6939 10.3642 16.0019 10.5649 16.2259 10.8076C16.4499 11.0502 16.6039 11.3162 16.6879 11.6056C16.7812 11.8949 16.8279 12.1702 16.8279 12.4316C16.8279 12.6929 16.7859 12.9356 16.7019 13.1596C16.6272 13.3742 16.5292 13.5749 16.4079 13.7616C16.2865 13.9482 16.1465 14.1256 15.9879 14.2936C15.8292 14.4616 15.6752 14.6202 15.5259 14.7696C15.4139 14.8816 15.2972 15.0029 15.1759 15.1336C15.0545 15.2549 14.9425 15.3856 14.8399 15.5256C14.7465 15.6656 14.6672 15.8149 14.6019 15.9736C14.5365 16.1229 14.5039 16.2769 14.5039 16.4356C14.5039 16.4729 14.5039 16.5289 14.5039 16.6036C14.5039 16.6782 14.5085 16.7436 14.5179 16.7996H12.6699C12.6512 16.7062 12.6372 16.6036 12.6279 16.4916C12.6279 16.3702 12.6279 16.2676 12.6279 16.1836C12.6279 15.9222 12.6605 15.6889 12.7259 15.4836C12.7912 15.2689 12.8752 15.0729 12.9779 14.8956C13.0899 14.7089 13.2159 14.5362 13.3559 14.3776C13.4959 14.2189 13.6405 14.0649 13.7899 13.9156C14.0045 13.7009 14.2005 13.4909 14.3779 13.2856C14.5552 13.0709 14.6439 12.8282 14.6439 12.5576C14.6439 12.3336 14.5552 12.1422 14.3779 11.9836C14.2099 11.8249 13.9579 11.7456 13.6219 11.7456ZM14.9239 18.7876C14.9239 19.1982 14.7885 19.5156 14.5179 19.7396C14.2565 19.9636 13.9579 20.0756 13.6219 20.0756C13.2859 20.0756 12.9825 19.9636 12.7119 19.7396C12.4505 19.5156 12.3199 19.1982 12.3199 18.7876C12.3199 18.3769 12.4505 18.0596 12.7119 17.8356C12.9825 17.6116 13.2859 17.4996 13.6219 17.4996C13.9579 17.4996 14.2565 17.6116 14.5179 17.8356C14.7885 18.0596 14.9239 18.3769 14.9239 18.7876Z"
      fill="#E6F1EB"
    />
  </svg>
</template>

<script>
/* eslint max-len: 0 */

export default {
  name: 'Support',
};
</script>
