import Vue from 'vue';
import { ADAPTER_STATUS } from '@web3auth/base';
import * as constants from '@/store/constants';
import { addresses } from '@/middleware/contracts/constants';
import { SovrynSwap, getFeePrice, transferTokens } from '@/middleware';
import {
  EVENT_ERROR, EVENT_NAME, setTransactionTypeMP, setTransactionSwapMP,
} from '../../utils/mixpanel';

const state = {
  user: [],
  dialogLoading: false,
  notifications: null,
  infoLoading: {
    type: '',
    amount: '',
    symbol: '',
    wallet: true,
    loading: false,
    deposit: true,
    success: null,
    firstTx: false,
    closable: false,
  },
  swapDialog: false,
  swapInfo: {
    type: '',
    amount: '',
    symbol: '',
    wallet: true,
    loading: false,
    exchange: true,
    sign: false,
    success: null,
    time: 0,
  },
  updateSwap: false,
  rejectEvent: false,
  errorEvent: false,
  updateMarket: false,
  depositUpdate: false,
  gasUsed: 0,
  progressLinear: 0,
  error: {},
  actionBorrow: false,
  currentAction: null,
  enterMarkets: false,
  totalRepayError: false,
};
const actions = {
  [constants.USER_ACTION]: ({ commit, dispatch, rootState }, data) => {
    const {
      Market: { instance },
      Session: { wallet },
    } = rootState;
    state.rejectEvent = false;
    state.errorEvent = false;
    state.swapDialog = false;
    state.totalRepayError = false;
    const { action, market } = data;

    commit(constants.USER_SET_PROPERTY, { dialogLoading: true });
    commit(constants.USER_SET_PROPERTY, { gasUsed: 0 });
    commit(constants.USER_SET_PROPERTY, { progressLinear: 10 });
    dispatch(constants.USER_DIALOG_INFO_CLOSE, false);
    let counterError = 0;
    instance.wsInstance.on('Failure', (from, to, amount, event) => {
      if (counterError > 0) return;
      const { error, detail, info } = event.args;
      const message = EVENT_ERROR[Number(info)];

      const err = {
        detail: Number(detail),
        info: Number(info),
        error: true,
        typeError: Number(error),
        hash: event.transactionHash,
        message,
      };
      setTransactionTypeMP(EVENT_ERROR[action], market.symbol, data.amount, wallet, err);
      commit(constants.USER_SET_PROPERTY, { error: err });
      counterError += 1;
      commit(constants.USER_SET_PROPERTY, {
        notifications: !state.dialogLoading ? err : null,
      });
      state.errorEvent = true;
    });

    switch (action) {
      case constants.USER_ACTION_MINT:
        dispatch(constants.USER_ACTION_MINT, data);
        break;
      case constants.USER_ACTION_REDEEM:
        dispatch(constants.USER_ACTION_REDEEM, data);
        break;
      case constants.USER_ACTION_BORROW:
        dispatch(constants.USER_ACTION_BORROW, data);
        break;
      case constants.USER_ACTION_REPAY:
        dispatch(constants.USER_ACTION_REPAY, data);
        break;
      case constants.USER_ACTION_SEND:
        dispatch(constants.USER_ACTION_SEND, data);
        break;
      default:
        break;
    }
  },

  [constants.USER_ACTION_SWAP_DIALOG]: ({ commit }, info) => {
    const { data } = info;
    commit(constants.USER_ACTION_SWAP_DIALOG, data);
  },

  [constants.USER_ACTION_MINT]: async ({ commit, dispatch, rootState }, data) => {
    const {
      Session,
      Market: { instance },
      Comptroller: { comptroller },
    } = rootState;
    const { provider, chainId, web3AuthInstance } = Session;
    const { market, amount } = data;
    setTransactionTypeMP(EVENT_NAME.DPS, market.symbol, data.amount, Session.wallet);
    const info = {};
    info.type = 'deposit';
    info.loading = true;
    info.symbol = market.underlyingSymbol;
    info.wallet = true;
    info.amount = 1;
    commit(constants.USER_ACTION_INFO_DIALOG, info);

    if (
      market.id !== addresses[Session.chainId].kSAT
      && market.id !== addresses[Session.chainId].kRBTC
    ) {
      const enoughAllowance = await instance.allowance(Session.account, amount);
      info.amount = !enoughAllowance ? info.amount + 1 : info.amount;
    }
    commit(constants.USER_ACTION_INFO_DIALOG, info);

    const assetsIn = await comptroller.getAssetsIn(Session.walletAddress);
    const markets = await comptroller.allMarkets();
    const existMarket = markets.length === assetsIn.length;
    info.amount = existMarket ? info.amount : info.amount + 1;
    commit(constants.USER_ACTION_INFO_DIALOG, info);

    if (!existMarket) {
      try {
        const allMarkets = await comptroller.allMarkets();
        const tx = await comptroller.enterMarkets(Session.account, allMarkets);
        if (
          (window.ethereum && !window.ethereum.isMetaMask)
          || web3AuthInstance.status === ADAPTER_STATUS.CONNECTED
        ) {
          const transaction = await provider.waitForTransaction(tx.hash);
          dispatch(constants.USER_ADD_GAS_USED, transaction);
        }

        commit(constants.USER_SET_PROPERTY, { progressLinear: 30 });
      } catch (error) {
        setTransactionTypeMP(EVENT_NAME.DPR, market.symbol, amount, Session.wallet);
        state.rejectEvent = true;
        info.loading = false;
        commit(constants.USER_ACTION_INFO_DIALOG, info);
        return;
      }
    }

    instance
      .supply(Session.account, amount, Session.typeConnection)
      .then((tx) => {
        info.hash = tx.hash;
        info.wallet = false;
        info.action = 'Mint';
        commit(constants.USER_SET_PROPERTY, { progressLinear: 80 });
        commit(constants.USER_ACTION_INFO_DIALOG, info);
        commit(constants.USER_SET_PROPERTY, { notifications: info });
        dispatch(constants.USER_DIALOG_INFO_CLOSE, true);
        return provider.waitForTransaction(tx.hash);
      })
      .then((transaction) => {
        info.status = transaction.status;
        dispatch(constants.USER_ADD_GAS_USED, transaction);
        return getFeePrice(state.gasUsed, chainId);
      })
      .then(async (feePrice) => {
        if (!Session.userId) await new Promise((resolve) => setTimeout(() => resolve(''), 3000));
        if (info.status) {
          info.loading = false;
          info.deposit = true;
          info.amount = amount;
          info.gasPrice = feePrice;
          // notify
          commit(constants.USER_SET_PROPERTY, {
            notifications: !state.dialogLoading ? info : null,
          });
          dispatch(constants.SESSION_GET_USER_ID, Session.walletAddress);
          dispatch(constants.MARKET_UPDATE_MARKET_USER_INFO, market);
          dispatch(constants.MARKET_UPDATE_WITHDRAW_INFO);
          dispatch(constants.MARKET_UPDATE_RBTC_BALANCE_IN_WALLET);
          dispatch(constants.COMPTROLLER_GET_TOTAL_DEPOSITS);
          commit(constants.USER_SET_PROPERTY, { progressLinear: 100 });
          state.updateMarket = true;
          state.depositUpdate = true;
          setTimeout(() => {
            state.updateMarket = false;
            state.depositUpdate = false;
          }, 500);
          setTransactionTypeMP(EVENT_NAME.DPC, market.symbol, data.amount, Session.wallet);
        } else {
          dispatch(constants.USER_ACTION_ERROR, {
            hash: info.hash, currency: market.symbol, amount: data.amount, event: EVENT_NAME.DPE,
          });
        }
        setTimeout(() => {
          info.loading = false;
          commit(constants.USER_ACTION_INFO_DIALOG, info);
        }, 2000);
      })
      .catch(() => {
        setTransactionTypeMP(EVENT_NAME.DPR, market.symbol, data.amount, Session.wallet);
        state.rejectEvent = true;
        info.loading = false;
        commit(constants.USER_ACTION_INFO_DIALOG, info);
      });
  },

  [constants.USER_ACTION_SEND]: async ({ commit, dispatch, rootState }, data) => {
    const { Session } = rootState;
    const { provider, chainId, account } = Session;
    const { market, amount, address } = data;

    const info = {};
    info.type = 'send';
    info.loading = true;
    info.symbol = market.underlyingSymbol;
    info.wallet = true;
    info.amount = 1;
    commit(constants.USER_ACTION_INFO_DIALOG, info);
    setTransactionTypeMP(EVENT_NAME.STS, market.symbol, amount, Session.wallet);
    transferTokens(account, chainId, address, market.underlyingSymbol, amount)
      .then((tx) => {
        info.hash = tx.hash;
        info.wallet = false;
        /* info.action = 'Send'; */
        commit(constants.USER_SET_PROPERTY, { progressLinear: 80 });
        commit(constants.USER_ACTION_INFO_DIALOG, info);
        commit(constants.USER_SET_PROPERTY, { notifications: info });
        dispatch(constants.USER_DIALOG_INFO_CLOSE, true);
        return provider.waitForTransaction(tx.hash);
      })
      .then((transaction) => {
        info.status = transaction.status;
        dispatch(constants.USER_ADD_GAS_USED, transaction);
        return getFeePrice(state.gasUsed, chainId);
      })
      .then((feePrice) => {
        if (info.status) {
          info.loading = false;
          info.deposit = true;
          info.amount = amount;
          info.gasPrice = feePrice;
          // notify
          commit(constants.USER_SET_PROPERTY, {
            notifications: !state.dialogLoading ? info : null,
          });
          dispatch(constants.MARKET_UPDATE_MARKET_USER_INFO, market);
          dispatch(constants.MARKET_UPDATE_WITHDRAW_INFO);
          dispatch(constants.MARKET_UPDATE_RBTC_BALANCE_IN_WALLET);
          commit(constants.USER_SET_PROPERTY, { actionSend: true });
          commit(constants.USER_SET_PROPERTY, { progressLinear: 100 });
          state.updateMarket = true;
          setTransactionTypeMP(EVENT_NAME.STC, market.symbol, amount, Session.wallet);
          setTimeout(() => {
            state.updateMarket = false;
          }, 500);
        } else {
          dispatch(constants.USER_ACTION_ERROR, info.hash);
          dispatch(constants.USER_ACTION_ERROR, {
            hash: info.hash, currency: market.symbol, amount, event: EVENT_NAME.STE,
          });
        }
        setTimeout(() => {
          info.loading = false;
          commit(constants.USER_ACTION_INFO_DIALOG, info);
        }, 2000);
      })
      .catch((e) => {
        setTransactionTypeMP(EVENT_NAME.STR, market.symbol, amount, Session.wallet);
        console.log(e);
        state.rejectEvent = true;
        info.loading = false;
        commit(constants.USER_ACTION_INFO_DIALOG, info);
      });
  },

  [constants.USER_ACTION_REDEEM]: async ({ commit, dispatch, rootState }, data) => {
    const {
      Session: {
        provider, account, walletAddress, chainId, typeConnection, wallet,
      },
      Market: { instance },
      Comptroller: { comptroller },
    } = rootState;

    const { market, amount } = data;
    const amountToSend = amount === -1 ? market.maxAllowedToWithdraw : amount;
    setTransactionTypeMP(EVENT_NAME.WDS, market.symbol, amountToSend, wallet);

    const info = {};
    info.type = 'deposit';
    info.loading = true;
    info.symbol = market.underlyingSymbol;
    info.wallet = true;
    info.amount = 1;

    commit(constants.USER_ACTION_INFO_DIALOG, info);
    const assetsIn = await comptroller.getAssetsIn(walletAddress);
    const markets = await comptroller.allMarkets();
    // const existMarket = assetsIn.indexOf(market.id) !== -1;
    const existMarket = markets.length === assetsIn.length;
    info.amount = existMarket ? 1 : 2;
    commit(constants.USER_ACTION_INFO_DIALOG, info);

    if (!existMarket) {
      try {
        const allMarkets = await comptroller.allMarkets();
        const tx = await comptroller.enterMarkets(account, allMarkets);
        const transaction = await provider.waitForTransaction(tx.hash);
        dispatch(constants.USER_ADD_GAS_USED, transaction);
        commit(constants.USER_SET_PROPERTY, { progressLinear: 50 });
      } catch {
        state.rejectEvent = true;
        info.loading = false;
        commit(constants.USER_ACTION_INFO_DIALOG, info);
        return;
      }
    }

    instance
      .redeem(account, amount, typeConnection)
      .then((tx) => {
        info.hash = tx.hash;
        info.wallet = false;
        info.action = 'Redeem';
        commit(constants.USER_SET_PROPERTY, { progressLinear: 80 });
        commit(constants.USER_ACTION_INFO_DIALOG, info);
        commit(constants.USER_SET_PROPERTY, { notifications: info });
        dispatch(constants.USER_DIALOG_INFO_CLOSE, true);
        return provider.waitForTransaction(tx.hash);
      })
      .then((transaction) => {
        info.status = transaction.status;
        dispatch(constants.USER_ADD_GAS_USED, transaction);
        return getFeePrice(state.gasUsed, chainId);
      })
      .then((feePrice) => {
        if (info.status) {
          info.loading = false;
          info.deposit = false;
          info.amount = amountToSend;
          info.gasPrice = feePrice;

          // notify
          commit(constants.USER_SET_PROPERTY, {
            notifications: !state.dialogLoading ? info : null,
          });
          dispatch(constants.MARKET_UPDATE_MARKET_USER_INFO, market);
          dispatch(constants.MARKET_UPDATE_WITHDRAW_INFO);
          dispatch(constants.MARKET_UPDATE_RBTC_BALANCE_IN_WALLET);
          state.updateMarket = true;
          setTimeout(() => {
            state.updateMarket = false;
          }, 500);
          setTransactionTypeMP(EVENT_NAME.WDC, market.symbol, amountToSend, wallet);
        } else {
          dispatch(constants.USER_ACTION_ERROR, {
            hash: info.hash, currency: market.symbol, amountToSend, event: EVENT_NAME.WDE,
          });
        }
        setTimeout(() => {
          info.loading = false;
          commit(constants.USER_ACTION_INFO_DIALOG, info);
        }, 2000);
      })
      .catch(() => {
        setTransactionTypeMP(EVENT_NAME.WDR, market.symbol, amountToSend, wallet);
        state.rejectEvent = true;
        info.loading = false;
        commit(constants.USER_ACTION_INFO_DIALOG, info);
      });
  },

  [constants.USER_ACTION_BORROW]: async ({ commit, dispatch, rootState }, data) => {
    const {
      Session: {
        provider, account, walletAddress, chainId, wallet,
      },
      Market: { instance },
      Comptroller: { comptroller, liquidity },
    } = rootState;
    const { market, amount } = data;
    const amountToSend = amount === -1 ? liquidity / market.price : amount;
    setTransactionTypeMP(EVENT_NAME.BWS, market.symbol, amountToSend, wallet);

    const info = {};
    info.type = 'borrow';
    info.loading = true;
    info.wallet = true;
    info.symbol = market.underlyingSymbol;
    info.amount = 1;
    commit(constants.USER_ACTION_INFO_DIALOG, info);

    const assetsIn = await comptroller.getAssetsIn(walletAddress);
    const markets = await comptroller.allMarkets();
    // const existMarket = assetsIn.indexOf(market.id) !== -1;
    const existMarket = markets.length === assetsIn.length;
    info.amount = existMarket ? 1 : 2;
    commit(constants.USER_ACTION_INFO_DIALOG, info);

    if (!existMarket) {
      try {
        const allMarkets = await comptroller.allMarkets();
        const tx = await comptroller.enterMarkets(account, allMarkets);
        const transaction = await provider.waitForTransaction(tx.hash);
        dispatch(constants.USER_ADD_GAS_USED, transaction);
        commit(constants.USER_SET_PROPERTY, { progressLinear: 30 });
      } catch {
        state.rejectEvent = true;
        info.loading = false;
        commit(constants.USER_ACTION_INFO_DIALOG, info);
        return;
      }
    }

    instance
      .borrow(account, amount, walletAddress)
      .then((tx) => {
        info.hash = tx.hash;
        info.wallet = false;
        info.action = 'Borrow';
        commit(constants.USER_SET_PROPERTY, { progressLinear: 80 });
        commit(constants.USER_ACTION_INFO_DIALOG, info);
        commit(constants.USER_SET_PROPERTY, { notifications: info });
        dispatch(constants.USER_DIALOG_INFO_CLOSE, true);
        return provider.waitForTransaction(tx.hash);
      })
      .then((transaction) => {
        info.status = transaction.status;
        dispatch(constants.USER_ADD_GAS_USED, transaction);
        return getFeePrice(state.gasUsed, chainId);
      })
      .then((feePrice) => {
        if (info.status) {
          info.loading = false;
          info.borrow = true;
          info.amount = amountToSend;
          info.gasPrice = feePrice;

          // notify
          commit(constants.USER_SET_PROPERTY, {
            notifications: !state.dialogLoading ? info : null,
          });
          dispatch(constants.MARKET_UPDATE_MARKET_USER_INFO, market);
          dispatch(constants.MARKET_UPDATE_WITHDRAW_INFO);
          dispatch(constants.MARKET_UPDATE_RBTC_BALANCE_IN_WALLET);
          commit(constants.USER_SET_PROPERTY, { actionBorrow: true });
          state.updateMarket = true;
          setTimeout(() => {
            state.updateMarket = false;
          }, 500);
          setTransactionTypeMP(EVENT_NAME.BWC, market.symbol, amountToSend, wallet);
        } else {
          dispatch(constants.USER_ACTION_ERROR, {
            hash: info.hash, currency: market.symbol, amountToSend, event: EVENT_NAME.BWE,
          });
        }
        setTimeout(() => {
          info.loading = false;
          commit(constants.USER_ACTION_INFO_DIALOG, info);
        }, 2000);
      })
      .catch((e) => {
        setTransactionTypeMP(EVENT_NAME.BWR, market.symbol, amountToSend, wallet);
        console.log(e);
        state.rejectEvent = true;
        info.loading = false;
        commit(constants.USER_ACTION_INFO_DIALOG, info);
      });
  },

  [constants.USER_ACTION_REPAY]: async ({ commit, dispatch, rootState }, data) => {
    const {
      Session: {
        provider, account, walletAddress, chainId, typeConnection, wallet,
      },
      Market: { instance },
      Comptroller: { comptroller },
    } = rootState;

    const { market, amount } = data;

    const amountToSend = amount === -1 ? market.borrowBalance : amount;
    setTransactionTypeMP(EVENT_NAME.RPS, market.symbol, amountToSend, wallet);
    const info = {};
    info.type = 'borrow';
    info.loading = true;
    info.wallet = true;
    info.symbol = market.underlyingSymbol;
    info.amount = 1;
    commit(constants.USER_ACTION_INFO_DIALOG, info);
    const totalBorrowBalance = await instance.borrowBalanceCurrent(walletAddress);
    const balanceInWallet = await instance.balanceOfUnderlyingInWallet(account);
    if (amount === -1 && (Number(balanceInWallet) < Number(totalBorrowBalance))) {
      state.totalRepayError = true;
      info.loading = false;
      commit(constants.USER_ACTION_INFO_DIALOG, info);
      return;
    }

    const assetsIn = await comptroller.getAssetsIn(walletAddress);
    const existMarket = assetsIn.indexOf(market.id) !== -1;
    info.amount = existMarket ? info.amount : info.amount + 1;
    commit(constants.USER_ACTION_INFO_DIALOG, info);

    if (!existMarket) {
      try {
        const allMarkets = await comptroller.allMarkets();
        const tx = await comptroller.enterMarkets(account, allMarkets);
        const transaction = await provider.waitForTransaction(tx.hash);
        dispatch(constants.USER_ADD_GAS_USED, transaction);
        commit(constants.USER_SET_PROPERTY, { progressLinear: 50 });
      } catch {
        state.rejectEvent = true;
        info.loading = false;
        commit(constants.USER_ACTION_INFO_DIALOG, info);
        return;
      }
    }
    let amountPay = amount;
    if (Number(amount) === market.borrowBalance) amountPay = -1;

    if (market.id !== addresses[chainId].kSAT && market.id !== addresses[chainId].kRBTC) {
      const enoughAllowance = await instance.allowance(account, amount);
      info.amount = !enoughAllowance ? info.amount + 1 : info.amount;
    }
    commit(constants.USER_ACTION_INFO_DIALOG, info);
    instance
      .repay(account, amountPay, walletAddress, typeConnection)
      .then((tx) => {
        info.hash = tx.hash;
        info.wallet = false;
        info.action = 'RepayBorrow';

        commit(constants.USER_SET_PROPERTY, { progressLinear: 80 });
        commit(constants.USER_ACTION_INFO_DIALOG, info);
        commit(constants.USER_SET_PROPERTY, { notifications: info });
        dispatch(constants.USER_DIALOG_INFO_CLOSE, true);
        return provider.waitForTransaction(tx.hash);
      })
      .then((transaction) => {
        info.status = transaction.status;
        dispatch(constants.USER_ADD_GAS_USED, transaction);
        return getFeePrice(state.gasUsed, chainId);
      })
      .then((feePrice) => {
        if (info.status) {
          info.loading = false;
          info.borrow = false;
          info.amount = amountToSend;
          info.gasPrice = feePrice;

          // notify
          commit(constants.USER_SET_PROPERTY, {
            notifications: !state.dialogLoading ? info : null,
          });
          dispatch(constants.MARKET_UPDATE_MARKET_USER_INFO, market);
          dispatch(constants.MARKET_UPDATE_WITHDRAW_INFO);
          dispatch(constants.MARKET_UPDATE_RBTC_BALANCE_IN_WALLET);
          state.updateMarket = true;
          setTimeout(() => {
            state.updateMarket = false;
          }, 500);
          setTransactionTypeMP(EVENT_NAME.RPC, market.symbol, amountToSend, wallet);
        } else {
          dispatch(constants.USER_ACTION_ERROR, {
            hash: info.hash, currency: market.symbol, amountToSend, event: EVENT_NAME.RPE,
          });
        }
        setTimeout(() => {
          info.loading = false;
          commit(constants.USER_ACTION_INFO_DIALOG, info);
        }, 2000);
      })
      .catch(() => {
        setTransactionTypeMP(EVENT_NAME.RPR, market.symbol, amountToSend, wallet);
        state.rejectEvent = true;
        info.loading = false;
        commit(constants.USER_ACTION_INFO_DIALOG, info);
      });
  },

  [constants.USER_ACTION_ERROR]: async ({ commit, rootState }, data) => {
    const {
      hash, amount, currency, event,
    } = data;
    const {
      Session: { provider, wallet },
    } = rootState;
    try {
      const a = await provider.getTransaction(hash);
      const code = await provider.call(a, a.blockNumber);
      console.log('code: ', code);
    } catch (err) {
      const code = err.error?.data?.message?.split(' ');
      const typeError = code.pop() || '';
      const message = EVENT_ERROR[typeError];

      const error = {
        error: true,
        hash,
        typeError,
        message,
      };
      setTransactionTypeMP(event, currency, amount, wallet, error);
      commit(constants.USER_SET_PROPERTY, { error });
      commit(constants.USER_SET_PROPERTY, {
        notifications: !state.dialogLoading ? error : null,
      });
      state.errorEvent = true;
    }
  },

  [constants.USER_FEEDBACK]: ({ commit }, data) => {
    const info = {
      type: 'feedback',
      loading: true,
    };
    commit(constants.USER_ACTION_INFO_DIALOG, info);
    commit(constants.USER_SET_PROPERTY, { dialogLoading: true });

    Vue.axios
      .post('/sendFeedback', data)
      .then(() => {
        info.loading = false;
        info.success = true;
        commit(constants.USER_ACTION_INFO_DIALOG, info);
      })
      .catch(() => {
        info.loading = false;
        info.success = false;
        commit(constants.USER_ACTION_INFO_DIALOG, info);
      });
  },

  [constants.USER_ACTION_SWAP]: async ({ commit, dispatch, rootState }, data) => {
    commit(constants.USER_SET_PROPERTY, { gasUsed: 0 });
    const { Session } = rootState;
    const { provider } = Session;
    const sovrynSwap = new SovrynSwap(Session.chainId);
    let resp = '';
    const info = {};
    const gasPrice = Number(await provider.getGasPrice());

    const {
      sourceToken,
      destToken,
      amount,
      minReturn,
      amountToReceive,
      conversionPath,
      select,
      selectI,
    } = data;

    try {
      info.sign = true;
      info.wallet = true;
      info.exchange = false;
      commit(constants.USER_ACTION_SWAP, info);

      if (sourceToken === addresses[Session.chainId].wRBTC) {
        resp = await sovrynSwap
          .convertFromRBTC(Session.account, conversionPath, amount, minReturn, gasPrice);
      } else if (destToken === addresses[Session.chainId].wRBTC) {
        resp = await sovrynSwap
          .convertToRBTC(Session.account, conversionPath, amount, minReturn, sourceToken, gasPrice);
      } else {
        resp = await sovrynSwap.convertByPath(
          Session.account,
          Session.walletAddress,
          conversionPath,
          amount,
          minReturn,
          sourceToken,
          gasPrice,
        );
      }

      info.wallet = false;
      info.loading = true;
      info.exchange = false;
      info.symbol = select.underlyingSymbol;
      info.symbolSwap = selectI.underlyingSymbol;

      commit(constants.USER_ACTION_SWAP, info);
      setTransactionSwapMP(EVENT_NAME.SWAPS, info, Session.wallet);

      provider
        .waitForTransaction(resp.hash)
        .then((transaction) => {
          info.status = transaction.status;
          dispatch(constants.USER_ADD_GAS_USED, transaction);
          return getFeePrice(state.gasUsed, Session.chainId);
        })
        .then((feePrice) => {
          if (info.status) {
            info.loading = false;
            info.action = 'Exchange';
            info.exchange = true;
            info.amount = amount;
            info.swap = amountToReceive;
            info.hash = resp.hash;
            info.gasPrice = feePrice;

            commit(constants.USER_ACTION_SWAP, info);
            commit(constants.USER_SET_PROPERTY, {
              notifications: !state.dialogLoading ? info : null,
            });
            dispatch(constants.MARKET_UPDATE_MARKET_USER_INFO, select);
            state.updateSwap = true;
            setTransactionSwapMP(EVENT_NAME.SWAPC, info, Session.wallet);
            setTimeout(() => {
              state.updateSwap = false;
            }, 1000);
          } else {
            state.errorEvent = true;
            const error = {
              error: true,
              hash: resp.hash,
              action: 'Swap',
            };
            setTransactionSwapMP(EVENT_NAME.SWAPE, error, Session.wallet);
            if (!state.swapDialog) {
              commit(constants.USER_SET_PROPERTY, { notifications: error });
            } else {
              state.errorEvent = true;
            }
          }
        });
    } catch (error) {
      const errorData = {
        message: error.message,
        error,
      };
      setTransactionSwapMP(EVENT_NAME.SWAPR, errorData, Session.wallet);
      console.log('error: ', error);
      state.rejectEvent = true;
    }
  },

  [constants.USER_ACTION_EVENT]: ({ commit }, data) => {
    commit(constants.USER_ACTION_EVENT, { ...data });
  },

  [constants.USER_ADD_GAS_USED]: ({ commit }, tx) => {
    let gasUsedTx = state.gasUsed;
    gasUsedTx += Number(tx.gasUsed);
    commit(constants.USER_SET_PROPERTY, { gasUsed: gasUsedTx });
    // progress linear
    const {
      infoLoading: { amount },
    } = state;
    if (state.progressLinear >= 80) {
      commit(constants.USER_SET_PROPERTY, { progressLinear: 100 });
      return;
    }
    if (amount === 1) commit(constants.USER_SET_PROPERTY, { progressLinear: 40 });
    if (amount === 2) commit(constants.USER_SET_PROPERTY, { progressLinear: 30 });
    if (amount === 3) commit(constants.USER_SET_PROPERTY, { progressLinear: 20 });
  },

  [constants.USER_DIALOG_INFO_CLOSE]: ({ commit }, closable) => {
    // closable muestra x para cerrar el popup
    commit(constants.USER_ACTION_INFO_DIALOG, { closable });
  },
};

const mutations = {
  // eslint-disable-next-line no-shadow
  [constants.USER_SET_PROPERTY]: (state, data) => {
    const [[property, value]] = Object.entries(data);
    state[property] = value;
  },

  // eslint-disable-next-line no-shadow
  [constants.USER_ACTION_EVENT]: (state, payload) => {
    const { rejectEvent, errorEvent } = payload;
    state.rejectEvent = rejectEvent;
    state.errorEvent = errorEvent;
  },

  // eslint-disable-next-line no-shadow
  [constants.USER_ACTION_INFO_DIALOG]: (state, payload) => {
    state.infoLoading = { ...state.infoLoading, ...payload };
  },

  // eslint-disable-next-line no-shadow
  [constants.USER_ACTION_SWAP]: (state, payload) => {
    state.swapInfo = { ...state.swapInfo, ...payload };
  },

  // eslint-disable-next-line no-shadow
  [constants.USER_ACTION_SWAP_DIALOG]: (state, payload) => {
    state.swapDialog = payload;
  },
};

export default {
  state,
  actions,
  mutations,
};
