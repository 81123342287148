<template>
  <div class="tpks-navbar">
    <v-row v-if="failRed" class="ma-0 pa-0 mb-1">
      <v-col cols="12" class="pa-0">
        <div class="
          alert-wallet alert-warning d-flex justify-center align-center text-center rounded
        ">
          <span>
            {{ textBar }}
          </span>
          <span v-if="wallet === 'WALLET_METAMASK'">
            <v-btn text @click="changeRed">RSK Mainnet</v-btn>
          </span>
        </div>
      </v-col>
    </v-row>
    <v-container class="tpks-navbar__container mt-3">
      <v-row class="tpks-navbar__default-content">
        <v-app-bar-nav-icon class="icon-drawer" @click="setDrawer(true)"></v-app-bar-nav-icon>
        <v-spacer />
        <template v-if="isWalletConnected">
          <v-row
            class="tpks-navbar__wallet-access"
            :class="[isSupportedNetwork ? 'secondary-color' : 'warning-color']"
          >
            <v-col cols="auto" class="tpks-wallet-info">
              <v-row class="ma-0">
                <v-tooltip bottom content-class="secondary-color box-shadow-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <div class="b2-secondary text-detail d-flex align-self-end"
                    v-bind="attrs" v-on="on">
                      {{ accountCutOff }}
                    </div>
                  </template>
                  <span class="p5-feedback text-info" >{{ walletAddress }}</span>
                </v-tooltip>
                <copy-clipboard
                :valueToCopy="walletAddress"
                :confirmationText="$t('dialog.fastBtc.fastBtc2.clipboard')"
                class="copy mb-n1 ml-2"
                />
              </v-row>
              <v-row class="ma-0">
                <div class="b2-secondary text-primary">
                  {{ isSupportedNetwork ? network : $t('menu.navbar.network') }}
                </div>
              </v-row>
            </v-col>
            <v-col cols="auto" class="tpks-disconnect-wallet-btn">
              <template v-if="isSupportedNetwork">
                <v-btn icon @click="disconnectWallet" class="tpks-disconnect-btn-icon">
                  <v-icon color="#042F24">mdi-logout</v-icon>
                </v-btn>
                <v-btn text class="btn btn-info tpks-disconnect-btn-text" @click="disconnectWallet">
                  {{ buttonLabel }}
                </v-btn>
              </template>
              <template v-else>
                <v-tooltip bottom color="#CFE7DA">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="disconnectWallet"
                      color="#196635"
                      rounded
                      outlined
                      width="200"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span class="b2-secondary text-detail">
                        {{ $t('menu.navbar.disconnect') }}
                      </span>
                    </v-btn>
                  </template>
                  <span class="p1-descriptions text-info">
                    {{ $t('menu.navbar.warning1') }} <br />
                    {{ $t('menu.navbar.warning2') }}
                  </span>
                </v-tooltip>
              </template>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <v-btn text @click="showDialogConnectWallet" class="btn btn-primary">
            <span class="b1-main white--text">
              {{ buttonLabel }}
            </span>
          </v-btn>
        </template>
      </v-row>
    </v-container>
    <div v-for="(market, index) in pausedBorrowMarkets" :key="index">
      <div class="banner" :key="index">
        <span class="bold">Los préstamos en el mercado de {{market.slice(1)}}
          están pausados por seguridad</span>
      </div>
    </div>
    <div v-for="(market, index) in pausedMintMarkets" :key="index">
      <div class="banner">
        <span class="bold">Los depósitos en el mercado de {{market.slice(1)}}
          están pausados por seguridad</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import Avatar from '@/assets/avatar.svg';
import Error from '@/assets/icons/error.svg';
import * as constants from '@/store/constants';
import { addresses } from '@/middleware/contracts/constants';
import CopyClipboard from '@/components/general/CopyClipboard.vue';

export default {
  components: {
    CopyClipboard,
  },
  data() {
    return {
      pausedBorrowMarkets: [],
      pausedMintMarkets: [],

    };
  },
  watch: {
    walletAddress() {
      if (!this.walletAddress) this.showDialogWallet(false);
    },
    markets() {
      if (this.markets.length) {
        const activeTokens = ['kDOC', 'kRBTC'];
        const activeAddresses = [];
        activeTokens.forEach((contract) => {
          activeAddresses.push(addresses[this.chainId][contract]);
        });
        // eslint-disable-next-line array-callback-return
        this.markets.map((market) => {
          if (activeAddresses.includes(market.id)) {
            if (market.borrow_paused_status === true
            && !this.pausedBorrowMarkets.includes(market.symbol) && market.symbol !== 'CRBTC') {
              this.pausedBorrowMarkets.push(market.symbol);
            }
            if (market.mint_paused_status === true
            && !this.pausedMintMarkets.includes(market.symbol)) {
              this.pausedMintMarkets.push(market.symbol);
            }
          }
        });
      }
    },
  },
  computed: {
    ...mapState({
      walletAddress: (state) => state.Session.walletAddress,
      routePath: (state) => state.route.path,
      chainId: (state) => state.Session.chainId,
      failRed: (state) => state.Session.failRed,
      wallet: (state) => state.Session.wallet,
      isMobile: (state) => state.Session.isMobile,
      markets: (state) => state.Market.markets,
    }),
    ...mapGetters({
      isConnected: constants.SESSION_IS_CONNECTED,
      isSupportedNetwork: constants.SESSION_IS_SUPPORTED_NETWORK,
      network: constants.SESSION_GET_NETWORK_NAME,
    }),
    accountCutOff() {
      return `${this.walletAddress.substr(0, 6)}..${this.walletAddress.substr(-2)}`;
    },
    avatarSize() {
      return this.isSupportedNetwork ? 35 : 20;
    },
    avatarImage() {
      return this.isSupportedNetwork ? Avatar : Error;
    },
    buttonLabel() {
      if (this.isConnected) {
        return this.$t('menu.navbar.disconnect');
      }
      return this.$t('menu.navbar.connect');
    },
    isTutorial() {
      return this.routePath.substr(1, 10) === 'tutorials/';
    },
    isWalletConnected() {
      return !!this.walletAddress;
    },
    networkColor() {
      return this.isSupportedNetwork ? 'transparent' : '#FCDE73';
    },
    textBar() {
      if (this.wallet) {
        if (this.failRed && this.wallet === 'WALLET_METAMASK') return this.$t('rules.red.rule1');
        if (this.failRed && this.wallet !== 'WALLET_METAMASK') return this.$t('rules.red.rule2');
      }
      return this.$t('');
    },
    /*  showCopy() {
      const isBeexoBrowser = this.wallet === 'Beexo' && this.isMobile;
      const isMTMBrowser = this.wallet === 'WALLET_METAMASK' && this.isMobile;
      return this.walletAddress && !isBeexoBrowser && !isMTMBrowser;
    }, */
  },
  methods: {
    ...mapActions({
      setDrawer: constants.SESSION_DRAWER,
      disconnectWallet: constants.SESSION_DISCONNECT_WALLET,
      showDialogWallet: constants.SESSION_SHOW_DIALOG_CONNECT,
      showDialogQrAddress: constants.SESSION_SHOW_DIALOG_QR_ADDRESS,
      connectToWeb3: constants.SESSION_CONNECT_WEB3,
    }),
    showDialogConnectWallet() {
      this.showDialogWallet(true);
    },
    changeRed() {
      this.connectToWeb3({ wallet: this.wallet });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables";
@import "@/styles/mixins";

.tpks-navbar {
  align-items: center;
}
.banner {
  background: #8C049838;
  padding: 10px;
  border-radius: 5px;
  margin: 20px auto 20px auto;
  max-width: 655px;
  line-height: 1;
  font-size: 14px;
  text-align: justify;
}
.banner .bold {
  font-weight: 900;
  color: black;
}

.tpks-navbar__container {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 1rem;

}

.tpks-navbar__default-content {
  margin: 0;
  flex-wrap: nowrap;
}

.tpks-navbar__wallet-access {
  flex-grow: 0;
  flex-wrap: nowrap;
  align-items: center;
  gap: 1rem;
  margin: 0;

  .tpks-account-image {
    padding: 0;
  }
  .tpks-wallet-info {
    padding: 0;
  }
.copy{
  &:hover{
    background-color: $secondary-color!important;

  }
}
  .tpks-disconnect-wallet-btn {
    padding: 0;
  }

  .tpks-disconnect-btn-text {
    display: block;
    @include phone() {
      display: none;
    }
  }
  .tpks-disconnect-btn-icon {
    display: none;
    @include phone() {
      display: block;
    }
  }

}
</style>
