import Decimal from 'decimal.js';

function getPrecision(number, precision = 18, forceNumOfDecimals = false) {
  if (number === undefined) {
    return undefined;
  }
  let aux = number;
  if (typeof number !== 'object') {
    aux = new Decimal(number);
  }
  if (forceNumOfDecimals) {
    return aux.toFixed(precision);
  }
  const result = aux.toFixed(precision > 0 ? precision + 1 : 1);
  return `${result.substring(0, result.length - 1)}\0`.replace(/[.]*[0]*\0/g, '');
}

function processNumber(number) {
  if (typeof number !== 'number' && typeof number !== 'string') {
    return number.toString();
  }
  return number;
}

function operate(a, b, operation, precision = 18, forceNumOfDecimals = false) {
  if (a === undefined || b === undefined) {
    return undefined;
  }
  const regA = processNumber(a);
  const regB = processNumber(b);
  const result = Decimal[operation](regA, regB);
  return getPrecision(result, precision, forceNumOfDecimals);
}

function add(a, b, precision = 18, forceNumOfDecimals = false) {
  return operate(a, b, 'add', precision, forceNumOfDecimals);
}

function sub(a, b, precision = 18, forceNumOfDecimals = false) {
  return operate(a, b, 'sub', precision, forceNumOfDecimals);
}

function mul(a, b, precision = 18, forceNumOfDecimals = false) {
  return operate(a, b, 'mul', precision, forceNumOfDecimals);
}

function div(a, b, precision = 18, forceNumOfDecimals = false) {
  return operate(a, b, 'div', precision, forceNumOfDecimals);
}

function pow(a, b, precision = 18, forceNumOfDecimals = false) {
  return operate(a, b, 'pow', precision, forceNumOfDecimals);
}

function compare(a, b, operation) {
  if (a === undefined || b === undefined) {
    return undefined;
  }
  const regA = new Decimal(processNumber(a));
  const regB = new Decimal(processNumber(b));
  return regA[operation](regB);
}

function gt(a, b) {
  return compare(a, b, 'gt');
}

function gte(a, b) {
  return compare(a, b, 'gte');
}

function lt(a, b) {
  return compare(a, b, 'lt');
}

function lte(a, b) {
  return compare(a, b, 'lte');
}

export default {
  getPrecision,
  add,
  sub,
  mul,
  div,
  pow,
  gt,
  gte,
  lt,
  lte,
};
