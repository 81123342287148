import { graphqlClient } from '@/utils/graphql';
import usersQuery from '@/graphql/query/User.gql';

export async function getUserId(walletAddress) {
  const userSubscribe = await graphqlClient.subscribe({
    query: usersQuery.findFirstUsers,
    variables: {
      where: {
        address_lowercase: {
          contains: walletAddress.toLowerCase(),
          mode: 'insensitive',
        },
      },
    },
  });

  return new Promise((resolve, reject) => {
    userSubscribe.subscribe({
      next(response) {
        const { data: { findFirstUsers } } = response;
        resolve({ findFirstUsers });
      },
      error(error) {
        reject(error);
      },
    });
  });
}

export async function getUserBalance(userId) {
  const balanceUser = await graphqlClient.subscribe({
    query: usersQuery.getUserBalance,
    variables: {
      where: {
        user_id: {
          equals: userId,
        },
      },
    },
  });

  return new Promise((resolve, reject) => {
    balanceUser.subscribe({
      next(response) {
        const { data } = response;
        const marketsBalance = data.findManyUser_balances;
        resolve({ marketsBalance });
      },
      error(error) {
        reject(error);
      },
    });
  });
}

export async function getUserActivity(userId, take, skip) {
  const activityUser = await graphqlClient.subscribe({
    query: usersQuery.findManyUser_activities,
    variables: {
      where: {
        user_id: {
          equals: userId,
        },
      },
      take: take || 10,
      skip: skip || 0,
      orderBy: [
        {
          events: {
            event_timestamp: 'desc',
          },
        },
      ],
    },
  });

  return new Promise((resolve, reject) => {
    activityUser.subscribe({
      next(response) {
        const { data } = response;
        const activity = data.findManyUser_activities;
        resolve({ activity });
      },
      error(error) {
        reject(error);
      },
    });
  });
}

export async function setTermsAndConditions(params) {
  const url = process.env.VUE_APP_SERVER_API;
  const data = await fetch(`${url}/users/accept-terms-and-conditions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });
  const response = await data.json();
  return response.tac_response;
}
