<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="custom-icon"
  >
    <path
      d="M10 0C4.48421 0 0 4.48421 0 10C0 15.5158 4.48421 20 10 20C15.5158 20 20 15.5158 20 10C20 4.48421 15.5158 0 10 0ZM3.91579 7.62105C3.91579 7.41053 4.10526 7.22105 4.31579 7.22105H10.8211V4.84211C10.8211 4.50526 11.2211 4.31579 11.4737 4.54737L15.9158 8.42105C16.1895 8.67368 16.0211 9.11579 15.6632 9.11579H4.31579C4.10526 9.11579 3.91579 8.92632 3.91579 8.71579V7.62105ZM16.0842 12.3789C16.0842 12.5895 15.8947 12.7789 15.6842 12.7789H9.17895V15.1579C9.17895 15.4947 8.77895 15.6842 8.52632 15.4526L4.06316 11.6C3.78947 11.3474 3.95789 10.9053 4.31579 10.9053H15.6842C15.8947 10.9053 16.0842 11.0737 16.0842 11.3053V12.3789Z"
      fill="#042F24"
    />
  </svg>
</template>

<script>
/* eslint max-len: 0 */

export default {
  name: 'Swap',
};
</script>
