import store from '@/store';

export const EVENT_NAME = {
  // wallets
  RLOGIN: 'RLOGIN',
  WCS: 'wallet connection Attempted',
  WCC: 'wallet connection successful',
  WCE: 'wallet connection error',
  // deposits
  DPS: 'deposit started',
  DPC: 'deposit completed',
  DPR: 'deposit rejected by user',
  DPE: 'deposit error',
  // withdraw
  WDS: 'withdraw started',
  WDC: 'withdraw completed',
  WDR: 'withdraw rejected by user',
  WDE: 'withdraw error',
  // borrow
  BWS: 'borrow started',
  BWC: 'borrow completed',
  BWR: 'borrow rejected by user',
  BWE: 'borrow error',
  // repay
  RPS: 'repay started',
  RPC: 'repay completed',
  RPR: 'repay rejected by user',
  RPE: 'repay error',
  // send token
  STS: 'send token started',
  STC: 'send token completed',
  STR: 'send rejected by user',
  STE: 'send token error',
  // swap events
  SWAPS: 'swap started',
  SWAPC: 'swap completed',
  SWAPR: 'swap rejected by user',
  SWAPE: 'swap error',
  // fast btc
  FBTCI: 'fastbtc init',
  // terms and conditions
  TACI: 'TAC init',
  TACC: 'TAC completed',
  TACR: 'TAC rejected by user',
  TACE: 'TAC Error',
};

export const EVENT_ERROR = {
  R8: 'Has excedido el límite de depósitos de 0.25 rBitcoin en el mercado Micro',
  R9: 'No puedes depositar porque el mercado Micro está lleno.',
  T17: 'No puedes pagar más de tu deuda.',
  T12: 'No puedes depositar porque tienes una deuda en este mercado.',
  balance: 'No puedes depositar mas de lo que hay en tu balance',
  overflow: 'No puedes depositar mas de lo que hay en tu balance',
  USER_ACTION_MINT: EVENT_NAME.DPE,
  USER_ACTION_REDEEM: EVENT_NAME.WDE,
  USER_ACTION_BORROW: EVENT_NAME.BWE,
  USER_ACTION_REPAY: EVENT_NAME.RPE,
  7: 'BORROW_ACCUMULATED_BALANCE_CALCULATION_FAILED',
  8: 'BORROW_ACCRUE_INTEREST_FAILED',
  9: 'BORROW_CASH_NOT_AVAILABLE',
  11: 'BORROW_NEW_TOTAL_BALANCE_CALCULATION_FAILED',
  12: 'BORROW_NEW_ACCOUNT_BORROW_BALANCE_CALCULATION_FAILED',
  14: 'BORROW_COMPTROLLER_REJECTION',
  30: 'MINT_ACCRUE_INTEREST_FAILED',
  31: 'MINT_COMPTROLLER_REJECTION',
  32: 'MINT_EXCHANGE_CALCULATION_FAILED',
  35: 'MINT_NEW_ACCOUNT_BALANCE_CALCULATION_FAILED',
  36: 'MINT_NEW_TOTAL_SUPPLY_CALCULATION_FAILED',
  40: 'REDEEM_COMPTROLLER_REJECTION',
  41: 'REDEEM_EXCHANGE_TOKENS_CALCULATION_FAILED',
  42: 'REDEEM_EXCHANGE_AMOUNT_CALCULATION_FAILED',
  45: 'REDEEM_NEW_ACCOUNT_BALANCE_CALCULATION_FAILED',
  46: 'REDEEM_NEW_TOTAL_SUPPLY_CALCULATION_FAILED',
  55: 'REPAY_BORROW_ACCUMULATED_BALANCE_CALCULATION_FAILED',
  56: 'REPAY_BORROW_COMPTROLLER_REJECTION',
  58: 'REPAY_BORROW_NEW_ACCOUNT_BORROW_BALANCE_CALCULATION_FAILED',
  59: 'REPAY_BORROW_NEW_TOTAL_BALANCE_CALCULATION_FAILED',
};

/* eslint-disable */
const getInfoNavigation = () => {
  const { state } = store;
  let browser = 'Desconocido';
  let browser_version = 'Desconocida';

  // ===== Browser =====
  // Opera
  if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1) {
    browser = 'Opera';
    browser_version = /(?:OPR|Version)\/([\d.]+)/.exec(navigator.userAgent)[1];
  }
  // Chrome
  else if (navigator.userAgent.indexOf('Chrome') !== -1) {
    browser = 'Chrome';
    browser_version = /(?:Chrome|CriOS)\/([\d.]+)/.exec(navigator.userAgent)[1];
  }
  // Safari
  else if (navigator.userAgent.indexOf('Safari') !== -1) {
    if (navigator) {
      browser = 'Safari';
      browser_version = /Version\/([\d.]+)/.exec(navigator);
    } else {
      browser = 'Safari';
      browser_version = /Version\/([\d.]+)/.exec(navigator.userAgent)[1];
    }
  }
  // Firefox
  else if (navigator.userAgent.indexOf('Firefox') !== -1) {
    browser = 'Firefox';
    browser_version = /Firefox\/([\d.]+)/.exec(navigator.userAgent)[1];
  }
  // Internet Explorer
  else if ((navigator.userAgent.indexOf('MSIE') !== -1) || (!!document.documentMode === true)) {
    browser = 'Internet Explorer';
    browser_version = /(?:MSIE |Trident\/.*; rv:)([\d.]+)/.exec(navigator.userAgent)[1];
  }
  // Edge
  else if (navigator.userAgent.indexOf('Edge') !== -1) {
    browser = 'Edge';
    browser_version = /Edge\/([\d.]+)/.exec(navigator.userAgent)[1];
  }

  // ===== OS =====
  let osname = '';
  if ('userAgentData' in navigator && 'platform' in navigator.userAgentData) {
    osname = navigator.userAgentData.platform;
  }
  else if (/iPhone/.test(navigator.userAgent)) {
    osname = 'iOS';
  } 
  else if (/Android/.test(navigator.userAgent)) {
    osname = 'Android';
  }
  let os_version = '';
  const userAgent = navigator.userAgent;
  if (/android/i.test(userAgent)) {
    os_version = 'Android ' + parseFloat(userAgent.slice(userAgent.indexOf('Android')+8));
  } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    os_version = 'iOS ' + parseFloat(('' + (/CPU.*OS ([0-9_]{1,5})|(like Mac OS X)/gi.exec(userAgent) || [, ''])[1].replace('_', '.').replace('_', '')));
  }

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  if (navigator?.brave) browser = 'Brave';

  const data = {
    browser,
    browser_version,
    os: osname,
    isMobile,
    wallet_name: '',
    wallet_address: state.Session.walletAddress
  };
  if (data.isMobile) data.os_version = os_version; 
  return data;
};

export const setEventMp = async (event, data) => {
  const params = {
    event_name: event,
    data,
  };
  const url = process.env.VUE_APP_SERVER_API;
  await fetch(`${url}/utils/mixpanel-track`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });
};

export const buyCriptoMP = () => {
  setEventMp('buy cripto', {});
};

export const setWalletConnectionEventMP = (event, wallet, data = null) => {
  const dataEvent = getInfoNavigation();
  dataEvent.wallet_name = wallet;
  switch (event) {
    case EVENT_NAME.WCS:
    case EVENT_NAME.WCC:
      setEventMp(event, dataEvent);
      break;
    case EVENT_NAME.WCE:
      dataEvent.error_message = data;
      setEventMp(event, dataEvent);
      break;
    case EVENT_NAME.RLOGIN: {
      const trezor = data?.isTrezor;
      const ledger = data?.isLedger;

      if (trezor) {
        dataEvent.wallet_name = 'Trezor';
        dataEvent.type = wallet;
        setEventMp(EVENT_NAME.WCC, dataEvent);
        return;
      }
      if (ledger) {
        dataEvent.wallet_name = 'Ledger';
        dataEvent.type = wallet;
        setEventMp(EVENT_NAME.WCC, dataEvent);
        return;
      }
      // eslint-disable-next-line
      dataEvent.wallet_name = data?.wc?._peerMeta?.name;
      dataEvent.type = wallet;

      setEventMp(EVENT_NAME.WCC, dataEvent);
      break;
    }
    default:
  }
};

export const setTransactionTypeMP = (event, currency, amount, wallet, data = null) => {
  const dataEvent = getInfoNavigation();
  dataEvent.currency = currency;
  dataEvent.amount = amount;
  dataEvent.wallet_name = wallet;
  if (data?.error) {
    const { hash, typeError, message } = data;
    dataEvent = {
      ...dataEvent,
      error: true,
      message,
      typeError: data.info || typeError,
      hash,
    };
    setEventMp(event, dataEvent);
  } else {
    setEventMp(event, dataEvent);
  }
};

export const setTransactionSwapMP = (event, data, wallet) => {
  const dataEvent = getInfoNavigation();
  dataEvent.data = data;
  dataEvent.wallet_name = wallet;
  setEventMp(event, dataEvent);
};

export const rbtcToBtcMP = (event, amount, wallet) => {
  const dataEvent = getInfoNavigation();
  dataEvent.amount = amount;
  dataEvent.wallet_name = wallet;
  setEventMp(event, dataEvent);
};

export const setTermsAndConditionsMP = (event, wallet, error = null) => {
  let dataEvent = getInfoNavigation();
  dataEvent.wallet_name = wallet;
  if (error) dataEvent = { ...dataEvent, error };
  setEventMp(event, dataEvent);
};
