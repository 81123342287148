<template>
  <div>
    <v-dialog v-model="dialog" persistent content-class="d-warning"
      overlay-opacity="0.6" overlay-color="#000">
      <v-card
        class="dialog-warning ma-0 secondary-color d-flex align-center justify-center px-6">
        <v-icon class="btn-close-dialog" @click="closeDialog(true)">mdi-close</v-icon>
        <div class="content-warning p1-descriptions mt-8 mb-11">
          <img src="@/assets/icons/warnning.svg" class="mt-5"/>
          <div class="h3-sections-heading text-info mt-5">
            {{$t('dialog.wallet-warning.title')}}
          </div>
          <div class="mt-5 text-left">
            {{$t('dialog.wallet-warning.description1')}}
          </div>
          <div class="mt-5 text-left">
            {{$t('dialog.wallet-warning.description2')}}
          </div>
          <div class="box-alert mt-5 d-flex align-center">
            {{$t('dialog.wallet-warning.description5')}}
          </div>
          <v-btn text block class="btn mt-10"
            :disabled="!activeButton"
            @click="closeDialog(false)"
            :class="activeButton ? 'primary-color' : 'btn-disabled'"
          >
            <span class="white--text">
              {{$t('dialog.terms.btn')}}
            </span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

export default {
  name: 'Terms&Conditions',
  data() {
    return {
      dialog: true,
      risk: true,
    };
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    showModal() {
      this.dialog = this.showModal;
    },
  },
  computed: {
    activeButton() {
      return this.risk === true;
    },
  },
  methods: {
    closeDialog(value) {
      if (value) {
        this.$emit('closed', null);
      } else if (this.activeButton) {
        this.$emit('closed', this.activeButton);
      } else {
        this.$emit('closed', null);
      }
    },
  },
};
</script>
