<template>
  <div>
    <v-dialog v-model="dialog" persistent content-class="d-loading"
      overlay-opacity="0.6" overlay-color="#000"
      :class="progressLinear>=80?'d-loading-complete':'d-loading-incomplete'" >
      <template v-if="!cancelEvent && !errorEvent && !totalRepayError">

          <v-card v-if="data.type === 'deposit' || data.type === 'send'"
          class="dialog-loading ma-0 secondary-color d-flex align-center relative"
          >
          <v-icon v-if="isClosable" class="btn-close-dialog"
            @click="closeDialog">mdi-close</v-icon>
          <div v-if="data.loading" class="row ma-0 d-flex flex-column align-center">
            <template>
              <div class="progress-bar-box">
                <v-progress-linear
                  :buffer-value="progressLinear"
                  color="#042F24"
                  :value="progressLinear"
                  stream
                  rounded
                ></v-progress-linear>
              </div>
              <div v-if="wallet !== 'WEB3AUTH'" class="text-value text-description mt-7">
                {{progressLinear === 30 && data.amount === 2
                    ? $t('dialog.loading.description12')
                    :progressLinear  >= 80
                      ? $t('dialog.loading.description2')
                      : $t('dialog.loading.description1')
                }}
              </div>
             <div v-else  class="text-value text-description mt-7">
              {{$t('dialog.loading.description2')}}
             </div>

              <div class="divider mt-6"></div>
              <div v-if="wallet !== 'WEB3AUTH'" class="p1-descriptions mt-3 mb-3">
                {{ textConfirmation }}
              </div>
              <div v-if="wallet !== 'WEB3AUTH'" class="content-sign">
                <div  class="text-sign text-detail">
                  {{ showProgressTx }}
                </div>
                <img src="@/assets/dialog/sign-dialog.svg" />
              </div>
              <div class="text-value text-gas mt-2">
                {{$t('dialog.loading.descriptionGas[0]')}} <br>
                <span class="text-value text-gas">
                  {{$t('dialog.loading.descriptionGas[1]')}}
                  <span class="text-bold">{{$t('dialog.loading.descriptionGas[2]')}}</span>
                </span>
              </div>
            </template>
          </div>
          <!-- finish tx deposit || withdraw -->
          <div v-else class="row ma-0 d-flex flex-column align-center pa-5">
            <v-img width="80" height="80" src="@/assets/dialog/success.svg" alt=""/>
            <div class="text-primary s1-pop-ups text-deposit">
              <span v-if="data.type!=='send'">
                {{ data.deposit
                  ? $t('dialog.loading.description3')
                  : $t('dialog.loading.description4')
                }} <br />
              </span>
              <span v-else>{{$t('dialog.loading.description13')}} <br />
              </span>
              <span class="font-weight-bold">
              {{data.amount | formatDecimals(data.symbol) }}
            </span>
              <span class="font-weight-bold">{{data.symbol}}</span>
            </div>
            <div class="font-weight-bold text-explorer ">
              <div class="p1-descriptions text-gasprice">
                {{$t('notify.description9')}} <br>
                <span class="font-weight-bold">{{data.gasPrice | formatPrice}} USD</span>
              </div>
              <div class="p1-descriptions mt-5 d-flex align-center justify-center">
                {{$t('notify.description5')}}
                <a target="_blank" :href="`${explorer}/${data.hash}`">
                  <img class="link ml-2 mt-1" src="@/assets/icons/link.svg" />
                </a>
              </div>
            </div>
            <finance-4-everyone class="mt-3" :inLoadingDialog="true"
             @expand="expandedFinanceCard=!expandedFinanceCard"/>
             <donation-addresses v-if="expandedFinanceCard"/>

            <v-btn :id="idBtn (marketId, 'Success', data.deposit?'Deposit':'Withdraw', chainId)"
             @click="closeDialog" class="btn btn-primary"
             :class="expandedFinanceCard?'mt-sm-3':'mt-sm-12'" >
              <span class="b1-main white--text">
              {{$t('dialog.loading.btn')}}
              </span>
            </v-btn>
          </div>
        </v-card>
        <v-card v-else-if="data.type === 'feedback'"
          class="dialog-loading ma-0 secondary-color d-flex align-center">
          <div v-if="data.loading" class="row ma-0 d-flex flex-column align-center">
            <v-progress-circular class="progress-loading"
              indeterminate color="#41A255" :width="6" :size="80"/>
            <div v-if="data.wallet" class="text-primary s1-pop-ups text-description mt-9">
              Enviando comentarios
            </div>
          </div>
          <div v-else class="row ma-0 d-flex flex-column align-center px-16 pt-9">
            <v-icon class="btn-close-dialog"
              v-if="data.closable" @click="closeDialog">mdi-close</v-icon>
            <v-img width="100" height="100" src="@/assets/dialog/success.svg" alt=""/>
            <v-container class="feedback mt-10 px-7">
              <div class="feedback-title text-center mb-3">
                ¡Hemos recibido tu comentario!
              </div>
              <div class="feedback-paragraph mb-8 text-center">
                Pronto recibirás un mensaje en tu correo con la respuesta a tu comentario.
              </div>
              <v-btn color="primary" block rounded @click="closeDialog" class="elevation-0">
                Continuar
              </v-btn>
            </v-container>
          </div>
        </v-card>
        <v-card v-else
          class="dialog-loading ma-0 secondary-color d-flex align-center"
          :class="progressLinear>=80?'dialog-loading-complete':'dialog-loading-incomplete'">
          <v-icon class="btn-close-dialog"
            v-if="data.closable" @click="closeDialog">mdi-close</v-icon>
          <div v-if="data.loading" class="row ma-0 d-flex flex-column align-center pa-5">
            <template>
              <div class="progress-bar-box">
                <v-progress-linear
                  :buffer-value="progressLinear"
                  color="#042F24"
                  :value="progressLinear"
                  stream
                  rounded
                ></v-progress-linear>
              </div>
              <div class="text-value text-description mt-7">
                {{
                 progressLinear === 30 && data.amount === 2
                    ? $t('dialog.loading.description12')
                    :progressLinear  >= 80
                      ? $t('dialog.loading.description2')
                      : $t('dialog.loading.description1')
                }}
              </div>
              <div class="divider mt-6"></div>
              <div v-if="wallet !== 'WEB3AUTH'" class="p1-descriptions mt-3 mb-3">
                {{ textConfirmation }}
              </div>
              <div v-if="wallet !== 'WEB3AUTH'" class="content-sign">
                <div class="text-sign text-detail">
                  {{
                    progressLinear >= 80 && data.amount === 2
                    ? `2 / ${data.amount}`
                    : `1 / ${data.amount}`
                  }}
                </div>
                <img src="@/assets/dialog/sign-dialog.svg" />
              </div>
              <div class="text-value text-gas">
                {{$t('dialog.loading.descriptionGas[0]')}} <br>
                <span class="text-bold">{{$t('dialog.loading.descriptionGas[1]')}}
                  <span class="text-bold">{{$t('dialog.loading.descriptionGas[2]')}}</span>
                </span>
              </div>
            </template>
          </div>
          <!-- finish tx borrow || repay -->
          <div v-else class="row ma-0 d-flex flex-column align-center pa-5">
            <v-img width="100" height="100" src="@/assets/dialog/success.svg" alt=""/>
            <div class="text-primary s1-pop-ups text-deposit">
              {{ data.borrow
                ? $t('dialog.loading.description5')
                : $t('dialog.loading.description6')
              }} <br />
              {{data.amount | formatDecimals(data.symbol) }}
              <span class="">{{data.symbol}}</span>
            </div>
            <finance-4-everyone class="mt-3" :inLoadingDialog="true"
             @expand="expandedFinanceCard=!expandedFinanceCard"/>
             <donation-addresses v-if="expandedFinanceCard"/>
            <div class="text-explorer">
              <div class="p1-descriptions text-gasprice">
                {{$t('notify.description9')}} <br>
                <span class="text-bold">{{data.gasPrice | formatPrice}} USD</span>
              </div>
              <div class="p1-descriptions mt-5 d-flex align-center justify-center">
                {{$t('notify.description5')}}
                <a target="_blank" :href="`${explorer}/${data.hash}`">
                  <img class="link ml-2 mt-1" src="@/assets/icons/link.svg" />
                </a>
              </div>
            </div>
            <v-btn :id="idBtn (marketId, 'Success', data.borrow?'Borrow':'Repay', chainId)"
             @click="closeDialog" class="btn btn-primary">
              <span class="b1-main white--text">
              {{$t('dialog.loading.btn')}}
              </span>
            </v-btn>
          </div>
        </v-card>
      </template>
      <template v-else>
        <v-card class="dialog-loading secondary-color d-flex justify-center align-center">
          <v-icon class="btn-close-dialog" @click="closeDialog">mdi-close</v-icon>
          <div class="d-flex flex-column justify-center align-center">
            <img src="@/assets/dialog/cancel.svg" />
            <div v-if="informationError.detail"
              class="text-primary s1-pop-ups text-cancel mt-6 mb-7">
              error: {{informationError.typeError}}
              detail: {{informationError.detail}}
              info: {{informationError.info}} <br>
              {{ textDescription }}
            </div>
            <div v-else-if="errorEvent" class="text-primary s1-pop-ups text-cancel mt-6 mb-7">
              {{ $t(`errors.${informationError.typeError}`) }}
            </div>
            <div v-else class="text-primary s1-pop-ups text-cancel mt-6 mb-7">
              {{ textDescription }}
            </div>
            <v-btn :id="idBtn (marketId, 'Fail', route, chainId )"
            @click="closeDialog" class="btn btn-primary">
              <span class="b1-main white--text">
              {{ $t('dialog.loading.btn') }}
              </span>
            </v-btn>
          </div>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import * as constants from '@/store/constants';
import idBtn from '@/mixins/idBtn';
import DonationAddresses from '@/components/home/DonationAddresses.vue';
import Finance4Everyone from '../home/Finance4Everyone.vue';

export default {
  name: 'Loading',
  components: {
    Finance4Everyone,
    DonationAddresses,
  },
  data() {
    return {
      dialog: this.showModal,
      cancelEvent: this.rejectEvent,
      errorEvent: this.error,
      constants,
      expandedFinanceCard: false,
    };
  },
  props: {
    showModal: {
      type: Boolean,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  watch: {
    showModal() {
      this.dialog = this.showModal;
    },
    rejectEvent() {
      this.cancelEvent = this.rejectEvent;
    },
    error() {
      this.errorEvent = this.error;
    },
  },
  computed: {
    ...mapState({
      chainId: (state) => state.Session.chainId,
      wallet: (state) => state.Session.wallet,
      rejectEvent: (state) => state.Users.rejectEvent,
      totalRepayError: (state) => state.Users.totalRepayError,
      error: (state) => state.Users.errorEvent,
      progressLinear: (state) => state.Users.progressLinear,
      informationError: (state) => state.Users.error,
      typeError: (state) => state.Users.error.typeError,
      marketId: (state) => state.Market.instance.marketAddress,
      actionType: (state) => state.Users.infoLoading.type,
      loadingAmount: (state) => state.Users.infoLoading.amount,
      route: (state) => state.route.name,
      borrowFlowOption: (state) => state.Borrow.option,
      borrowFlowActive: (state) => state.Borrow.amountToBorrow,
      loadingAction: (state) => state.Users.infoLoading.loading,
      infoLoading: (state) => state.Users.infoLoading,
    }),
    isClosable() {
      return this.data.closable && !this.route === 'Borrow' && this.infoLoading.type !== 'deposit' && !this.loadingAction;
    },
    explorer() {
      switch (this.chainId) {
        case 30:
          return 'https://explorer.rsk.co/tx';
        case 31:
          return 'https://explorer.testnet.rsk.co/tx';
        default:
          return '';
      }
    },
    textDescription() {
      let text = '';
      if (this.rejectEvent) {
        text = this.$t('dialog.loading.description10');
      }
      if (this.error) {
        text = this.$t('dialog.loading.description11');
      }
      if (this.totalRepayError) {
        text = this.$t('dialog.loading.description14');
      }
      return text;
    },
    textConfirmation() {
      if (this.wallet !== 'WEB3AUTH') {
        if (this.data.amount === 1 && this.progressLinear < 80) return this.$t('dialog.loading.description8');
        if (this.data.amount === 2 && this.progressLinear < 80) return this.$t('dialog.loading.description8');
        if (this.progressLinear >= 80) return 'Confirmación realizada';
      }
      return '';
    },
    showProgressTx() {
      /* eslint-disable */ 
      if (this.loadingAmount === 3 && (this.progressLinear === 80 || this.progressLinear === 100)) return '3 / 3';
      if (this.loadingAmount === 3 && this.progressLinear === 50) return '2 / 3';
      if (this.loadingAmount === 3 && (this.progressLinear === 30 || this.progressLinear === 10)) return '1 / 3';
      if (this.loadingAmount === 2 && (this.progressLinear === 80 || this.progressLinear === 100)) return '2 / 2';
      if (this.loadingAmount === 2 && (this.progressLinear === 50
        || this.progressLinear === 30 || this.progressLinear === 10)) return '1 / 2';
      return '1 / 1';
    },
  },
  methods: {
    ...mapActions({
      initEvent: constants.USER_ACTION_EVENT,
    }),
    ...mapMutations({
      setPropertyBorrow: constants.BORROW_SET_PROPERTY,
    }),
    closeDialog() {
      this.$emit('closed');
      const operationSuccessfull = !this.rejectEvent;
    if(this.data.action ==='Borrow' ||(this.data.action ==='Mint' && this.borrowFlowActive )){
      this.setPropertyBorrow({ option: (!this.rejectEvent && this.borrowFlowOption < 5) ? 4 : this.borrowFlowOption });
      if (this.rejectEvent && this.borrowFlowOption === 5) this.setPropertyBorrow({ option: 4 });
      }
      this.initEvent({ rejectEvent: false, errorEvent: false });
    // borrowFlowOption =4 when the deposit  of warranty is done, the  flow must  go on till borrow is finally executed
      if(operationSuccessfull  && this.borrowFlowOption !==4 && !this.totalRepayError){
         this.$router.push({ name: constants.ROUTE_NAMES.BALANCE });
      }
    },
  },
  mixins: [idBtn],
};
</script>
