/* eslint-disable no-console */
import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready(registration) {
      setInterval(() => {
        registration.update();
      }, 15000);
    },
    registered() {},
    cached() {},
    updatefound() {},
    async updated(registration) {
      const worker = registration.waiting;
      worker.postMessage({ action: 'skipWaiting' });
    },
    offline() {},
    error(error) {
      console.error(error);
    },
  });
}
