export const rpcUrls = {
  31: process.env.VUE_APP_RSK_NODE_TESTNET,
  30: process.env.VUE_APP_RSK_NODE,
};

export const webSocketUrls = {
  31: process.env.VUE_APP_WS_PROVIDER_TESTNET,
  30: process.env.VUE_APP_WS_PROVIDER,
};

export const supportedChains = process.env.VUE_APP_SUPPORTED_NETWORKS.split(' ').map(Number);

export const manifestAppUrl = process.env.VUE_APP_TREZOR_APP_URL;

export const chainId = Number(process.env.VUE_APP_DEFAULT_CHAIN_ID);

export const WC_PROJECT_ID = process.env.VUE_APP_WC_PROJECT_ID;
