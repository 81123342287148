export const WALLET_LIQUALITY = 'WALLET_LIQUALITY';
export const WALLET_METAMASK = 'WALLET_METAMASK';
export const WALLET_CONNECT = 'WALLET_CONNECT';
export const WALLET_DEFIANT = 'WALLET_DEFIANT';
export const WALLET_BEEXO = 'WALLET_BEEXO';

export const USER_FEEDBACK = 'USER_FEEDBACK';
export const USER_ACTION = 'USER_ACTION';
export const USER_ACTION_ALLOWANCE = 'USER_ACTION_ALLOWANCE';
export const USER_ACTION_EVENT = 'USER_ACTION_EVENT';
export const USER_ACTION_SWAP = 'USER_ACTION_SWAP';
export const USER_ACTION_SWAP_DIALOG = 'USER_ACTION_SWAP_DIALOG';
export const USER_ACTION_MINT = 'USER_ACTION_MINT';
export const USER_ACTION_REDEEM = 'USER_ACTION_REDEEM';
export const USER_ACTION_SEND = 'USER_ACTION_SEND';
export const USER_ACTION_BORROW = 'USER_ACTION_BORROW';
export const USER_ACTION_REPAY = 'USER_ACTION_REPAY';

export const USER_ACTION_INFO_DIALOG = 'USER_ACTION_INFO_DIALOG';
export const USER_ACTION_NOTIFICATIONS = 'USER_ACTION_NOTIFICATIONS';
export const USER_ACTION_ERROR = 'USER_ACTION_ERROR';
export const USER_SET_PROPERTY = 'USER_SET_PROPERTY';
export const USER_ADD_GAS_USED = 'USER_ADD_GAS_USED';
export const USER_DIALOG_INFO_CLOSE = 'USER_DIALOG_INFO_CLOSE';

export const TRBTC_SYMBOL = 'trBTC';
export const RBTC_SYMBOL = 'RBTC';
export const RBITCOIN_SYMBOL = 'rBitcoin';
export const TRDOC_SYMBOL = 'trDOC';
export const RDOC_SYMBOL = 'rDOC';
export const DOC_SYMBOL = 'DOC';
export const TSAT_SYMBOL = 'tSAT';
export const CRDOC_SYMBOL = 'kRDOC';
export const BRZ_SYMBOL = 'BRZ';
export const BPRO_SYMBOL = 'BPRO';

export const CRBTC_SYMBOL = 'CRBTC';
export const CSAT_SYMBOL = 'CSAT';
export const CDOC_SYMBOL = 'CDOC';
export const CUSDT_SYMBOL = 'CUSDT';
export const CXUSD_SYMBOL = 'CXUSD';
export const XUSD_SYMBOL = 'XUSD';
export const CBPRO_SYMBOL = 'CBPRO';

export const RIF_SYMBOL = 'RIF';
export const TRIF_SYMBOL = 'tRIF';

export const ROUTE_NAMES = {
  HOMEDASHBOARD: 'HomeDashboard',
  BALANCE: 'Balance',
  BUY_ASSETS: 'Buy Crypto',
  DEPOSITS: 'Deposits',
  DEPOSIT: 'Deposit',
  DPSTDASHBOARD: 'DpstDashboard',
  BORROWS: 'Borrows',
  BORROW: 'Borrow',
  BRRWDASHBOARD: 'BrrwDashboard',
  BRRWMARKET: 'BrrwMarket',
  EXCHANGES: 'Exchanges',
  SCHOOL: 'School',
  MARKETS: 'Markets',
  PROTOCOL: 'protocol',
};

export const ROUTES = {
  /* HOME: '/', */
  HOME: {
    HOME: '/',
    ROOT: 'Home',
    SECTION: 'Section',
    DASHBOARD: 'HomeDashboard',
    QR: 'Qr',
    SEND: 'Send',
    SENDWALLET: 'SendWallet',
  },
  BORROW: {
    ROOT: 'Borrows',
    DASHBOARD: 'BorrowDashboard',
    MARKET: 'BorrowMarket',
    BORROW: 'Borrow',
    REPAY: 'Repay',
  },
  BUY_CRYPTO: {
    LETSBIT: 'BuyWithLetsBit',
  },
  DEPOSIT: {
    ROOT: 'Deposits',
    DASHBOARD: 'DepositDashboard',
    MARKET: 'DepositMarket',
    DEPOSIT: 'Deposit',
    WITHDRAW: 'Withdraw',
  },
  AUTH: '',
};

// actions
export const SESSION_CONNECT_WEB3 = 'SESSION_CONNECT_WEB3';
export const SESSION_DISCONNECT_WALLET = 'SESSION_DISCONNECT_WALLET';
export const SESSION_ADD_MARKETS = 'SESSION_ADD_MARKETS';
export const SESSION_SET_COMPTROLLER = 'SESSION_SET_COMPTROLLER';
export const SESSION_CONNECT_WEB3AUTH = 'SESSION_CONNECT_WEB3AUTH';
export const SESSION_GET_USER_ID = 'SESSION_GET_USER_ID';
export const SESSION_SET_W3A_INSTANCE = 'SESSION_SET_W3A_INSTANCE';
export const SESSION_SET_APP_INSTALL_DIALOG = 'SESSION_SET_APP_INSTALL_DIALOG';
export const SESSION_IS_MOBILE = 'SESSION_IS_MOBILE';

export const MARKET_LOAD_MARKETS = 'MARKET_LOAD_MARKETS';
export const MARKET_LOAD_MARKETS_USER_INFO = 'MARKET_LOAD_MARKETS_USER_INFO';
export const MARKET_LOAD_MARKET_INSTANCE = 'MARKET_LOAD_MARKET_INSTANCE';
export const MARKET_LOAD_TOKENS = 'MARKET_LOAD_TOKENS';
export const MARKET_UPDATE_MARKET_USER_INFO = 'MARKET_UPDATE_MARKET_USER_INFO';

export const COMPTROLLER_GET_LIQUIDITY = 'COMPTROLLER_GET_LIQUIDITY';
export const COMPTROLLER_SET_PROPERTY = 'COMPTROLLER_SET_PROPERTY';
export const COMPTROLLER_SET_COMPTROLLER = 'COMPTROLLER_SET_COMPTROLLER';
export const COMPTROLLER_GET_TOTAL_BORROWS = 'COMPTROLLER_GET_TOTAL_BORROWS';
export const COMPTROLLER_GET_TOTAL_DEPOSITS = 'COMPTROLLER_GET_TOTAL_DEPOSITS';
export const COMPTROLLER_BORROW_MAX_CAPACITY = 'COMPTROLLER_BORROW_MAX_CAPACITY';
export const COMPTROLLER_ENTER_MARKET_SIMULATOR = 'COMPTROLLER_ENTER_MARKET_SIMULATOR';

// mutations
export const SESSION_SET_PROPERTY = 'SESSION_SET_PROPERTY';
export const MARKET_SET_PROPERTY = 'MARKET_SET_PROPERTY';

// getters
export const SESSION_GET_ACCOUNT = 'SESSION_GET_ACCOUNT';
export const SESSION_GET_NETWORK_NAME = 'SESSION_GET_NETWORK_NAME';
export const SESSION_GET_WALLET_ADDRESS = 'SESSION_GET_WALLET_ADDRESS';
export const SESSION_GET_TROPYKUS = 'SESSION_GET_TROPYKUS';
export const SESSION_IS_CONNECTED = 'SESSION_IS_CONNECTED';
export const SESSION_IS_SUPPORTED_NETWORK = 'SESSION_IS_SUPPORTED_NETWORK';
export const SESSION_GET_CHAIN_ID = 'SESSION_GET_CHAIN_ID';

export const SESSION_SET_WALLET = 'SESSION_SET_WALLET';
export const SESSION_SHOW_DIALOG_CONNECT = 'SESSION_SHOW_DIALOG_CONNECT';
export const SESSION_SHOW_DIALOG_QR_ADDRESS = 'SESSION_SHOW_DIALOG_QR_ADDRESS';
export const SESSION_DRAWER = 'SESSION_DRAWER';
export const SESSION_SHOW_NEW_CONTENT_DIALOG = 'SESSION_SHOW_NEW_CONTENT_DIALOG';
export const SESSION_CONNECT_RLOGIN = 'SESSION_CONNECT_RLOGIN';
export const SESSION_RED_ERROR = 'SESSION_RED_ERROR';

// routes
export const SESSION_ROUTE_BALANCE = 'SESSION_ROUTE_BALANCE';
export const SESSION_ROUTE_DEPOSITS = 'SESSION_ROUTE_DEPOSITS';
export const SESSION_ROUTE_BORROWS = 'SESSION_ROUTE_BORROWS';

// Market
export const MARKET_LOADED = 'MARKET_LOADED';
export const MARKET_ADDRESSES = 'MARKET_ADDRESSES';
export const MARKET_GET_MARKET = 'MARKET_GET_MARKET';
export const MARKET_RESET_MARKET = 'MARKET_RESET_MARKET';
export const MARKET_GET_DEST_MARKET = 'MARKET_GET_DEST_MARKET';
export const MARKET_UPDATE_DEST_MARKET = 'MARKET_UPDATE_DEST_MARKET';
export const MARKET_UPDATE_DEST_SELECT = 'MARKET_UPDATE_DEST_SELECT';
export const MARKET_UPDATE_WITHDRAW_INFO = 'MARKET_UPDATE_WITHDRAW_INFO';
export const MARKET_UPDATE_RBTC_BALANCE_IN_WALLET = 'MARKET_UPDATE_RBTC_BALANCE_IN_WALLET';
export const MARKET_SET_ENTER_MARKET = 'MARKET_SET_ENTER_MARKET';

// graphs
export const PERIOD_WEEK = 'PERIOD_WEEK';
export const PERIOD_TWO_WEEKS = 'PERIOD_TWO_WEEKS';
export const PERIOD_MONTH = 'PERIOD_MONTH';
export const PERIOD_YEAR = 'PERIOD_YEAR';

export const BALANCE_SET_PROPERTY = 'BALANCE_SET_PROPERTY';
export const BALANCE_GET_USER_ACTIVITY = 'BALANCE_GET_USER_ACTIVITY';
export const BALANCE_GET_SWAP_ACTIVITY = 'BALANCE_GET_SWAP_ACTIVITY';
export const BALANCE_GET_MARKETS_BALANCE = 'BALANCE_GET_MARKETS_BALANCE';

export const BORROW_SET_PROPERTY = 'BORROW_SET_PROPERTY';
export const BORROW_SET_WARRANTY_DEPOSIT = 'BORROW_SET_WARRANTY_DEPOSIT';
export const BORROW_RESET_ALL_DATA = 'BORROW_RESET_ALL_DATA';
