import CRDOCAbi from '@/abis/CRDOC.json';
import Market from './market';
import { addresses } from './constants';
import * as constants from '../../store/constants';

export default class CRDoc extends Market {
  constructor(address = '', chainId = process.env.VUE_APP_DEFAULT_CHAIN_ID) {
    super(address, CRDOCAbi, chainId);
  }

  async underlying() {
    return addresses[this.chainId].rDOC;
  }

  // eslint-disable-next-line class-methods-use-this
  async underlyingAssetName() {
    return 'RIF Dollar';
  }

  async underlyingAssetSymbol() {
    return Number(this.chainId) === 30 ? constants.RDOC_SYMBOL : constants.TRDOC_SYMBOL;
  }
}
