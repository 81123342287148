import Vue from 'vue';

// We need to listen for 'beforeinstallprompt' ASAP
// Then we use a Vue instance property
// to use it when Vue instance has been alredy created
// and we can show our InstallAppDialog
window.addEventListener('beforeinstallprompt', (beforeInstallPromptEvent) => {
  beforeInstallPromptEvent.preventDefault();
  Vue.prototype.$beforeInstallPromptEvent = beforeInstallPromptEvent;
});

window.addEventListener('appinstalled', () => {
  // Clear the deferredPrompt so it can be garbage collected
  Vue.prototype.$beforeInstallPromptEvent = null;
});
