<template>
  <svg
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="custom-icon"
  >
    <circle cx="14.4075" cy="14.6595" r="13.5677" fill="#41907C" stroke="#41907C" />
    <path
      d="M5.44 12.216C6.05867 11.9707 6.67733 11.656 7.296 11.272C7.92533 10.888 8.48 10.4347 8.96 9.912H10.32V21H8.384V12.504C8.096 12.728 7.73333 12.9467 7.296 13.16C6.85867 13.3733 6.43733 13.5493 6.032 13.688L5.44 12.216ZM19.2218 17.832C19.2218 17.48 19.1524 17.1653 19.0138 16.888C18.8751 16.6107 18.6298 16.376 18.2778 16.184C17.9364 15.992 17.4724 15.848 16.8858 15.752C16.3098 15.656 15.5791 15.608 14.6938 15.608C14.8111 14.584 14.9018 13.6133 14.9658 12.696C15.0298 11.768 15.0831 10.84 15.1258 9.912H20.7898V11.544H16.7738C16.7631 11.7253 16.7471 11.9333 16.7258 12.168C16.7151 12.392 16.6991 12.6213 16.6778 12.856C16.6671 13.0907 16.6511 13.3147 16.6298 13.528C16.6084 13.7413 16.5871 13.9227 16.5658 14.072C18.1444 14.1573 19.3071 14.52 20.0538 15.16C20.8111 15.7893 21.1898 16.6533 21.1898 17.752C21.1898 18.2533 21.1044 18.7173 20.9338 19.144C20.7631 19.5707 20.5018 19.9387 20.1498 20.248C19.7978 20.5573 19.3551 20.8027 18.8218 20.984C18.2884 21.1547 17.6644 21.24 16.9498 21.24C16.6618 21.24 16.3684 21.2187 16.0698 21.176C15.7711 21.1333 15.4831 21.08 15.2057 21.016C14.9391 20.9627 14.6991 20.904 14.4858 20.84C14.2724 20.776 14.1124 20.7173 14.0058 20.664L14.3738 19.032C14.5978 19.1387 14.9178 19.256 15.3338 19.384C15.7604 19.5013 16.2778 19.56 16.8858 19.56C17.3018 19.56 17.6591 19.5173 17.9578 19.432C18.2564 19.336 18.4964 19.208 18.6778 19.048C18.8698 18.888 19.0084 18.7067 19.0938 18.504C19.1791 18.2907 19.2218 18.0667 19.2218 17.832ZM25.2755 8.872C25.2862 9 25.2915 9.13333 25.2915 9.272C25.3022 9.41067 25.3022 9.52267 25.2915 9.608C25.2702 10.248 25.1422 10.8667 24.9075 11.464C24.6835 12.0507 24.4062 12.5733 24.0755 13.032L22.6675 12.664C22.8808 12.1947 23.0462 11.6987 23.1635 11.176C23.2808 10.6533 23.3395 10.152 23.3395 9.672C23.3395 9.59733 23.3342 9.48 23.3235 9.32C23.3235 9.16 23.3128 9.01067 23.2915 8.872H25.2755Z"
      fill="#E6F1EB"
    />
  </svg>
</template>

<script>
/* eslint max-len: 0 */

export default {
  name: 'FifteenMinutes',
};
</script>
