import { BigNumber } from 'ethers';

export default class PNumber {
  static from(number, mantissa = 18) {
    const result = new PNumber();
    let regMantissa = mantissa;
    if (typeof number === 'object') {
      // eslint-disable-next-line
      if (number.number !== undefined && number.number._isBigNumber) {
        return number;
      }
      // eslint-disable-next-line
      if (number._isBigNumber) {
        result.number = number;
        return result;
      }
      return PNumber.from(number.toString());
    } if (typeof number === 'string') {
      if (number.includes('.')) {
        let regNumber = number;
        const dot = regNumber.indexOf('.') + 1;
        regMantissa = regNumber.length - dot;
        if (regMantissa > 18) {
          regNumber = regNumber.substr(0, regNumber.length - regMantissa + 18);
          regMantissa = 18;
        }
        result.number = BigNumber.from(regNumber.replace('.', ''));
      } else {
        result.number = BigNumber.from(number);
      }
    } else {
      result.number = BigNumber.from(number);
    }

    if (regMantissa !== 18) {
      if (regMantissa < 18) {
        result.number = result.number.mul((10 ** (18 - regMantissa)).toString());
      } else {
        throw new Error(`The mantissa must be lower than 18, received: ${regMantissa}`);
      }
    }
    return result;
  }

  toString() {
    return this.toFixed(18);
  }

  getDecimals() {
    return `${this.toFixed(18)}\0`.replace(/[.]*[0]*\0/g, '');
  }

  toFixed(decimals) {
    let number = this.number.toString();
    const negative = number.includes('-') ? 1 : 0;
    let { length } = number;

    if (length - negative <= 18) {
      const zerosToAppend = (10 ** (18 - length + negative)).toString().replace('1', '');
      number = `0.${number}`.replace('0.-', '-0.').replace('.', `.${zerosToAppend}`);
    } else {
      const dot = length - 18;
      number = `${number.substring(0, dot)}.${number.substring(dot)}`;
    }

    length = number.length;
    if (decimals === 0) {
      number = number.substr(0, length - 19);
    } else if (decimals < 18) {
      number = number.substr(0, length - 18 + decimals);
    } else {
      number = `${number}${(10 ** (decimals - 18)).toString().replace('1', '')}`;
    }
    return number;
  }

  add(anotherValue) {
    const reg = PNumber.from(anotherValue);
    const result = new PNumber();
    result.number = this.number.add(reg.number);
    return result;
  }

  sub(anotherValue) {
    const reg = PNumber.from(anotherValue);
    const result = new PNumber();
    result.number = this.number.sub(reg.number);
    return result;
  }

  mul(anotherValue) {
    const reg = PNumber.from(anotherValue);
    const result = new PNumber();
    result.number = this.number.mul(reg.number);
    result.number = result.number.div('1000000000000000000');
    return result;
  }

  div(anotherValue) {
    const reg = PNumber.from(anotherValue);
    if (reg.toFixed(0) === '0') {
      return undefined;
    }
    const result = new PNumber();
    result.number = this.number.mul('1000000000000000000');
    result.number = result.number.div(reg.number);
    return result;
  }

  equals(anotherValue) {
    const reg = PNumber.from(anotherValue);
    return this.number.eq(reg.number);
  }

  lt(anotherValue) {
    const reg = PNumber.from(anotherValue);
    return this.number.lt(reg.number);
  }

  lte(anotherValue) {
    const reg = PNumber.from(anotherValue);
    return this.number.lte(reg.number);
  }

  gt(anotherValue) {
    const reg = PNumber.from(anotherValue);
    return this.number.gt(reg.number);
  }

  gte(anotherValue) {
    const reg = PNumber.from(anotherValue);
    return this.number.gte(reg.number);
  }

  isZero() {
    return this.number.isZero();
  }
}
