<template>
  <v-dialog v-model="flag" width="360" height="618" :persistent="flag"
  content-class="install-prompt">
    <v-card color="#E6F1EB" class="content-install-prompt">
      <v-icon class="btn-close-dialog" @click="closeDialog">
        mdi-close
      </v-icon>
      <div>
        <v-row class="ma-0 pb-8">
          <div>
          <div class="p1-descriptions text-center">
            {{ $t('dialog.install-app.title') }}
          </div>
          </div>
        </v-row>
        <v-img src="@/assets/dialog/installAppBackg.jpg" />
        <v-row class="ma-0 pb-4 d-block">
          <div >
          <div class="text">
            {{ $t('dialog.install-app.text') }}
          </div>
          </div>
        </v-row>
        <v-row class="buttons">
          <v-btn class="btn primary-color action-btn mb-2"
                  color="#4cb163" @click="install">
            <span class="b1-main white--text">
              {{ $t('dialog.install-app.button1') }}
            </span>
          </v-btn>
          <v-btn class="btn action-btn" text
            @click="dismiss">
            <span class="b1-main text-detail">
              {{ $t('dialog.install-app.button2') }}
            </span>
          </v-btn>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'InstallApp',
  props: {
    showModal: {
      type: Boolean,
    },
  },
  data() {
    return {
      flag: this.showModal,

    };
  },
  methods: {
    closeDialog() {
      this.$emit('closed');
    },
    dismiss() {
      localStorage.setItem('trpksDontShowAppInstallDialog', true);
      this.$emit('closed');
    },
    install() {
      if (this.$beforeInstallPromptEvent && !this.showDialogConnect && !this.walletAddress) {
        this.$beforeInstallPromptEvent.prompt();
      }
      this.$emit('installApp');
    },
  },
};
</script>
