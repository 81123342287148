<template>
<svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="14.4075" cy="14.0677" r="13.5677" fill="#41907C" stroke="#41907C"/>
<path d="M15.7789 12.0201C15.7789 12.8841 15.7469 13.6895 15.6829 14.4361C15.6189 15.1828 15.5336 15.9401 15.4269 16.7081H13.6029C13.4963 15.9401 13.4056 15.1828 13.3309 14.4361C13.2669 13.6895 13.2349 12.8841 13.2349 12.0201V9.15614H15.7789V12.0201ZM15.9869 18.9801C15.9869 19.4495 15.8323 19.8121 15.5229 20.0681C15.2243 20.3241 14.8829 20.4521 14.4989 20.4521C14.1149 20.4521 13.7683 20.3241 13.4589 20.0681C13.1603 19.8121 13.0109 19.4495 13.0109 18.9801C13.0109 18.5108 13.1603 18.1481 13.4589 17.8921C13.7683 17.6361 14.1149 17.5081 14.4989 17.5081C14.8829 17.5081 15.2243 17.6361 15.5229 17.8921C15.8323 18.1481 15.9869 18.5108 15.9869 18.9801Z" fill="#E6F1EB"/>
</svg>
</template>

<script>
/* eslint max-len: 0 */

export default {
  name: 'Warning',
};
</script>
