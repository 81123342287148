import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/router/routes';
import { addresses } from '@/middleware/contracts/constants';
import * as constants from '@/store/constants';
import store from '../store';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
const chainId = Number(process.env.VUE_APP_DEFAULT_CHAIN_ID);
router.beforeEach((to, from, next) => {
  if (to.params?.id === addresses[chainId].kXUSD && (to.name === 'Deposit' || to.name === 'Borrow')) {
    next({ path: '/home' });
  }
  if (to.name === constants.ROUTE_NAMES.BALANCE) {
    if (to.matched.some((record) => record.meta.requiredWallet)) {
      if (store.getters.SESSION_IS_CONNECTED) {
        store.dispatch(constants.SESSION_SHOW_DIALOG_CONNECT, false);
        store.dispatch(constants.SESSION_ROUTE_BALANCE, false);
        next();
      } else {
        store.dispatch(constants.SESSION_SHOW_DIALOG_CONNECT, true);
        store.dispatch(constants.SESSION_ROUTE_BALANCE, true);
      }
    }
  } else {
    store.dispatch(constants.SESSION_ROUTE_BALANCE, false);
    next();
  }

  if (
    to.name === constants.ROUTES.DEPOSIT.DEPOSIT
    || to.name === constants.ROUTES.DEPOSIT.WITHDRAW
  ) {
    if (to.matched.some((record) => record.meta.requiredWallet)) {
      if (store.getters.SESSION_IS_CONNECTED) {
        store.dispatch(constants.SESSION_SHOW_DIALOG_CONNECT, false);
        store.dispatch(constants.SESSION_ROUTE_DEPOSITS, false);
        next();
      } else {
        store.dispatch(constants.SESSION_SHOW_DIALOG_CONNECT, true);
        store.dispatch(constants.SESSION_ROUTE_DEPOSITS, true);
      }
    }
  } else {
    store.dispatch(constants.SESSION_ROUTE_DEPOSITS, false);
    next();
  }

  if (
    to.name === constants.ROUTES.BORROW.BORROW
    || to.name === constants.ROUTES.BORROW.REPAY
  ) {
    if (to.matched.some((record) => record.meta.requiredWallet)) {
      if (store.getters.SESSION_IS_CONNECTED) {
        store.dispatch(constants.SESSION_SHOW_DIALOG_CONNECT, false);
        store.dispatch(constants.SESSION_ROUTE_BORROWS, false);
        next();
      } else {
        store.dispatch(constants.SESSION_SHOW_DIALOG_CONNECT, true);
        store.dispatch(constants.SESSION_ROUTE_BORROWS, true);
      }
    }
  } else {
    store.dispatch(constants.SESSION_ROUTE_BORROWS, false);
    next();
  }
  if (
    to.name === constants.ROUTES.HOME.QR
    || to.name === constants.ROUTES.HOME.SEND
  ) {
    if (to.matched.some((record) => record.meta.requiredWallet)) {
      if (store.getters.SESSION_IS_CONNECTED) {
        store.dispatch(constants.SESSION_SHOW_DIALOG_CONNECT, false);
        store.dispatch(constants.SESSION_ROUTE_BORROWS, false);
        next();
      } else {
        store.dispatch(constants.SESSION_SHOW_DIALOG_CONNECT, true);
        store.dispatch(constants.SESSION_ROUTE_BORROWS, true);
      }
    }
  } else {
    store.dispatch(constants.SESSION_ROUTE_BORROWS, false);
    next();
  }
  if (from.name === null && to.name === constants.ROUTE_NAMES.BALANCE) {
    next({ path: '/' });
  }
});

export default router;
