<template>
  <div>
    <template v-if="isLoading">
      <loading :showModal="isLoading" :data="dataLoading" @closed="closeNotification" />
    </template>
    <div class="content-notifications">
      <template v-if="showNotification">
        <notification :data="notifications" />
      </template>
    </div>

    <template v-if="showModalConnectWallet">
      <connect-wallet :showModal="showModalConnectWallet" @closed="closeDialog" />
    </template>
    <template v-if="showDialogQrAddress">
      <qr-address-dialog :showModal="showDialogQrAddress" @closed="closeDialog" />
    </template>

    <template v-if="warningWallet">
      <warning-wallet :showModal="warningWallet" @closed="closeWarningWallet"/>
    </template>

    <template v-if="savingTheAmazonDialog">
      <saving-the-amazon
        :showModal="savingTheAmazonDialog"
        :savingTheAmazonId="savingTheAmazonId"
        @closed="closeDialog"
      />
    </template>
    <referral-content />

    <w3a-guide />

  </div>
</template>
<script>
import Loading from '@/components/modals/Loading.vue';
import Notification from '@/components/general/Notifications.vue';
import ConnectWallet from '@/components/dialog/ConnectWallet.vue';
import QrAddressDialog from '@/components/dialog/QrAddressDialog.vue';
import WarningWallet from '@/components/dialog/WarningWallet.vue';
import SavingTheAmazon from '@/components/dialog/SavingTheAmazon.vue';
import { mapActions, mapMutations, mapState } from 'vuex';
import * as constants from '@/store/constants';
import ReferralContent from './ReferralContent.vue';
import W3aGuide from '../dialog/W3aGuide.vue';

export default {
  components: {
    Loading,
    Notification,
    ConnectWallet,
    QrAddressDialog,
    WarningWallet,
    SavingTheAmazon,
    ReferralContent,
    W3aGuide,
  },
  data() {
    return {
      isLoading: false,
      showModalConnectWallet: false,
      warningWallet: false,
      dataLoading: {},
      savingTheAmazonDialog: false,
      savingTheAmazonId: 0,
      existUserSavingTheAmazon: false,
      dbName: process.env.VUE_APP_DB_SAVING_THE_AMAZON,
      borrowAction: false,
    };
  },
  watch: {
    dialogLoading() {
      this.isLoading = this.dialogLoading;
    },
    infoLoading() {
      this.dataLoading = this.infoLoading;
    },
    showDialogConnect() {
      this.showModalConnectWallet = this.showDialogConnect;
    },
    walletAddress() {
      this.getUserSavingTheAmazon();
      this.getSavingTheAmazonCode();
      if (this.walletAddress && this.routeBalance) {
        const to = { name: constants.ROUTE_NAMES.BALANCE };
        this.$router.push(to);
      }
      if (this.walletAddress && this.routeDeposits) {
        const to = { name: constants.ROUTE_NAMES.DEPOSITS };
        this.$router.push(to);
      }
      if (this.walletAddress && this.routeBorrows) {
        const to = { name: constants.ROUTE_NAMES.BORROWS };
        this.$router.push(to);
      }
    },
    depositUpdate() {
      if (this.depositUpdate && !this.existUserSavingTheAmazon
        && this.savingTheAmazonId > 0 && this.chainId === 30) {
        this.savingTheAmazonDialog = true;
      }
    },
  },
  computed: {
    ...mapState({
      infoLoading: (state) => state.Users.infoLoading,
      dialogLoading: (state) => state.Users.dialogLoading,
      notifications: (state) => state.Users.notifications,
      showDialogConnect: (state) => state.Session.showDialogConnect,
      showDialogQrAddress: (state) => state.Session.showDialogQrAddress,
      routeBalance: (state) => state.Session.routeBalance,
      routeDeposits: (state) => state.Session.routeDeposits,
      routeBorrows: (state) => state.Session.routeBorrows,
      walletAddress: (state) => state.Session.walletAddress,
      depositUpdate: (state) => state.Users.depositUpdate,
      chainId: (state) => state.Session.chainId,
    }),
    showNotification() {
      return this.notifications && !this.dialogLoading;
    },
  },
  methods: {
    ...mapActions({
      showDialogWallet: constants.SESSION_SHOW_DIALOG_CONNECT,
      setDialogQrAddress: constants.SESSION_SHOW_DIALOG_QR_ADDRESS,
      setDrawer: constants.SESSION_DRAWER,
      connectToWeb3: constants.SESSION_CONNECT_WEB3,
    }),
    ...mapMutations({
      setDialogLoading: constants.USER_SET_PROPERTY,
    }),
    getUserSavingTheAmazon() {
      if (!this.walletAddress) return;
      this.$db
        .collection(this.dbName).where('address', '==', this.walletAddress)
        .get()
        .then((data) => {
          if (data.docs.length > 0) {
            const { exists } = data.docs[0];
            this.existUserSavingTheAmazon = !exists ? 0 : exists;
          }
        });
    },
    getSavingTheAmazonCode() {
      if (!this.walletAddress) return;
      this.$db
        .collection(this.dbName).where('address', '==', '')
        .get()
        .then((data) => {
          if (data.docs.length > 0) {
            const { id } = data.docs[0];
            this.savingTheAmazonId = Number(id);
            this.getCodeSnapshot(id);
          } else {
            this.savingTheAmazonId = 0;
          }
        });
    },
    getCodeSnapshot(code) {
      this.$db
        .collection(this.dbName)
        .doc(code)
        .onSnapshot((doc) => {
          const { address } = doc.data();
          if (address !== '') {
            this.getSavingTheAmazonCode();
          }
        });
    },
    closeNotification() {
      this.setDialogLoading({ dialogLoading: false });
      this.borrowAction = false;
    },
    closeChivoDialog() {
      this.borrowAction = false;
    },
    closeDialog(value) {
      this.setDialogQrAddress(false);
      this.showDialogWallet(false);
      this.getUserSavingTheAmazon();
      this.getSavingTheAmazonCode();
      this.savingTheAmazonDialog = false;
      if (value) {
        this.warningWallet = true;
      } else {
        this.warningWallet = false;
      }
    },
    closeWarningWallet(value) {
      if (value) {
        this.warningWallet = false;
        this.connectToWeb3({ wallet: constants.WALLET_CONNECT });
      } else {
        this.warningWallet = false;
      }
    },
  },
  created() {
    this.getUserSavingTheAmazon();
    this.getSavingTheAmazonCode();
  },
};
</script>
