import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import VueGoogleCharts from 'vue-google-charts';

import Bitcoin from '@/components/icons/Bitcoin.vue';
import Clipboard from '@/components/icons/Clipboard.vue';
import OpenInNew from '@/components/icons/OpenInNew.vue';
import FeedbackBubbleIcon from '@/components/icons/FeedbackBubbeIcon.vue';
import FifteenMinutes from '@/components/icons/FifteenMinutes.vue';
import NinetyMinutes from '@/components/icons/NinetyMinutes.vue';
import NoCommission from '@/components/icons/NoCommission.vue';
import SadFace from '@/components/icons/SadFace.vue';
import Support from '@/components/icons/Support.vue';
import Swap from '@/components/icons/Swap.vue';
import TelegramIcon from '@/components/icons/TelegramIcon.vue';
import ChangeIcon from '@/components/icons/Change.vue';
import Warning from '@/components/icons/Warning.vue';

Vue.use(VueGoogleCharts);
Vue.use(Vuetify);

const theme = {
  primary: '#042F24',
  secondary: '#E6F1EB',
  third: '#053126',
  warning: '#FCDE73',
  primary_bg: '#CFE7DA',
  secondary_bg: '#8AE39E',
  text_detail: '#196635',
  btn_wallet: '#E1F0E8',
  text_value: '#042F24',
  text_bg: '#63A47B',
  text_rate: '#4CB163',
};

export default new Vuetify({
  theme: {
    themes: {
      light: theme,
    },
  },
  icons: {
    values: {
      bitcoin: {
        component: Bitcoin,
      },
      clipboard: {
        component: Clipboard,
      },
      warning: {
        component: Warning,
      },
      feedbackBubble: {
        component: FeedbackBubbleIcon,
      },
      'fifteen-minutes': {
        component: FifteenMinutes,
      },
      'ninety-minutes': {
        component: NinetyMinutes,
      },
      'no-commission': {
        component: NoCommission,
      },
      'sad-face': {
        component: SadFace,
      },
      'change-icon': {
        component: ChangeIcon,
      },
      support: {
        component: Support,
      },
      swap: {
        component: Swap,
      },
      telegram: {
        component: TelegramIcon,
      },
      'open-in-new': {
        component: OpenInNew,
      },
    },
  },
});
