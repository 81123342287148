<template>
  <div class="w3a-guide">
    <v-dialog v-model="dialog" width="414" overlay-opacity="0.6" overlay-color="#000"
      persistent
    >
    <v-carousel
    :show-arrows="false"
    :continuous="false"
    class="modal-w3a-guide py-2 pb-8 px-n8"
  >
   <v-icon class="btn-close-dialog " @click="closeDialog">mdi-close</v-icon>
   <div class="content-w3a-dialog mb-6">
    <v-carousel-item
     >
       <v-sheet
       class="content-w3a-sheet"
       height="100%"

       >
       <div class="section">
             <div class="ma-0">
               <h1 class="h1-heading text-primary">
                 {{ $t('dialog.w3a-guide.titles[0]') }}
               </h1>
             </div>
             <div class="d-flex justify-center">
               {{ $t('dialog.w3a-guide.description[0]') }}
             </div>
             <div class="d-flex justify-center mt-3">
               <img class="mb-3" src="@/assets/dialog/wallet-info.svg"
               alt="go_back">
             </div>
             <div class="d-flex justify-center align-center">
               {{ $t('dialog.w3a-guide.description[1]') }}
             </div>
           </div>
       </v-sheet>
     </v-carousel-item>
     <v-carousel-item
     >
       <v-sheet
         class="content-w3a-sheet"
         height="100%"
       >
       <div class="section">
             <div class="ma-0">
               <h1 class="h1-heading text-primary">
                 {{ $t('dialog.w3a-guide.titles[1]') }}
               </h1>
             </div>
             <div class="d-flex justify-center px-6 px-sm-0  mt-8 mt-sm-0">
               {{ $t('dialog.w3a-guide.description[2]') }}
             </div>
             <div class="d-flex justify-center mt-8">
               <img class="mb-3" src="@/assets/dialog/btn-group.svg"
               alt="go_back">
             </div>
           </div>
       </v-sheet>
     </v-carousel-item>
     <v-carousel-item
     >
       <v-sheet
         class="content-w3a-sheet "
         height="100%"
       >
       <div class="section">
             <div class="ma-0">
               <h1 class="h1-heading text-primary">
                 {{ $t('dialog.w3a-guide.titles[2]') }}
               </h1>
             </div>
             <div class="d-flex justify-center px-6 px-sm-0 ">
               {{ $t('dialog.w3a-guide.description[3]') }}
             </div>
             <div class="d-flex justify-center mt-5">
               <img class="mb-3" src="@/assets/dialog/user-account.svg"
               alt="go_back">
             </div>

              <v-btn class="btn btn-primary mx-auto"
             @click="dialog = false"
           >

             <span class="white--text">{{ $t('dialog.w3a-guide.btn[0]') }}</span>
           </v-btn>

           </div>
       </v-sheet>
     </v-carousel-item>
   </div>

  </v-carousel>

    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'w3a',
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapState({
      wallet: (state) => state.Session.wallet,
    }),
  },
  watch: {
    wallet() {
      if (this.wallet === 'WEB3AUTH') {
        this.dialog = true;
      }
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>
