<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="custom-icon"
  >
    <path
      d="M14 0C6.28133 0 0 6.28133 0 14C0 21.7187 6.28133 28 14 28C21.7187 28 28 21.7187 28 14C28 6.28133 21.7187 0 14 0ZM6.86 18.312C6.70756 18.4676 6.50533 18.5453 6.30311 18.5453C6.10711 18.5453 5.91111 18.4707 5.76178 18.3244C5.45378 18.0227 5.44756 17.5311 5.74933 17.2231C7.60978 15.3191 9.492 15.3502 11.0911 15.6333C11.5142 15.708 11.7973 16.1124 11.7227 16.5356C11.648 16.9587 11.2436 17.2418 10.8204 17.1671C9.324 16.9027 8.13867 17.0022 6.86 18.312ZM17.7271 23.688C17.5933 23.7751 17.444 23.8187 17.2947 23.8187C17.0427 23.8187 16.7969 23.6973 16.6476 23.4733C15.9009 22.3596 14.9644 21.7436 14.0031 21.7436C13.0418 21.7436 12.1053 22.3564 11.3587 23.4733C11.1191 23.8311 10.6369 23.9276 10.2791 23.688C9.92133 23.4484 9.82489 22.9662 10.0644 22.6084C11.1067 21.0467 12.5129 20.188 14.0062 20.188C15.4996 20.188 16.8996 21.0467 17.9418 22.6084C18.1813 22.9662 18.0849 23.4484 17.7271 23.688ZM22.2444 18.3213C22.092 18.4676 21.896 18.5422 21.7 18.5422C21.4978 18.5422 21.2956 18.4644 21.1431 18.3089C19.8613 16.9991 18.6791 16.8996 17.1827 17.164C16.7627 17.2418 16.3551 16.9587 16.2836 16.5324C16.2089 16.1093 16.492 15.7049 16.9151 15.6302C18.5111 15.3471 20.3964 15.316 22.2569 17.22C22.5587 17.528 22.5524 18.0196 22.2444 18.3213Z"
      fill="#41907C"
    />
  </svg>
</template>

<script>
/* eslint max-len: 0 */

export default {
  name: 'SadFace',
};
</script>
