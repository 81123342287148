import Vue from 'vue';
import * as constants from '../store/constants';

Vue.filter(
  'formatPrice',
  (value) => {
    const number = value || 0;
    const decimals = number.toString().split('.')[0].length > 4 ? 0 : 2;
    const formatNumber = Number(number).toLocaleString('es', {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    }).replace(',', '.');
    return `$${formatNumber}`;
  },
);

Vue.filter(
  'formatRate',
  (value) => {
    const number = Number(value) || 0;
    return number.toFixed(2);
  },
);

Vue.filter('formatDecimals', (value, token) => {
  const aux = value.toString().split('.');
  let decimalPart = '';
  const normalizedToken = token.toLowerCase();
  if (value <= 0) return 0;

  if (
    normalizedToken === constants.RBTC_SYMBOL.toLowerCase()
    || normalizedToken === constants.TRBTC_SYMBOL.toLowerCase()
    || normalizedToken === constants.CSAT_SYMBOL.toLowerCase()
    || normalizedToken === constants.TSAT_SYMBOL.toLowerCase()
    || normalizedToken === constants.RBITCOIN_SYMBOL.toLowerCase()
    || normalizedToken === constants.BPRO_SYMBOL.toLowerCase()
  ) {
    if (value < 0.000001) return Number(value).toFixed(6);
    decimalPart = aux[1] ? '.'.concat(aux[1].substring(0, 6)) : '';
    return `${aux[0]}${decimalPart}`;
  }

  decimalPart = aux[1] ? '.'.concat(aux[1].substring(0, 2)) : '';
  return `${aux[0]}${decimalPart}`;
});

Vue.filter('formatInterest', (value, token) => {
  const aux = value.toString().split('.');
  let decimalPart = '';
  const normalizedToken = token.toString().toLowerCase();
  if (value <= 0 || aux.includes('e')) return 0;

  if (
    normalizedToken === constants.DOC_SYMBOL.toLowerCase()
    || normalizedToken === constants.XUSD_SYMBOL.toLowerCase()
  ) {
    if (value < 0.000001) return Number(value).toFixed(4);
    decimalPart = aux[1] ? '.'.concat(aux[1].substring(0, 4)) : '';
    return `${aux[0]}${decimalPart}`;
  }

  if (value < 0.000001) return Number(value).toFixed(6);
  decimalPart = aux[1] ? '.'.concat(aux[1].substring(0, 6)) : '';
  return `${aux[0]}${decimalPart}`;
});
