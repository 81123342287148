import { addresses } from '@/middleware/contracts/constants';

const marketCode = {
  kSAT: '00',
  kRBTC: '01',
  kDOC: '02',
  kXUSD: '03',
};

const states = {
  Initiated: 'I',
  Success: 'S',
  Fail: 'F',
};

const actions = {
  Deposit: 'D',
  Withdraw: 'W',
  Repay: 'R',
  Borrow: 'B',
};

export default {
  methods: {
    idBtn(id, state, action, chainId) {
      const tokensArray = Object.keys(addresses[chainId]);
      const addressesArray = Object.values(addresses[chainId]);
      const i = addressesArray.indexOf(id);
      const market = tokensArray[i];
      return `KT${marketCode[market]}${actions[action]}${states[state]}`;
    },
  },
};
