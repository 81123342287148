import * as constants from '@/store/constants';
import { reduceDecimals } from '@/middleware';

const initState = {
  amountToBorrow: null,
  amountToWarranty: null,
  warrantyMarket: null,
  marketToBorrow: null,
  amountToDeposit: null,
  needWarranty: false,
  option: 1, // option flow
  activeOptionButton: false,
  showButtonOption: true,
  rbtcMarket: null,
  isRBTC: false,
  amountToDepositInRBTC: 0,
};

const state = {
  ...initState,
};

const actions = {

  [constants.BORROW_SET_WARRANTY_DEPOSIT]: ({ commit, rootState }, data) => {
    const { value, update } = data;
    const {
      Comptroller: { safetyMargin },
      Borrow: { warrantyMarket, amountToWarranty, rbtcMarket },
    } = rootState;

    const amountToDeposit = update
      ? ((value / warrantyMarket.collateralFactor) * safetyMargin + 1) / warrantyMarket.price
      : value;
    const amountToDepositInRBTC = ((value / rbtcMarket.collateralFactor) * safetyMargin + 1)
      / rbtcMarket.price;
    const amount = reduceDecimals(amountToDeposit, warrantyMarket.underlyingSymbol);
    commit(constants.BORROW_SET_PROPERTY, { amountToDeposit: amount });
    commit(constants.BORROW_SET_PROPERTY, { amountToDepositInRBTC });
    commit(constants.BORROW_SET_PROPERTY, {
      amountToWarranty: update ? amount : amountToWarranty,
    });
  },

  [constants.BORROW_RESET_ALL_DATA]: ({ commit }) => {
    const currentState = Object.entries(initState);
    currentState.forEach((data) => {
      const [property, value] = data;
      commit(constants.BORROW_SET_PROPERTY, { [property]: value });
    });
    commit(constants.USER_SET_PROPERTY, { rejectEvent: false });
    commit(constants.USER_SET_PROPERTY, { errorEvent: false });
  },

};
const mutations = {
  // eslint-disable-next-line no-shadow
  [constants.BORROW_SET_PROPERTY]: (state, data) => {
    const [[property, value]] = Object.entries(data);
    state[property] = value;
  },
};

export default {
  state,
  actions,
  mutations,
};
