<template>
      <div class="text-center card card2" :class="!showAddress ?'card-unexpanded':''">
        <div v-if="!inLoadingDialog" class="card-title" >
          {{ $t('home.donation.title')}}
        </div>
        <div v-if="!inLoadingDialog" class="card-description"
        v-html="$t('home.donation.description1')"></div>
        <div class="card-buttons">
          <button class="button1 btn btn-primary button btn-hover"
          @click="handleClick">
            <span class="button-text" >
              {{ $t('home.donation.btn')}}
            </span>
          </button>
        </div>
        <div  v-if="showAddress && !inLoadingDialog">
          <donation-addresses/>
        </div>
      </div>
  </template>

<script>
import DonationAddresses from '@/components/home/DonationAddresses.vue';

export default {
  components: {
    DonationAddresses,
  },
  props: {
    inLoadingDialog: {
      type: Boolean,
    },
  },
  data() {
    return {
      dialog: false,
      showAddress: false,
      rskAddress: '0xbd230dedad3fbaf8b52400ac41a87d2e6bde86d6',
      btcAddress: 'bc1qheu2h7jum426rfgmgc9zq4fmy3u37w3a4yk5vleapmekdz94dcvskg8twm',
    };
  },
  methods: {
    handleClick() {
      this.showAddress = !this.showAddress;
      this.$emit('expand');
    },
  },
};
</script>
