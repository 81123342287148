/* eslint-disable no-underscore-dangle */
import ComptrollerAbi from '@/abis/ComptrollerG6.json';
import store from '@/store';
import { ethers, BigNumber, FixedNumber } from 'ethers';
import PriceOracleProxyAbi from '@/abis/PriceOracleProxy.json';
import Vue from 'vue';
import math from '@/mathematics';
import { addresses } from './constants';
import { signer } from './utils';

ethers.utils.Logger.setLogLevel(ethers.utils.Logger.levels.ERROR);

export default class Comptroller {
  constructor(chainId = process.env.VUE_APP_DEFAULT_CHAIN_ID) {
    this.deployBlock = addresses[chainId].deployBlock;
    this.comptrollerAddress = addresses[chainId].comptroller;
    this.kRBTC = addresses[chainId].kRBTC;
    this.kSAT = addresses[chainId].kSAT;
    this.kRIF = addresses[chainId].kRIF;
    this.kDOC = addresses[chainId].kDOC;
    this.krDOC = addresses[chainId].krDOC;
    this.kUSDT = addresses[chainId].kUSDT;
    this.instance = new ethers.Contract(this.comptrollerAddress, ComptrollerAbi, Vue.web3);
    this.wsInstance = new ethers.Contract(this.comptrollerAddress, ComptrollerAbi, Vue.web3Ws);
    this.chainId = chainId;
  }

  async allMarkets(all = true) {
    const markets = await this.instance.callStatic.getAllMarkets();
    let marketsCopy = [];
    markets.forEach((marketAddress) => {
      if (all) {
        marketsCopy.push(marketAddress.toLowerCase());
      } else if (marketAddress.toLowerCase() !== this.kRBTC) {
        marketsCopy.push(marketAddress.toLowerCase());
      }
    });
    if (addresses[this.chainId]?.krDOC) {
      marketsCopy = marketsCopy.filter((m) => m !== addresses[this.chainId].krDOC);
      marketsCopy.push(addresses[this.chainId].krDOC);
    }
    return marketsCopy.reverse();
  }

  async getAssetsIn(address) {
    const assetsIn = await this.instance.callStatic.getAssetsIn(address);
    const assetsInCopy = [];
    assetsIn.forEach((asset) => {
      assetsInCopy.push(asset.toLowerCase());
    });
    return assetsInCopy;
  }

  async getAccountLiquidity(address) {
    const liquidityResponse = await this.instance.callStatic.getAccountLiquidity(address);
    const factor = BigNumber.from(1e18.toString());
    let liquidity = FixedNumber.from(liquidityResponse[1].toString(), 'fixed80x18')
      .divUnsafe(FixedNumber.from(factor.toString(), 'fixed80x18'));
    liquidity = liquidity
      .subUnsafe(FixedNumber.from('1', 'fixed80x18'));
    liquidity = Number(liquidity._value) <= 0 ? 0 : Number(liquidity._value);
    return liquidity;
  }

  enterMarkets(account, marketAddresses) {
    const accountSigner = signer(account);
    return this.instance.connect(accountSigner).enterMarkets(marketAddresses);
  }

  async healthRatio(markets, chainId, address) {
    const numerator = await this.getAccountLiquidity(address);
    const borrows = await Promise.all(await markets
      .map((market) => market.borrowBalanceInUSD(chainId, address)));
    const denominator = borrows.reduce((x, y) => x + y);
    return numerator / Number(denominator);
  }

  async hypotheticalHealthRatio(markets, chainId, address, borrowBalanceInUSD) {
    const numerador = await this.getAccountLiquidity(address);
    let denominador = 0;
    markets.forEach(async (market) => {
      denominador += await market.borrowBalanceInUSD(chainId, address);
    });
    denominador += borrowBalanceInUSD;
    return numerador / denominador;
  }

  async healthFactor(markets, chainId, address) {
    const healthRatio = await this.healthRatio(markets, chainId, address);
    return 1 - Math.min(1, 1 / healthRatio);
  }

  /**
   * @dev borrowMaxCapacity(markets,accountAddress, chainId)
   * In order to calculate this parameter we fetch the current borrows of the user
   * and we add them together. We fetch the current liquidity of the user and we add it
   * to the borrows to get the initial liquidity
   */
  async borrowMaxCapacity(markets, accountAddress, chainId) {
    const { SESSION_GET_ACCOUNT } = store.getters;
    const account = SESSION_GET_ACCOUNT || null;
    const priceOracleProxyInstance = new ethers.Contract(
      addresses[chainId].priceOracleProxy,
      PriceOracleProxyAbi,
      Vue.web3,
    );
    return new Promise((resolve, reject) => {
      let borrowMaxCapacity = BigNumber.from('0');
      let counter = 0;
      markets.forEach(async (market) => {
        await Promise.all([
          priceOracleProxyInstance.callStatic.getUnderlyingPrice(market.marketAddress),
          market.instance.connect(account).callStatic.borrowBalanceCurrent(accountAddress),
        ])
          .then(async ([price, borrowBalance]) => {
            borrowMaxCapacity = borrowMaxCapacity.add(borrowBalance.mul(price)
              .div(BigNumber.from(1e18.toString())));
            counter += 1;
            if (counter === markets.length) {
              const liquidityData = await this.instance.callStatic
                .getAccountLiquidity(accountAddress);
              borrowMaxCapacity = borrowMaxCapacity.add(liquidityData[1])
                .sub(ethers.utils.parseEther('1'));
              borrowMaxCapacity = Number(borrowMaxCapacity) / 1e18;
              borrowMaxCapacity = borrowMaxCapacity < 0 ? 0 : borrowMaxCapacity;
              resolve(borrowMaxCapacity);
            }
          })
          .catch(reject);
      });
    });
  }

  async getMarketCollateralFactor(marketAddress) {
    const market = await this.instance.callStatic.markets(marketAddress);
    return math.div(Number(market.collateralFactorMantissa), 1e18);
  }

  async hypotheticalHealthFactor(markets, chainId, address, borrowBalanceInUSD) {
    return 1 - Math.min(1, 1 / await this
      .hypotheticalHealthRatio(markets, chainId, address, borrowBalanceInUSD));
  }

  async totalBalanceInUSD(markets, accountAddress, chainId) {
    const deposits = await this.totalDepositsInUSD(markets, accountAddress, chainId);
    const debts = await this.totalBorrowsInUSD(markets, accountAddress, chainId);
    return deposits - debts;
  }

  // eslint-disable-next-line class-methods-use-this
  totalDepositsByInterestInUSD(markets, accountAddress, chainId) {
    return new Promise((resolve, reject) => {
      let totalDepositsByInterest = 0;
      let totalDeposits = 0;
      let counter = 0;
      markets.forEach(async (market) => {
        await Promise.all([
          market.underlyingCurrentPrice(chainId),
          market.currentBalanceOfCTokenInUnderlying(accountAddress),
          market.getSupplierSnapshotStored(accountAddress),
        ])
          .then(([price, totalDepositInUnderlying, supplySnapshot]) => {
            totalDepositsByInterest += totalDepositInUnderlying * price;
            totalDeposits += Number(math.div(supplySnapshot.underlyingAmount, 1e18)) * price;
            counter += 1;
            if (counter === markets.length) resolve({ totalDepositsByInterest, totalDeposits });
          })
          .catch(reject);
      });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async totalBorrowsByInterestInUSD(markets, accountAddress, chainId, marketAddress = '') {
    const priceOracleProxyInstance = new ethers.Contract(
      addresses[chainId].priceOracleProxy,
      PriceOracleProxyAbi,
      Vue.web3,
    );
    return new Promise((resolve, reject) => {
      let totalBorrowsByInterest = BigNumber.from('0');
      let totalBorrows = 0;
      let counter = 0;
      let underlyingPrice = BigNumber.from('0');
      markets.forEach(async (market) => {
        await Promise.all([
          priceOracleProxyInstance.callStatic.getUnderlyingPrice(market.marketAddress),
          market.instance.callStatic.borrowBalanceCurrent(accountAddress),
          market.getDebtInterest(accountAddress),
        ])
          .then(([price, totalBorrowInUnderlying, interestBorrow]) => {
            const result = (totalBorrowInUnderlying.mul(price))
              .div(BigNumber.from(1e18.toString()));
            if (market.marketAddress.toLowerCase() === marketAddress.toLowerCase()) {
              underlyingPrice = price;
            }
            totalBorrowsByInterest = result.add(totalBorrowsByInterest);
            totalBorrows += (totalBorrowInUnderlying - interestBorrow) * price;
            counter += 1;
            if (counter === markets.length) {
              const totalBorrowsAsUnderlying = marketAddress
                ? FixedNumber.from(totalBorrowsByInterest.toString(), 'fixed80x18')
                  .divUnsafe(FixedNumber.from(underlyingPrice.toString(), 'fixed80x18'))
                : 0;
              totalBorrowsByInterest = Number(totalBorrowsByInterest) / 1e18;
              resolve({ totalBorrowsByInterest, totalBorrows, totalBorrowsAsUnderlying });
            }
          })
          .catch(reject);
      });
    });
  }

  async getRegisteredAddresses(blocks = null) {
    let delta;
    if (blocks) {
      delta = blocks;
    } else {
      const currentBlock = await Vue.web3.getBlockNumber();
      delta = (this.deployBlock - currentBlock);
    }
    const events = await this.wsInstance
      .queryFilter('MarketEntered', delta);
    const accountAddresses = [];
    events.forEach((marketEnter) => {
      const { account } = marketEnter.args;
      if (accountAddresses.indexOf(account) === -1) accountAddresses.push(account);
    });
    return accountAddresses;
  }

  // eslint-disable-next-line class-methods-use-this
  async totalValueLockedInUSD(markets, chainId) {
    return new Promise((resolve, reject) => {
      const data = [];
      let counter = 0;
      let totalValueLocked = 0;
      Promise.all(markets
        .map((market) => Promise.all([
          market.symbol,
          market.getTotalSupply(),
          market.underlyingCurrentPrice(chainId),
        ])))
        .then((lockedValues) => lockedValues
          .forEach(([symbol, totalSupply, underlyingPrice]) => {
            data.push({ symbol, totalSupply, underlyingPrice });
            totalValueLocked += (totalSupply * underlyingPrice);
            counter += 1;
            if (counter === markets.length) resolve({ data, number: totalValueLocked });
          }))
        .catch(reject);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async totalReservesInUSD(markets, chainId) {
    return new Promise((resolve, reject) => {
      const data = [];
      let counter = 0;
      let totalReserves = 0;
      Promise.all(markets
        .map((market) => Promise.all([
          market.symbol,
          market.getReserves(),
          market.underlyingCurrentPrice(chainId),
        ])))
        .then((reservesData) => reservesData
          .forEach(([symbol, reserves, underlyingPrice]) => {
            data.push({ symbol, reserves, underlyingPrice });
            totalReserves += (reserves * underlyingPrice);
            counter += 1;
            if (counter === markets.length) resolve({ data, number: totalReserves });
          }))
        .catch(reject);
    });
  }
}
